export const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
  {
    languageId: 'croatian',
    locale: 'hr',
    name: 'Hrvatski',
    icon: 'hr',
  },

  {
    languageId: 'german',
    locale: 'de',
    name: 'Deutsch',
    icon: 'de',
  },

  {
    languageId: 'italian',
    locale: 'it',
    name: 'Italiano',
    icon: 'it',
  },
  {
    languageId: 'slovenian',
    locale: 'sl',
    name: 'Slovenščina',
    icon: 'sl',
  },
  {
    languageId: 'serbian',
    locale: 'sr',
    name: 'Srpski',
    icon: 'rs',
  },
  {
    languageId: 'albanian',
    locale: 'al',
    name: 'Shqip',
    icon: 'al',
  },
  {
    languageId: 'bosnian',
    locale: 'ba',
    name: 'Bosnian',
    icon: 'ba',
  },
  {
    languageId: 'montenegro',
    locale: 'me',
    name: 'Montenegro',
    icon: 'me',
  },
  {
    languageId: 'macedonian',
    locale: 'mk',
    name: 'Macedonia',
    icon: 'mk',
  },
];

export const flags = {
  en: '/images/flag/icons8-usa.png',
  hr: '/images/flag/icons8-croatia.png',
};
