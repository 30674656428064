import React, { useContext } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import parse from 'html-react-parser';
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import GridContainer from '../../@jumbo/components/GridContainer';
import Backdrop from '@material-ui/core/Backdrop';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import ToggleButton from '@material-ui/lab/ToggleButton';
import CmtCard from '../../@coremat/CmtCard';
import CmtCardContent from '../../@coremat/CmtCard/CmtCardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';
import PageContainer from '../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../@jumbo/utils/IntlMessages';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import SidebarButtons from '../../@jumbo/components/AppLayout/partials/SideBar/SIdebarButtons';
import queryString from 'query-string';
import FormControl from '@material-ui/core/FormControl';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Slider from '@material-ui/core/Slider';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import { DatePicker, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core/';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import Skeleton from '@material-ui/lab/Skeleton';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import AppContext from '../../@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { Alert, AlertTitle } from '@material-ui/lab';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Modal from '@material-ui/core/Modal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import LanguageSwitcher from './LanguageSwitcher';
import SolutionItem from './SolutionItem';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getMyDate1, getMyDate3, getMyDate2, getMyDate4, formatPrice } from '../../@jumbo/utils/commonHelper';
import Chip from '@material-ui/core/Chip';
import Swal from 'sweetalert2';
import Carousel from './Carousel';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import {
  ArrowBack,
  Hotel,
  Bathtub,
  Room,
  ExpandLess,
  ExpandMore,
  Launch,
  Add,
  Remove,
  Phone,
  Home,
  Mail,
  ArrowRightAlt,
} from '@material-ui/icons';
import 'react-dates/initialize';
import { DateRangePicker, DayPickerRangeController } from 'react-dates';
import isBeforeDay from 'react-dates/lib/utils/isBeforeDay';
import 'react-dates/lib/css/_datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Datepicker,
  CalendarPrev,
  CalendarNav,
  CalendarNext,
  CalendarToday,
  formatDate,
  getJson,
  localeEn,
  localeHr,
  localeSr,
  localeDe,
  localeIt,
  setOptions,
} from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { START_DATE, END_DATE, VERTICAL_ORIENTATION, HORIZONTAL_ORIENTATION } from 'react-dates/constants';
import { DateRangeIcon } from '@material-ui/pickers/_shared/icons/DateRangeIcon';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import FilterList from '@material-ui/icons/FilterList';

setOptions({
  theme: 'ios',
  themeVariant: 'light',
});

function MyAlert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function shorten(x) {
  if (String(x).length > 500) return String(x).slice(0, 500) + '...';
  else return x;
}

function dateDiffInDays(a, b) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

function myFormatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getMyDate(prop) {
  //console.log('getmydate');
  //console.log(prop);
  if (String(prop).length == 10) {
    return prop;
  }
  //prop = prop + 'T00:00:00.000+01:00';
  var md = new Date(prop);
  var dd = String(md.getDate()).padStart(2, '0');
  var mm = String(md.getMonth() + 1).padStart(2, '0');
  var yyyy = md.getFullYear();

  return yyyy + '-' + mm + '-' + dd;
}

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles(theme => ({
  root: {
    backgroundColor: alpha(theme.palette.common.dark, 0.05),
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}))(MuiAccordionDetails);

const useStyles = theme => ({
  propertyMainImage: { width: '100% !important', aspectRatio: '16/9', maxHeight: '350px', objectFit: 'cover' },
  propertyMain: {
    position: 'relative',
    textAlign: 'center',
    margin: '8px',
    color: 'white',
  },
  propertyMain2: {
    position: 'relative',
    margin: '8px',
    textAlign: 'center',
    color: 'white',
  },
  propertyMainTitle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    transform: 'translate(-50%, -50%)',
    paddingTop: '10px',
    background: 'rgba(0,0,0,0.5)',
  },
  rateBox: {
    // borderTop: '1px solid #dbd9d9',
    //paddingRight: 20,
  },
  boxTop: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  lastRoom: {
    color: '#e74856',
    fontColor: '#e74856',
  },
  myDialog: {
    zIndex: '99999 !important',
  },
  legal: {
    '& ul': { paddingLeft: '3em' },
  },
  appBar: {
    position: 'relative',
  },
  logoImage: {
    maxHeight: 100,
  },
  logo: {
    maxHeight: 28,
  },
  CCImage: { opacity: 0.5, height: 'auto', width: '45px', display: 'inline-block', margin: '0 .85rem .5rem 0' },
  pb: {
    fontSize: '10px',
    fontWeight: 300,
    marginRight: 10,
    color: '#183550',
  },
  miframe: {
    overflow: 'hidden',
    border: 0,
    alignSelf: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  text: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#063e89',
  },
  text2: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#00ad73',
  },
  wbpInput: {
    paddingTop: '23px !important',
    //paddingBottom: '6px !important',
    boxSizing: 'content-box !important',
  },
  rateReserve: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  boxMiddle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  rateReserveRight: {
    alignItems: 'end',
    display: 'flex',
    paddingRight: '15px',
    flexDirection: 'column',
  },
  rightAlign: {
    alignItems: 'end',
    display: 'flex',
    flexDirection: 'column',
  },
  dateBox1: { fontSize: '15px', fontWeight: 400, lineHeight: '21px' },
  dateBox2: { fontSize: '35px', fontWeight: 600, lineHeight: '41px' },
  dateBox3: { fontSize: '25px', fontWeight: 600, lineHeight: '21px' },
  dateBox4: { fontSize: '15px', fontWeight: 400, lineHeight: '21px', color: '#888888' },
  dateBox: {
    width: '100%',
    paddingTop: '8px',
    paddingBottom: '8px',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    background: '#F3F3F3',
    marginRight: '10px',
    minHeight: '53px',
    marginBottom: '10px',
  },

  description: {
    //fontSize: '15px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  title2: {
    fontWeight: 500,
  },
  title2hotel: {
    fontWeight: 450,
    color: '#111111',
  },
  title2dates: {
    fontWeight: 400,
    color: '#1a1a1a',
  },
  title2dates2: {
    fontWeight: 400,
    color: '#888888',
  },

  titlex2: {
    fontWeight: 500,
    color: '#222222',
  },

  title3: {
    fontSize: '10px',
    fontColor: '#222222',
    fontWeight: 300,
  },

  title4: {
    fontSize: '12px',
    fontColor: '#222222',
    fontWeight: 300,
  },
  ptitle2: {
    fontSize: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },

  ptitle: {
    fontFeatureSettings: "'dlig' 0, 'liga' 0, 'lnum' 1, 'kern' 1",
    margin: 0,
    color: 'white',
    fontSize: '1.1rem',
    fontWeight: '500',
    textTransform: 'uppercase',
    lineHeight: 1.4,

    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      paddingBottom: '10px',
    },
  },

  title: {
    fontFeatureSettings: "'dlig' 0, 'liga' 0, 'lnum' 1, 'kern' 1",
    margin: 0,
    color: '#000',
    fontSize: '1.13rem',
    fontWeight: '500',
    textTransform: 'uppercase',
    lineHeight: 1.4,

    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      paddingBottom: '10px',
    },
  },
  titleSize25: {
    fontSize: '25px',
    fontWeight: '700',
    lineHeight: '28px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      lineHeight: '20px',
      paddingBottom: '10px',
    },
  },
  titleSize20: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      paddingBottom: '10px',
    },
  },
  titleSize15: {
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '28px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      paddingBottom: '10px',
    },
  },
  titleSize12: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '21px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      paddingBottom: '10px',
    },
  },

  stitle4: {
    fontFeatureSettings: "'dlig' 0, 'liga' 0, 'lnum' 1, 'kern' 1",
    margin: 0,
    color: '#000',
    fontSize: '1rem',
    fontWeight: '500',

    lineHeight: 1.4,

    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      paddingBottom: '10px',
    },
  },

  stitle5: {
    fontFeatureSettings: "'dlig' 0, 'liga' 0, 'lnum' 1, 'kern' 1",
    margin: 0,
    color: '#000',
    fontSize: '0.9rem',
    fontWeight: '500',

    lineHeight: 1.4,

    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      paddingBottom: '10px',
    },
  },

  title5: {
    fontFeatureSettings: "'dlig' 0, 'liga' 0, 'lnum' 1, 'kern' 1",
    margin: 0,
    color: '#000',
    fontSize: '1.0rem',
    fontWeight: '300',
    textTransform: 'uppercase',
    lineHeight: 1.4,

    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      paddingBottom: '10px',
    },
  },

  titleBar: {
    fontFeatureSettings: "'dlig' 0, 'liga' 0, 'lnum' 1, 'kern' 1",
    margin: 0,
    color: '#ffffff',
    fontSize: '1.13rem',
    fontWeight: '500',
    textTransform: 'uppercase',
    lineHeight: 1.4,
    paddingLeft: '10px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  card: {
    overflow: 'unset',
    margin: theme.typography.pxToRem(16),
    borderRadius: '11px',
    border: '1px solid #aaaaaa',
    boxShadow: 'none',
  },
  cardp: {
    overflow: 'unset',
    margin: theme.typography.pxToRem(16),
    borderRadius: '0px',
    border: '1px solid #aaaaaa',
    boxShadow: 'none',
  },
  card2: {
    overflow: 'unset',

    position: 'sticky',
    top: '1rem',
    borderRadius: '11px',
    border: '1px solid #aaaaaa',
    boxShadow: 'none',
    padding: '20px',

    margin: theme.typography.pxToRem(16),
  },
  card3: {
    overflow: 'unset',
    bottom: '1rem',
    borderRadius: '4px',
    border: '1px solid #e1e1e1',
    boxShadow: 'none',
    marginBottom: '16px',
    //margin: theme.typography.pxToRem(16),
  },
  cardContent: {
    position: 'relative',
    padding: 10,
    paddingBottom: 10,
  },
  cardContentP: {
    position: 'relative',
    padding: 10,
    paddingBottom: '8px !important',
  },
  cardContent2: {
    position: 'relative',
    padding: 10,
    //paddingBottom: 10,
    margin: 10,
  },
  cardContentInner2: {
    position: 'relative',
    padding: '3px 10px 10px 10px',
    display: 'block',
  },
  cardContentInner2y: {
    position: 'relative',
    padding: '3px 10px 10px 10px',
    display: 'block',
    [theme.breakpoints.down('sm')]: { paddingTop: '30px' },
  },
  cardContentInner5: {
    position: 'relative',
    paddingLeft: 10,
    display: 'block',
  },
  featured: {
    borderColor: '#008000',
    borderBottomWidth: '0.2rem',
    boxSizing: 'border-box',
    border: '0.2rem solid',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  featuredLabel: {
    position: 'absolute',
    left: '0',
    cursor: 'auto',
    fontSize: '0.7rem',
    fontWeight: '700',
    backgroundColor: '#008000',
    padding: '0.2rem 0.6rem',
    color: '#fff',
    transform: 'rotate(-90deg) translate(-50%, -50%)',
    transformOrigin: 'left center',
    zIndex: 1,
    borderRadius: '0.4rem',
    lineHeight: 'normal',
  },
  cardContentInner2x: {
    paddingLeft: 35,
    paddingRight: 35,
    [theme.breakpoints.down('sm')]: {
      //    minWidth: 100,
      paddingLeft: 15,
      paddingRight: 15,
    },
  },

  cardContentInner6: {
    position: 'relative',
    padding: '3px 10px 10px 10px',
    display: 'block',
  },
  cardContentInner: {
    position: 'relative',
    padding: 10,
  },
  cardContentInnerP: {
    position: 'relative',
    padding: 10,
  },
  titleHeader: {
    fontWeight: 700,
    color: '#063e89',
  },
  formTitle: {
    fontSize: '20px !important',
    color: '#00ad73 !important',
    textTransform: 'uppercase',
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 200,
  },
  guestPrice: {
    minWidth: 200,
  },
  currency: {
    minWidth: 100,
    marginRight: '10px',
  },
  combo2: {
    //    minWidth: 100,
    marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
      //    minWidth: 100,
      maxWidth: '90%',
    },
  },
  combo3: {
    //    minWidth: 100,
    //marginRight: '10px',
    [theme.breakpoints.down('sm')]: {
      //    minWidth: 100,
      maxWidth: '30%',
    },
  },
  notInThisMonthDayPaper: {
    width: '35px',
    height: '35px',
    backgroundColor: '#eeeeee',
    margin: '3px',
    boxShadow: 'none',
    borderRadius: 0,
    padding: '1px',
  },
  normalDayPaper2: {
    width: '35px',
    height: '35px',
    backgroundColor: '#f5e8ea',
    margin: '3px',
    boxShadow: 'none',
    borderRadius: 0,
    padding: '1px',
    cursor: 'pointer',
  },
  normalDayPaper: {
    width: '35px',
    height: '35px',
    backgroundColor: '#e8f5e9',
    margin: '3px',
    boxShadow: 'none',
    borderRadius: 0,
    padding: '1px',
    cursor: 'pointer',
  },
  selectedDayPaper: {
    width: '31px',
    height: '31px',
    backgroundColor: '#f9fbe7',
    margin: '3px',
    boxShadow: 'none',
    borderRadius: 0,
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: 'lime',
    padding: '1px',
    cursor: 'pointer',
  },
  todayPaper: {
    width: '100%',
    height: '100%',
    backgroundColor: 'lightGreen',
    margin: '0px',
    boxShadow: 'none',
    borderRadius: 0,
    padding: '0px',
    cursor: 'pointer',
    color: ' white',
  },
  todayPaper2: {
    width: '100%',
    height: '100%',
    backgroundColor: '#f5e8ea',
    margin: '0px',
    boxShadow: 'none',
    borderRadius: 0,
    padding: '0px',
    cursor: 'pointer',
    color: ' white',
  },
});

class Booking extends React.Component {
  constructor(props) {
    super(props);

    //    const classes = this.useStyles();
    /*const { authUser } = useSelector(({ auth }) => auth);*/
    var defaultPC = 0,
      defaultRC = 0;
    var factor = 1;
    var discount = '';
    var defaultDuration = 12;

    var roomCount = Array(100).fill(1);
    var hotelId = 0;
    var m1 = new Date().getMonth();
    var y1 = new Date().getFullYear();
    var x = 0;

    this.state = {
      adults: 2,
      pageNum: 1,
      maxPages: 1,

      personDialog: false,
      filterDialog: false,

      children: 0,
      bookingMode: 'room',
      availableBookingModes: [],
      coupon: '',
      available: [],
      available2: [],
      activeStep: x,
      selCurrency: '',
      selLC: 0,
      focusedInput: props.autoFocusEndDate ? END_DATE : START_DATE,
      selRC: defaultRC,
      selAV: 0,
      showSlide: false,
      dialogTitle: '',
      selPC: defaultPC,
      selectedView: 0,
      duration: defaultDuration,
      hotelId: hotelId,
      factor: factor,
      discount: discount,
      cColors: [],
      currencyRatio: 1,
      selPayment: '',
      check1: false,
      showLegal: false,
      disableFocus: 0,
      check2: false,
      check3: false,
      showPrivacy: false,
      destinations: [],
      propertytypes: [],
      selpropertytypes: [],
      seldestinations: [],
      amenities: [],
      selamenities: [],
      showPrivacy2: false,
      showPackageData: false,
      showRateData: false,
      isSearchAvailable: false,
      disableSearch: 0,
      disableGuests: 0,
      choosedPerson: 0,

      disableCoupons: 0,

      solPrices: [],
      vid: [],
      solPrices2: [],
      roomCount: roomCount,
      checked: [],
      items: [],
      extrasSel: [],
      creditCardExpireMonth: m1,
      creditCardExpireYear: y1,
      currencies: [],
      bankinfo: '',
      loadingCalendar: false,
      maintanance: 0,
      maintananceMessage: '',
      packages: [],
      extras: [],
      rooms: [],
      cLabels: [],
      cInvalids: [],

      cLabels2: [],
      cInvalids2: [],
      cColors2: [],
      searchMinDays: 1,
      searchMaxDays: 62,

      visiblePackage: [],

      properties: [],
      currentPiD: 0,
      showPayment: false,
      paramHotel: '',
      general: { maxChildren: 0, maxAdults: 2, disableChildren: 0, maxChildrenAge: 15, brandId: 0 },
      results: [],
      sliderData: [],
      roomData: {},
      policies: [],
      guestComment: '',
      source: '',
      selectUnitUnits: [],
      selectUnitVisible: false,
      selectUnitData: {},
      errorCode: 0,
      childAge: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

      loading: true,
      loadingResults: true,

      totalTax: 0,
      totalRoom: 0,
      totalExtras: 0,
      opMode: 0,
      isCancel: false,

      isCC: false,
      total: 0,

      expanded: 'panel3',
      totalPrice: 0,
      allValidTreatments: [],
      currentTreatment: 0,
      validTreatments: [],
      loadedTreatmetns: [],
      validPersons: [],
      treatmentsPersons: [],
      totalSetup: 0,
      showSuccess: false,
      running2: false,
      //loyaltyDiscount: 0,
      /*      firstName: 'xxx',
      lastName: 'xxx',
      email: 'ivan@conexware.com',
      email2: 'ivan@conexware.com',
      address: 'xxx',
      city: 'xxx',
      country: 'xxx',
      phone: '111111',
      postalCode: '1111',*/
    };

    /*services.forEach(function(item) {
      item.count = 1;
    });*/

    this.dayBlocked = this.dayBlocked.bind(this);
    this.getAllowedIds = this.getAllowedIds.bind(this);
    this.getSojernCode = this.getSojernCode.bind(this);
    this.getHotelId = this.getHotelId.bind(this);

    this.dayBlockedA = this.dayBlockedA.bind(this);
    this.monthChange = this.monthChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.getCancelInfo = this.getCancelInfo.bind(this);
    this.getCancelInfo2 = this.getCancelInfo2.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.toogleView = this.toggleView.bind(this);
    this.toogleView2 = this.toggleView2.bind(this);
    this.visiblePackage = this.visiblePackage.bind(this);
    this.largePackage = this.largePackage.bind(this);
    this.getRoomCount = this.getRoomCount.bind(this);
    this.getDestination = this.getDestination.bind(this);
    this.getPropertyType = this.getPropertyType.bind(this);
    this.getTime = this.getTime.bind(this);
    this.getRoom = this.getRoom.bind(this);
    this.hotelSel = this.hotelSel.bind(this);
    this.showRoomMessage = this.showRoomMessage.bind(this);
    this.showRoomMessage2 = this.showRoomMessage2.bind(this);
    this.showAvailableTerms = this.showAvailableTerms.bind(this);
    this.showPersons = this.showPersons.bind(this);
    this.showIcons = this.showIcons.bind(this);
    this.showLast = this.showLast.bind(this);
    this.showAvailableTerms2 = this.showAvailableTerms2.bind(this);
    this.getPayment = this.getPayment.bind(this);
    this.getPaymentInfo = this.getPaymentInfo.bind(this);
    this.isExtraSelected = this.isExtraSelected.bind(this);
    this.extrasSelected = this.extrasSelected.bind(this);
    this.getDayElement = this.getDayElement.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setValue = this.setValue.bind(this);
    this.showPrice = this.showPrice.bind(this);
    this.getRoomPrice = this.getRoomPrice.bind(this);
    this.getPriceModel = this.getPriceModel.bind(this);
    this.getFormated = this.getFormated.bind(this);
    this.getBestPrice = this.getBestPrice.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.loadResults = this.loadResults.bind(this);
    //this.handleMessage = this.handleMessage(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.getExtraPrice = this.getExtraPrice.bind(this);
    this.updateAvailability = this.updateAvailability.bind(this);
    this.checkDates = this.checkDates.bind(this);
    this.getValidTreatments = this.getValidTreatments.bind(this);
    this.addTreatment = this.addTreatment.bind(this);
    this.getOfferText = this.getOfferText.bind(this);
    this.getMealPlan = this.getMealPlan.bind(this);
    this.setLoyalty = this.setLoyalty.bind(this);
    //this.updateRoomsDates(mydate1, mydate2, );

    this.firstService = React.createRef();
    this.datesForm = React.createRef();
    this.firstRoom = React.createRef();
    this.firstProperty = React.createRef();
    this.scroolServide = React.createRef();
    this.firstName = React.createRef();
    this.lastName = React.createRef();
    this.email = React.createRef();
    this.email2 = React.createRef();
    this.address = React.createRef();
    this.city = React.createRef();
    this.country = React.createRef();
    this.phone = React.createRef();

    this.postalCode = React.createRef();
    this.birthDate = React.createRef();
    this.creditCard = React.createRef();
    this.creditCardHolder = React.createRef();
    this.creditCardExpireMonth = React.createRef();
    this.creditCardExpireYear = React.createRef();
    this.creditCardCVV = React.createRef();

    this.getEItems = this.getEItems.bind(this);
  }

  getSojernCode(mode, hp = 0) {
    if (this.state.activeStep == 0) var pitem = this.state.properties.find(({ id }) => id == this.state.currentPiD);
    else var pitem = this.getHotelId();
    if (pitem == null) return;
    var id = '';
    if (mode == 0 && pitem.sojernSearch != undefined) id = pitem.sojernSearch;
    if (mode == 1 && pitem.sojernCart != undefined) id = pitem.sojernCart;
    if (mode == 2 && pitem.sojernConversion != undefined) id = pitem.sojernConversion;
    if (id == '') return;
    var code = '';
    var hd1 = getMyDate(this.state.date1);
    var hd2 = getMyDate(this.state.date2);
    var hcu = this.state.selCurrency;

    if (mode == 0) {
      code = (
        <>
          {(function() {
            /* Please fill the following values. */
            var params = {
              hd1: hd1,
              hd2: hd2,
            };

            /* Please do not modify the below code. */
            if (sjrn_params == undefined) var sjrn_params = {};
            try {
              params = Object.assign({}, sjrn_params, params);
            } catch (e) {}
            var cid = [];
            var paramsArr = [];
            var cidParams = [];
            var pl = document.createElement('iframe');
            var defaultParams = { vid: 'hot', et: 'hs' };
            let key;
            for (key in defaultParams) {
              params[key] = defaultParams[key];
            }
            for (key in cidParams) {
              cid.push(params[cidParams[key]]);
            }
            params.cid = cid.join('|');
            for (key in params) {
              paramsArr.push(key + '=' + encodeURIComponent(params[key]));
            }
            pl.type = 'text/html';
            pl.setAttribute('style', 'height:0; width: 0; display:none;');
            pl.async = true;
            pl.src = 'https://static.sojern.com/cip/w/s?id=' + id + '&f_v=v6_js&p_v=1&' + paramsArr.join('&');
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(pl);
          })()}
        </>
      );
    }

    if (mode == 1) {
      code = (
        <>
          {(function() {
            /* Please fill the following values. */
            var params = {
              hd1: hd1,
              hd2: hd2,
              hp: hp,
              hcu: hcu,
            };

            /* Please do not modify the below code. */
            if (sjrn_params == undefined) var sjrn_params = {};
            try {
              params = Object.assign({}, sjrn_params, params);
            } catch (e) {}
            var cid = [];
            var paramsArr = [];
            var cidParams = [];
            var pl = document.createElement('iframe');
            var defaultParams = { vid: 'hot' };
            let key;
            for (key in defaultParams) {
              params[key] = defaultParams[key];
            }
            for (key in cidParams) {
              cid.push(params[cidParams[key]]);
            }
            params.cid = cid.join('|');
            for (key in params) {
              paramsArr.push(key + '=' + encodeURIComponent(params[key]));
            }
            pl.type = 'text/html';
            pl.setAttribute('style', 'height:0; width: 0; display:none;');
            pl.async = true;
            pl.src = 'https://static.sojern.com/cip/w/s?id=' + id + '&f_v=v6_js&p_v=1&' + paramsArr.join('&');
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(pl);
          })()}
        </>
      );
    }
    if (mode == 2) {
      code = (
        <>
          {(function() {
            /* Please fill the following values. */
            var params = {
              hd1: hd1,
              hd2: hd2,
              hp: hp,
              hcu: hcu,
            };

            /* Please do not modify the below code. */
            if (sjrn_params == undefined) var sjrn_params = {};
            try {
              params = Object.assign({}, sjrn_params, params);
            } catch (e) {}
            var cid = [];
            var paramsArr = [];
            var cidParams = [];
            var pl = document.createElement('iframe');
            var defaultParams = { vid: 'hot', et: 'hc' };
            let key;
            for (key in defaultParams) {
              params[key] = defaultParams[key];
            }
            for (key in cidParams) {
              cid.push(params[cidParams[key]]);
            }
            params.cid = cid.join('|');
            for (key in params) {
              paramsArr.push(key + '=' + encodeURIComponent(params[key]));
            }
            pl.type = 'text/html';
            pl.setAttribute('style', 'height:0; width: 0; display:none;');
            pl.async = true;
            pl.src = 'https://static.sojern.com/cip/w/s?id=' + id + '&f_v=v6_js&p_v=1&' + paramsArr.join('&');
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(pl);
          })()}
        </>
      );
    }
    return code;
  }
  getAllowedIds(mode = 0) {
    var allowedIds = [],
      totalIds = [];
    this.state.properties.map(
      function(pitem) {
        var filterYes = true;
        if (!this.state.seldestinations.includes(pitem.destination)) filterYes = false;
        if (!this.state.selpropertytypes.includes(pitem.propertyType)) filterYes = false;
        if (this.state.selamenities.length > 0 && !Array.isArray(pitem.facilities)) filterYes = false;
        if (this.state.selamenities.length > 0 && Array.isArray(pitem.facilities))
          for (var x = 0; x < this.state.selamenities.length; x++)
            if (!pitem.facilities.includes(this.state.selamenities[x])) filterYes = false;

        if ((pitem.id == this.state.hotelId || this.state.hotelId == 0) && filterYes) totalIds.push(pitem.id);
      }.bind(this),
    );
    var maxPages = 1;
    var z = 0;
    for (var x = (this.state.pageNum - 1) * 10; x < totalIds.length; x++) {
      z++;
      if (z > 10) break;
      allowedIds.push(totalIds[x]);
    }

    maxPages = Math.trunc((totalIds.length - 1) / 10) + 1;
    //this.setState({ allowedIds: allowedIds, totalIds: totalIds,maxPages: maxPages });
    if (mode == 0) return allowedIds;
    if (mode == 1) return totalIds;
    if (mode == 2) return maxPages;
  }
  getExtraPrice(extraId, date) {
    if (this.state.extras == null || this.state.extras.length == 0) return 0;

    var eitem = this.state.extras.find(({ id }) => id == extraId);

    var eprice = eitem.price,
      mdate1 = getMyDate(date);
    if (eitem.pricesPeriods != null && eitem.pricesPeriods.length > 0)
      if (eitem.pricesPeriods.filter(({ from, to }) => from <= mdate1 && to >= mdate1).length > 0)
        eprice = eitem.pricesPeriods.filter(({ from, to }) => from <= mdate1 && to >= mdate1)[0].price;
    return eprice;
  }
  getMealPlan(id) {
    const { intl } = this.props;
    var propertyType = 1;
    if (this.state.properties.length > 0) propertyType = this.state.properties[0].propertyType;
    if (propertyType == null) propertyType = 1;

    if (id == 0 && propertyType == 5) return intl.formatMessage({ id: 'plotRent' });
    if (id == 0 && propertyType != 5) return intl.formatMessage({ id: 'roomOnly' });
    if (id == 1) return intl.formatMessage({ id: 'breakfastIncluded' });
    if (id == 2) return intl.formatMessage({ id: 'halfBoard' });
    if (id == 3) return intl.formatMessage({ id: 'fullBoard' });
    if (id == 4) return intl.formatMessage({ id: 'allInclusive' });
  }
  getOfferText(offerNr) {
    var result = '';
    const locale = this.context.locale.locale;
    const { intl } = this.props;
    if (offerNr == 1) {
      result = intl.formatMessage({ id: 'offer1' });
    } else if (offerNr == 2) {
      result = intl.formatMessage({ id: 'offer2' });
    } else if (offerNr == 3) {
      result = intl.formatMessage({ id: 'offer3' });
    } else if (offerNr == 4) {
      result = intl.formatMessage({ id: 'offer4' });
    } else result = offerNr;

    return result;
  }
  getBestPrice(pid) {
    var bestPrice = 0,
      loading = 1,
      originalPrice = 0,
      badge = '',
      onRequest = 0,
      nights = 0;
    const { classes } = this.props;
    if (this.state.loadingResults) loading = 1;
    else loading = 0;
    this.state.results.map(function(sitem) {
      /*if (sitem.hotelId == pid) */
      if (sitem.maxRooms > 0 && sitem.hotelId == pid && sitem.upsell == 0) {
        if (bestPrice == 0 || sitem.priceFinal + sitem.taxes < bestPrice) {
          bestPrice = sitem.priceFinal + sitem.taxes;
          originalPrice = sitem.priceOriginal + sitem.taxes;
          nights = sitem.nights;
          badge = sitem.badge;
        }
      }
    });
    onRequest = this.state.properties.filter(item => item.id == pid)[0].onRequest;
    if (onRequest == 1 && loading == 0) {
      return <IntlMessages id="_ON_REQUEST" />;
    }
    if (bestPrice == 0 && loading == 0) {
      return <IntlMessages id="_NO_AVAILABLE" />;
    }
    if (bestPrice == 0 && loading == 0) {
      return <IntlMessages id="_NO_AVAILABLE" />;
    }
    if (bestPrice == 0 && loading == 1) {
      return <CircularProgress />;
    }
    return (
      <>
        <IntlMessages id="priceFrom" />{' '}
        {bestPrice != originalPrice && <strike>{this.getRoomPrice(originalPrice, pid)}</strike>}{' '}
        {this.getRoomPrice(bestPrice, pid)}
        {this.state.properties.find(({ id }) => id == pid).pricePerRoom == 1 && (
          <>
            {' '}
            <IntlMessages id="_FOR" /> {this.state.adults} <IntlMessages id="_ADULTS" />
            {this.state.children > 0 && (
              <>
                {', '}
                {this.state.children} <IntlMessages id="_CHILDREN" />,
              </>
            )}
            {', '}
            {nights} {nights == 1 ? <IntlMessages id="_NIGHT" /> : <IntlMessages id="_NIGHTS" />}
          </>
        )}{' '}
        {this.state.properties.find(({ id }) => id == pid).pricePerRoom == 0 && (
          <>
            <IntlMessages id="_PERSON_PER_NIGHT" />
          </>
        )}
        <br />
        {badge != '' && badge != null && (
          <Chip
            style={{
              backgroundColor: '#f2e7bd',
              borderRadius: 2,
              marginRight: 5,
            }}
            className="wbpBadge"
            size="small"
            label={this.getOfferText(badge)}
          />
        )}
      </>
    );
  }

  showIcons(f, size, text = 0) {
    var result = [];
    const { intl } = this.props;
    if (!Array.isArray(f)) return;

    f.forEach(function(item) {
      var t = '',
        t2 = intl.formatMessage({ id: 'f.' + item });
      if (item == 1) {
        t = 'martini-glass-citrus';
      }
      if (item == 4) t = 'paw';
      if (item == 5) t = 'bell-concierge';
      if (item == 18) t = 'snowflake';
      if (item == 7) t = 'tv';
      if (item == 8) t = 'person-swimming';
      if (item == 71) t = 'person-swimming';
      if (item == 11) t = 'kitchen-set';
      if (item == 13) t = 'square-parking';
      if (item == 14) t = 'baby-carriage';
      if (item == 12) t = 'wifi';
      if (item == 17) t = 'handshake';
      if (item == 21) t = 'dumbbell';
      if (item == 23) t = 'spa';
      if (item == 33) t = 'hot-tub-person';
      if (item == 36) t = 'elevator';
      if (item == 28) t = 'radio';
      if (item == 38) t = 'ban-smoking';
      if (item == 39) t = 'umbrella-beach';
      if (item == 40) t = 'water';
      if (item == 41) t = 'utensils';

      if (item == 74) t = 'anchor';
      if (item == 73) t = 'volleyball';
      if (item == 69) t = 'warehouse';
      if (item == 61) t = 'wheelchair';

      if (t != '') {
        if (text == 0) {
          result.push(
            <Tooltip title={t2}>
              <FontAwesomeIcon icon={t} size={size} />
            </Tooltip>,
          );
          result.push(<span>&nbsp;</span>);
        }
      }
      if (text == 1 && t2 != null && t2 != '') result.push(<li>{t2}</li>);
    });
    return result;
  }

  addTreatment(myTid, myPid, datetime) {
    const { intl } = this.props;
    var canAdd = true;
    var message = '';
    var selHotelId = this.state.treatments.find(({ id }) => id == myTid).hotelId;
    //    var roomName = this.state.rooms.find(({ id }) => id == selRoomId).title['en'];
    var alreadyCount = 0;
    this.state.items.forEach(function(item) {
      //if (item.roomId == selRoomId) alreadyCount += item.count;
      if (item.hotelId != selHotelId) {
        canAdd = false;
        message = intl.formatMessage({ id: 'error1' });
      }

      if ((item.datetime == null && datetime != null) || (item.datetime != null && datetime == null)) {
        canAdd = false;
        message = intl.formatMessage({ id: 'error6' });
      }
    });
    //alert(alreadyCount + ' ' + data.maxRooms);
    /*if (alreadyCount + selRoomCount > data.maxRooms) {
      canAdd = false;
      message = intl.formatMessage({ id: 'error3' });
    }*/

    if (message != '') {
      Swal.fire({
        title: intl.formatMessage({ id: '_MESSAGE' }),
        text: message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: intl.formatMessage({ id: 'ok' }),
      }).then(result => {
        //this.doRefresh();
      });
    }
    if (canAdd) {
      this.setState(
        prevState => {
          const oitems = [...prevState.items];
          var data = {};
          data.hotelId = selHotelId;

          var selPayment = prevState.selPayment;
          if (prevState.payments.filter(({ hotelId }) => hotelId == data.hotelId).length == 1)
            selPayment = prevState.payments.filter(({ hotelId }) => hotelId == data.hotelId)[0].id;

          data.count = 1;
          data.priceFinal = this.state.treatments.filter(({ id }) => id == myTid)[0].price;
          data.extraBedCharge = 0;
          data.extraBeds = 0;
          data.taxes = 0;
          //data.roomName = this.state.treatments.filter(({ id }) => id == myTid)[0].title['en'];
          data.treatmentId = myTid;
          if (myPid == 0 && datetime != null) {
            console.log(this.state.validTreatments);
            console.log(
              this.state.validTreatments.filter(
                ({ tid, pid, start }) => tid == myTid && getMyDate3(start, 'en') == getMyDate3(datetime, 'en'),
              ),
            );
            myPid = this.state.validTreatments.filter(
              ({ tid, pid, start }) => tid == myTid && getMyDate3(start, 'en') == getMyDate3(datetime, 'en'),
            )[0].pid;
          }
          data.personId = myPid;
          data.datetime = datetime;

          console.log(data);

          oitems.push(data);
          var myTitle = this.state.title + ': ' + intl.formatMessage({ id: 'step2' });
          document.title = myTitle;

          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'pageview',
            page: {
              title: myTitle,
            },
          });
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'begin_checkout',
            items: this.getEItems(oitems),
          });

          return {
            validTreatments: [],
            choosedPerson: 0,
            currentTreatment: null,
            choosedDateTime: null,
            selPayment: selPayment,
            items: oitems,
            activeStep: 1,
          };
        },
        () => {
          this.firstName.focus();
        },
      );
    }
  }
  getValidTreatments(myTid, load = false, myPid = 0) {
    var response = [];

    load = !this.state.loadedTreatmetns.includes(parseInt(myTid));
    console.log('treamted ' + myTid + ' - ' + '-' + load + '-' + myPid);
    console.log(this.state.loadedTreatmetns);

    if (load == false && myPid == -1) {
      this.setState(prevState => {
        return { validTreatments: [], choosedDateTime: null, currentTreatment: null, choosedPerson: 0, validPersons: [] };
      });
      return response;
    }
    if (load) {
      const senddata = {
        Action: 'getBResults',
        Data: {
          hotelId: this.state.paramHotel,
          tid: myTid,
          coupon: this.state.coupon,
        },
      };
      axios.post(`https://secure.webbookingpro.com/bapi/booking.php`, senddata).then(res => {
        //console.log(res.data);
        if (res.data != null && res.data.data != null) {
          var result = res.data.data;

          if (myTid == 1)
            var result = [
              {
                tid: myTid,
                pid: 1,
                start: '2023-10-17T11:00',
                end: '2023-10-17T11:00',
              },
              {
                tid: myTid,
                pid: 2,
                start: '2023-10-17T12:00',
                end: '2023-10-17T12:00',
              },
            ];
          if (myTid == 2)
            var result = [
              {
                tid: myTid,
                pid: 1,
                start: '2023-10-17T13:00',
                end: '2023-10-17T13:00',
              },
              {
                tid: myTid,
                pid: 2,
                start: '2023-10-17T14:00',
                end: '2023-10-17T14:00',
              },
            ];
          this.setState(prevState => {
            var allValidTreatments = [...prevState.allValidTreatments];
            var x = [...prevState.loadedTreatmetns];
            var result2 = [];
            //result2.push(0);
            x.push(parseInt(myTid));
            //var validTreatments = allValidTreatments.filter(({ tid }) => tid != myTid);
            result.forEach(function(item) {
              allValidTreatments.push(item);
              if (!result2.includes(item.pid)) result2.push(item.pid);
            });
            console.log(allValidTreatments);
            console.log(result);
            console.log(result2);
            console.log(x);

            //validTreatments.push(result);
            return {
              loadedTreatmetns: x,
              allValidTreatments: allValidTreatments,
              validTreatments: result,
              validPersons: result2,
              currentTreatment: myTid,
            };
          });
        }
      });
    } else {
      response = this.state.allValidTreatments.filter(({ tid }) => tid == myTid);
      var result2 = [];
      //result2.push(0);

      response.forEach(function(item) {
        console.log(getMyDate3(item.start, 'en'));
        if (!result2.includes(item.pid)) result2.push(item.pid);
      });
      var cp = this.state.choosedPerson;
      if (cp != 0) response = response.filter(({ pid }) => pid == cp);
      this.state.items.forEach(function(item) {
        console.log(item);
        console.log(getMyDate3(item.datetime, 'en'));
        console.log(response);
        var x = response.findIndex(
          ({ tid, pid, start }) =>
            tid == item.treatmentId && pid == item.personId && getMyDate3(start, 'en') == getMyDate3(item.datetime, 'en'),
        );
        console.log('x=' + x);
        if (x != -1) {
          response.splice(x, 1);
        }
      });

      this.setState({ validPersons: result2, validTreatments: response, currentTreatment: myTid });
    }
    console.log(response);
    return response;
  }
  showLast(ritem) {
    var result = [];
    var t = this.state.results.filter(function(sitem) {
      return sitem.roomId == ritem.id;
    });
    var propertyType = 1;
    if (this.state.properties.length > 0) propertyType = this.state.properties[0].propertyType;
    if (propertyType == null) propertyType = 1;

    const { intl } = this.props;

    if (ritem.count > 1 && t.length > 0 && t[0].maxRooms >= 1 && t[0].maxRooms <= 3) {
      var text = t[0].maxRooms + ' ';
      if (t[0].maxRooms > 1) {
        /*        result.push(
          <span>
            {t[0].maxRooms + ' '}
            {propertyType == 1 && <IntlMessages id="_ROOMS_LEFT" />}
            {propertyType != 1 && <IntlMessages id="_UNITS_LEFT" />}
          </span>,
        );*/
        if (propertyType == 1) text += intl.formatMessage({ id: '_ROOMS_LEFT' });
        else text += intl.formatMessage({ id: '_UNITS_LEFT' });
      } else {
        /*result.push(
            <span>
            {propertyType == 1 && <IntlMessages id="_ROOMS_LAST"/>}
              {propertyType != 1 && <IntlMessages id="_UNITS_LAST"/>}
          </span>,
        );*/
        if (propertyType == 1) text += intl.formatMessage({ id: '_ROOMS_LAST' });
        else text += intl.formatMessage({ id: '_UNITS_LAST' });
      }

      result.push(
        <Chip
          style={{
            backgroundColor: '#ffffff',
            border: '1px solid #ECDC2E',
            borderRadius: 4,
            marginRight: 5,
            marginBottom: 5,
          }}
          className="wbpLastRooms"
          size="small"
          label={text}
        />,
      );
    }
    return result;
  }

  showPersons(ritem) {
    var result = [];
    var t = ritem.max_persons;
    if (ritem.max_adults < ritem.max_persons) t = ritem.max_adults;
    for (let i = 0; i < t; i++) result.push(<PersonIcon style={{ fontSize: 25 }} />);
    if (ritem.max_persons > ritem.max_adults && ritem.max_children > 0) {
      result.push('+');
      for (let i = 0; i < ritem.max_persons - ritem.max_adults; i++) result.push(<PersonIcon style={{ fontSize: 15 }} />);
    }
    return result;
  }

  monthChange(month) {
    //console.log(month);
    const dayFormated = month.format('YYYY-MM-DD');
    this.updateAvailability(dayFormated);
  }

  dayBlockedA(date) {
    return true;
  }
  dayBlocked(date) {
    var dayFormated = date.format('YYYY-MM-DD');
    //  console.log('blocked ' + dayFormated);
    var dayFormateBefore = moment(date)
      .add(-1, 'days')
      .format('YYYY-MM-DD');
    // return false;
    if (this.state.focusedInput == END_DATE) dayFormated = dayFormateBefore;
    if (this.state.available.find(({ d }) => d == dayFormated) != null)
      if (this.state.available.find(({ d }) => d == dayFormated).a == 1) return false;

    return true;
  }

  onFocusChange(focusedInput) {
    this.setState({
      // Force the focusedInput to always be truthy so that dates are always selectable
      focusedInput: !focusedInput ? START_DATE : focusedInput,
    });
  }

  getHotelId() {
    if (this.state.items.length < 1) return {};
    var roomId = this.state.items[0].roomId;
    var propertyId = this.state.rooms.find(({ id }) => id == roomId).hotelId;
    return this.state.properties.find(({ id }) => id == propertyId);
  }

  getDestination(roomId, hotelName, treatment = false) {
    if (treatment == false) {
      var propertyId = this.state.rooms.find(({ id }) => id == roomId).hotelId;
      if (hotelName == true) return this.state.properties.find(({ id }) => id == propertyId).title['en'];
      else return this.state.properties.find(({ id }) => id == propertyId).destination;
    } else {
      var propertyId = this.state.treatments.find(({ id }) => id == roomId).hotelId;
      if (hotelName == true) return this.state.properties.find(({ id }) => id == propertyId).title['en'];
      else return this.state.properties.find(({ id }) => id == propertyId).destination;
    }
  }

  getPropertyType(id) {
    const { intl } = this.props;
    return intl.formatMessage({ id: 'p' + id });
  }

  getTime(roomId, checkOut) {
    var propertyId = this.state.rooms.find(({ id }) => id == roomId).hotelId;
    if (checkOut == true) return this.state.properties.find(({ id }) => id == propertyId).cot;
    else return this.state.properties.find(({ id }) => id == propertyId).cit;
  }

  getCancelInfo(ci, cit) {
    const locale = this.context.locale.locale;

    if (ci == null || ci == 0) return '';
    var today = new Date();
    //console.log('ci ' + this.state.date1 + ' - ' + ci);
    var lcday = new Date(this.state.date1);
    lcday.setDate(lcday.getDate() - parseInt(ci));
    //console.log(lcday);
    if (lcday > today) return getMyDate2(getMyDate(lcday), locale) + ' ' + cit;
    return '';
  }

  getCancelInfo2(lcday, cit) {
    const locale = this.context.locale.locale;

    return getMyDate2(getMyDate(lcday), locale); // + ' ' + cit;
  }

  getEItems(items) {
    var result = [];
    items.forEach(
      function(myitem) {
        var t = {
          currency: this.state.selCurrency,
          coupon: this.state.coupon,
          item_id: myitem.roomId,
          item_name: myitem.roomName,
          quantity: myitem.count,
          price: myitem.priceFinal * this.state.currencyRatio,
          discount: (myitem.priceOriginal - myitem.priceFinal) * this.state.currencyRatio,
        };
        result.push(t);
      }.bind(this),
    );
    return result;
  }

  hotelSel(xhotelId) {
    var result = false;
    result = this.state.items.filter(({ hotelId }) => hotelId == xhotelId).length > 0;
    //result = this.state.paramHotel == xhotelId;
    return result;
  }

  getRoom(roomId) {
    const locale = this.context.locale.locale;
    return this.state.rooms.find(({ id }) => id == roomId).title[locale];
  }

  loadResults() {
    console.log('load results');
    const date1 = new Date(this.state.date1);
    const mdate1 = getMyDate(date1);
    const date2 = new Date(this.state.date2);
    var myCA = JSON.parse(JSON.stringify(this.state.childAge));

    const diffTime = Math.abs(date2 - date1);
    var nights = dateDiffInDays(date1, date2); //Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    var lang = navigator.language || navigator.userLanguage;
    var isMobile = 0;
    if (window.innerWidth < 800) {
      if (/ipad|iphone|ipod|android|ie|blackberry|fennec/.test(navigator.userAgent.toLowerCase())) isMobile = 1;
    }

    this.setState({ loadingResults: true });

    const params = queryString.parse(window.location.search.substring(1));
    if (params != null && params.addCode != null) {
      this.state.extras.forEach(
        function(item) {
          var roomSel = true,
            rateSel = true,
            rCount = 1;
          if (Array.isArray(item.rooms) && item.rooms.length > 0) {
            roomSel = false;
            this.state.items.map(function(x) {
              if (item.rooms.includes(x.roomId)) {
                roomSel = true;
              }
            });
          }
          if (Array.isArray(item.excludeRates) && item.excludeRates.length > 0) {
            this.state.items.map(function(x) {
              if (item.excludeRates.includes(x.rateId)) {
                rateSel = false;
              }
            });
          }

          if (item.mandatory == 1 && this.hotelSel(item.hotelId) && roomSel && rateSel)
            this.setState(prevState => {
              const oitems = [...prevState.extrasSel];
              var i = oitems.findIndex(({ extraId }) => extraId == item.id);
              var price = item.price * 1;
              var eprice = this.getExtraPrice(item.id, this.state.date1);
              /*if (item.pricesPeriods != null && item.pricesPeriods.length > 0)
                eprice = item.pricesPeriods.filter(({ from,to })=>(from<=mdate1 && to>=mdate1))[0].price;*/
              if (i >= 0) {
                oitems[i].count = rCount;
                oitems[i].price = eprice * oitems[i].count;
              } else oitems.push({ extraId: item.id, count: rCount, price: eprice });
              return { extrasSel: oitems };
              //x
            });
        }.bind(this),
      );
      var res = [];
      var resitem = { hotelId: this.state.paramHotel, roomId: parseInt(params.addRoomId) };
      if (params.addServiceId != null) resitem.serviceId = parseInt(params.addServiceId);
      if (params.addRateId != null) resitem.rateId = parseInt(params.addRateId);
      if (params.addPackageId != null) resitem.packageId = parseInt(params.addPackageId);
      resitem.fromDate = this.state.date1;
      resitem.toDate = this.state.date2;
      resitem.nights = parseInt(params.addNights);
      resitem.policyId = parseInt(params.addPolicyId);
      resitem.adults = parseInt(params.adults);
      resitem.children = parseInt(params.children);
      resitem.childAge = params.addChildAge;
      resitem.count = parseInt(params.addRooms);
      resitem.taxes = params.addTaxes;
      resitem.mealPrice = params.addMealPrice;
      resitem.priceOriginal = params.addPriceOriginal;
      resitem.priceFinal = params.addPriceFinal;
      resitem.freeCancel = params.addFreeCancel;
      resitem.partialCancel = params.addPartialCancel;
      resitem.prePayment = params.addPrePayment;
      resitem.customPolicyId = parseInt(params.addPolicyId);
      resitem.code = params.addCode;

      res.push(resitem);
      var firstName = String(params.addName).split(' ')[0],
        lastName = String(params.addName).split(' ')[1];

      console.log(res);

      this.setState(
        {
          hotelId: parseInt(this.state.paramHotel),
          nights: parseInt(params.addNights),
          loadingResults: false,
          firstName: firstName,
          lastName: lastName,
          email: params.addEmail,
          email2: params.addEmail,
          selPayment: params.addPayment,
          activeStep: 1,
          items: res,
          results: [],
        },
        () => {},
      );
      return;
    }

    if (this.state.bookingId == null) {
      var loadHotels = this.state.properties.length > 1;
      /*this.getAllowedIds().forEach(
        function(itemx) {
          if (!Array.isArray(this.state.properties.filter(item => item.id == itemx)[0].rooms)) {
            loadHotels = true;
            return;
          }
        }.bind(this),
      );*/

      if (loadHotels) {
        const senddata = {
          Action: 'getHotelInfo',
          Data: {
            hotelId: this.getAllowedIds().join(','),
            full: 1,
          },
        };

        axios.post(`https://secure.webbookingpro.com/bapi/booking.php`, senddata).then(res => {
          //console.log(res.data);
          if (res.data != null && res.data.data != null) {
            this.setState(prevState => {
              var rooms = [];
              var services = [];
              var packages = [];
              var rates = [];
              var extras = [];
              var payments = [];
              var policies = [];
              if (Array.isArray(prevState.rooms)) rooms = [...prevState.rooms];
              if (Array.isArray(prevState.services)) services = [...prevState.rooms];
              if (Array.isArray(prevState.packages)) packages = [...prevState.packages];
              if (Array.isArray(prevState.rates)) rates = [...prevState.rates];
              if (Array.isArray(prevState.extras)) extras = [...prevState.extras];
              if (Array.isArray(prevState.policies)) policies = [...prevState.policies];
              if (Array.isArray(prevState.payments)) payments = [...prevState.payments];
              res.data.data.rooms.forEach(function(item) {
                if (rooms.filter(({ id }) => id == item.id).length == 0) rooms.push(item);
              });
              res.data.data.packages.forEach(function(item) {
                if (packages.filter(({ id }) => id == item.id).length == 0) packages.push(item);
              });
              res.data.data.rates.forEach(function(item) {
                if (rates.filter(({ id }) => id == item.id).length == 0) rates.push(item);
              });
              res.data.data.extras.forEach(function(item) {
                if (extras.filter(({ id }) => id == item.id).length == 0) extras.push(item);
              });
              res.data.data.services.forEach(function(item) {
                if (services.filter(({ id }) => id == item.id).length == 0) services.push(item);
              });
              res.data.data.policies.forEach(function(item) {
                if (policies.filter(({ id }) => id == item.id).length == 0) policies.push(item);
              });
              res.data.data.payments.forEach(function(item) {
                if (payments.filter(({ id }) => id == item.id).length == 0) payments.push(item);
              });

              return {
                rooms: rooms,
                services: services,
                packages: packages,
                rates: rates,
                extras: extras,
                policies: policies,
                payments: payments,
              };
            });
          }
        });
        //   return;
      }

      const senddata = {
        Action: 'getResults',
        Data: {
          hotelId: this.getAllowedIds().join(','),
          fromDate: getMyDate(this.state.date1),
          toDate: getMyDate(this.state.date2),
          adults: this.state.adults,
          children: this.state.children,
          coupon: this.state.coupon,
          language: lang,
          isMobile: isMobile,
          childAge: myCA.slice(0, this.state.children).toString(),
        },
      };

      if (Array.isArray(this.state.extras))
        this.state.extras.forEach(
          function(item) {
            var roomSel = true,
              rateSel = true,
              rCount = 1;
            if (Array.isArray(item.rooms) && item.rooms.length > 0) {
              roomSel = false;
              this.state.items.map(function(x) {
                if (item.rooms.includes(x.roomId)) {
                  roomSel = true;
                }
              });
            }
            if (Array.isArray(item.excludeRates) && item.excludeRates.length > 0) {
              this.state.items.map(function(x) {
                if (item.excludeRates.includes(x.rateId)) {
                  rateSel = false;
                }
              });
            }
            if (item.mandatory == 1 && this.hotelSel(item.hotelId) && roomSel && rateSel)
              this.setState(prevState => {
                const oitems = [...prevState.extrasSel];
                var i = oitems.findIndex(({ extraId }) => extraId == item.id);
                var price = item.price * 1;
                /*        var eprice = item.price;
              if (item.pricesPeriods != null && item.pricesPeriods.length > 0)
                eprice = item.pricesPeriods.filter(({ from,to })=>(from<=mdate1 && to>=mdate1))[0].price;*/
                var eprice = this.getExtraPrice(item.id, this.state.date1);

                if (i >= 0) {
                  oitems[i].count = rCount;
                  oitems[i].price = eprice * oitems[i].count;
                } else oitems.push({ extraId: item.id, count: rCount, price: eprice });
                return { extrasSel: oitems };
                //x
              });
          }.bind(this),
        );

      axios.post(`https://secure.webbookingpro.com/bapi/booking.php`, senddata).then(res => {
        //console.log(res.data);
        if (res.data != null && res.data.data != null) {
          this.setState({ selectedView: 0, loadingResults: false, nights: nights, results: res.data.data }, () => {
            console.log('firstserviced');
            console.log(this.firstService);
            if (this.firstService != null && this.state.disableFocus == 0)
              try {
                this.firstService.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              } catch (error) {}

            if (this.firstService == null && this.firstRoom != null && this.state.disableFocus == 0)
              try {
                this.firstRoom.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              } catch (error) {}
          });

          if (this.state.currentPiD == 0 && this.state.properties.length > 0)
            if (this.firstProperty != null && this.state.disableFocus == 0)
              try {
                this.firstProperty.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              } catch (error) {}
        }
      });
    } else {
      const senddata = {
        Action: 'getBookingInfo',
        Data: {
          bookingId: this.state.bookingId,
          control: this.state.control,
          amount: this.state.paymentAmount,
        },
      };
      const params = queryString.parse(window.location.search.substring(1));

      if (params != null && params.paymentid != null) {
        senddata.Data.paymentId = params.paymentid;
      }
      this.state.extras.forEach(
        function(item) {
          var roomSel = true,
            rateSel = true,
            rCount = 1;
          if (Array.isArray(item.rooms) && item.rooms.length > 0) {
            roomSel = false;
            this.state.items.map(function(x) {
              if (item.rooms.includes(x.roomId)) {
                roomSel = true;
              }
            });
          }
          if (Array.isArray(item.excludeRates) && item.excludeRates.length > 0) {
            this.state.items.map(function(x) {
              if (item.excludeRates.includes(x.rateId)) {
                rateSel = false;
              }
            });
          }
          if (item.mandatory == 1 && this.hotelSel(item.hotelId) && roomSel && rateSel)
            this.setState(prevState => {
              const oitems = [...prevState.extrasSel];
              var i = oitems.findIndex(({ extraId }) => extraId == item.id);
              var price = item.price * 1;
              /*var eprice = item.price;
              if (item.pricesPeriods != null && item.pricesPeriods.length > 0)
                eprice = item.pricesPeriods.filter(({ from,to })=>(from<=mdate1 && to>=mdate1))[0].price;*/
              var eprice = this.getExtraPrice(item.id, this.state.date1);

              if (i >= 0) {
                oitems[i].count = rCount;
                oitems[i].price = eprice * oitems[i].count;
              } else oitems.push({ extraId: item.id, count: rCount, price: eprice });
              return { extrasSel: oitems };
            });
        }.bind(this),
      );

      axios.post(`https://secure.webbookingpro.com/bapi/booking.php`, senddata).then(res => {
        //console.log(res.data);
        if (res.data != null && res.data.data.items != null) {
          var selPayment = this.state.selPayment;
          if (this.state.payments.filter(({ hotelId }) => hotelId == this.state.paramHotel).length == 1)
            selPayment = this.state.payments.filter(({ hotelId }) => hotelId == this.state.paramHotel)[0].id;

          const params = queryString.parse(window.location.search.substring(1));
          if (params != null && params.paymentid != null) {
            //      selPayment = params.paymentid;
          }

          var myIsCancel = false,
            myOpMode = 0,
            myIsCC = false,
            activeStep = 1,
            ec = 0;
          myIsCC = this.props.isCC === true || this.state.action == 'cc';
          if (myIsCC) {
            if (res.data.data.status < 2) ec = 1;

            if (res.data.data.paid != null && res.data.data.paid == 0) ec = 1;

            activeStep = 2;

            this.setState({ errorCode: ec, bookingNumber: this.state.bookingId, hotelEmail: res.data.data.contactEmail });
          }
          if (res.data.data.status == 2 && myIsCC == false) {
            myIsCancel = true;
            myOpMode = 1;
          }

          if (res.data.data.status <= 2 && this.state.paymentAmount !== null && this.state.paymentAmount > 0) {
            myOpMode = 2;
          }
          //alert(myOpMode);
          if (res.data.data.status == 6) return;

          this.setState(
            {
              loadingResults: false,
              nights: res.data.data.nights,
              adults: res.data.data.adults,
              children: res.data.data.children,
              date1: res.data.data.checkin,
              date2: res.data.data.checkout,
              items: res.data.data.items,
              bankinfo: res.data.data.bankinfo,
              firstName: res.data.data.firstName,
              lastName: res.data.data.lastName,
              email: res.data.data.email,
              isCancel: myIsCancel,
              opMode: myOpMode,
              email2: res.data.data.email,
              hotelId: res.data.data.hotelId,
              selPayment: selPayment,
              activeStep: activeStep,
              results: res.data.data.data,
            },
            () => {},
          );
        }
      });
    }
  }

  toggleView(mode) {
    if (mode == 3) this.updateAvailability(this.state.date1);
    if (this.state.selectedView == 0 && mode == 3 && this.state.selectedDate == null) {
      if (!this.dayBlocked(moment(this.state.date1)))
        this.setState({ selectedDate: [moment(this.state.date1), moment(this.state.date2)] });
      //else
    }

    if (this.state.selectedView == mode) this.setState({ selectedView: 0 });
    else
      this.setState({ selectedView: mode }, () => {
        if (this.datesForm != null && mode == 3)
          try {
            this.datesForm.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          } catch (error) {}
      });
  }

  toggleView2(ritem, mode) {
    this.setState(prevState => {
      const oitems = [...prevState.rooms];
      var i = oitems.findIndex(({ id }) => id == ritem.id);
      oitems[i].showAllRates = mode;
      return { rooms: oitems };
    });
  }

  showRoomMessage(data) {
    if (data == 0)
      return (
        <>
          <MyAlert severity="warning">
            <IntlMessages id="_NO_AVAILABLE_ROOMS" />
          </MyAlert>
          <br />
        </>
      );
  }

  showRoomMessage2(data) {
    var locale = this.context.locale.locale;
    if (data < 10) {
      if (data == 1)
        return (
          <>
            <MyAlert severity="warning">
              <IntlMessages id="error4" />
            </MyAlert>
            <br />
          </>
        );

      if (data == 2)
        return (
          <>
            <MyAlert severity="warning">
              <IntlMessages id="error5" />
            </MyAlert>
            <br />
          </>
        );
    }
    if (data == 1500)
      return (
        <>
          <MyAlert severity="warning">
            <IntlMessages id="_ON_REQUEST2" />
          </MyAlert>
          <br />
        </>
      );

    if (data >= 10 && data < 100) {
      return (
        <>
          <MyAlert severity="warning">
            <IntlMessages id="_MSN_MINIMUM_NIGHTS" />
            {': '}
            {data - 10}
          </MyAlert>
          <br />
        </>
      );
    }
    if (data >= 100 && data < 1000) {
      return (
        <>
          <MyAlert severity="warning">
            <IntlMessages id="_MSN_MAXIMUM_NIGHTS" />
            {': '}
            {data - 100}
          </MyAlert>
          <br />
        </>
      );
    }

    if (data >= 1000 && data < 1100) {
      return (
        <>
          <MyAlert severity="warning">
            <IntlMessages id="roomAccept" />
            {data - 1000} <IntlMessages id="persons" />
            {'.'}
          </MyAlert>
          <br />
        </>
      );
    }
    if (data >= 1100 && data < 1200) {
      return (
        <>
          <MyAlert severity="warning">
            <IntlMessages id="roomAccept" />
            {data - 1100} <IntlMessages id="adults" />
            {'.'}
          </MyAlert>
          <br />
        </>
      );
    }
    if (data >= 1300 && data < 1400) {
      return (
        <>
          <MyAlert severity="warning">
            <IntlMessages id="arrivalAfter" /> {getMyDate2(myFormatDate(moment().add(data - 1300, 'days')), locale)}
            {'.'}
          </MyAlert>
          <br />
        </>
      );
    }
    if (data >= 1200 && data < 1300) {
      return (
        <>
          <MyAlert severity="warning">
            <IntlMessages id="roomAccept" />
            {data - 1200} <IntlMessages id="children" />
            {'.'}
          </MyAlert>
          <br />
        </>
      );
    }

    return null;
  }

  updateAvailability(day2, forRoom = false) {
    var date = new Date(day2),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m - 1, 1);
    var middleDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 2, 0);

    if (forRoom == false) {
      if (this.state.available.find(({ d }) => d == myFormatDate(firstDay)) != null)
        if (this.state.available.find(({ d }) => d == myFormatDate(lastDay)) != null)
          if (this.state.available.find(({ d }) => d == myFormatDate(middleDay)) != null) return;

      this.setState({ loadingCalendar: true });
      const senddata = {
        Action: 'getAvailable',
        Data: {
          hotelId: this.state.currentPiD == 0 ? this.state.paramHotel : this.state.currentPiD,
          fromDate: myFormatDate(firstDay),
          toDate: myFormatDate(lastDay),
          coupon: this.state.coupon,
        },
      };
      if (this.state.userRoomId != null && this.state.userRoomId != -1 && this.state.userRoomId > 0)
        senddata.Data.roomId = parseInt(this.state.userRoomId);
      const currencyCode = this.state.currencyCode;
      axios.post(`https://secure.webbookingpro.com/bapi/booking.php`, senddata).then(res => {
        //console.log(res.data);
        if (res.data != null && res.data.data != null) {
          this.setState(prevState => {
            const newItems = [...prevState.available];
            const newInvalids = [...prevState.cInvalids];
            const newColors = [...prevState.cColors];
            const newLabels = [...prevState.cLabels];
            const gtoday = moment().format('YYYY-MM-DD');

            const locale = this.context.locale.locale;
            res.data.data.forEach(function(item) {
              newItems.push(item);

              var z = newLabels.findIndex(({ date }) => date == item.d);
              if (z == -1 && item.d >= gtoday && item.p > 0) {
                newLabels.push({ date: item.d, text: formatPrice(item.p, locale) + ' ' + currencyCode });
              } else {
                //newLabels[z].text = formatPrice(item.p, locale) + ' ' + currencyCode;
              }

              if (item.a == 0) {
                z = newInvalids.findIndex(start => start == item.d);
                if (z == -1) {
                  newInvalids.push(item.d);
                  var today = new Date(item.d);
                  var tomorrow = new Date(today.valueOf());
                  tomorrow.setDate(today.getDate() + 1);
                  var yesterday = new Date(today.valueOf());
                  yesterday.setDate(today.getDate() - 1);
                  var ti = res.data.data.findIndex(({ d }) => d == myFormatDate(tomorrow));
                  var yi = res.data.data.findIndex(({ d }) => d == myFormatDate(yesterday));
                  //                newColors.splice()
                  if (ti != -1 && res.data.data[ti].a == 0 && yi != -1 && res.data.data[yi].a == 0)
                    newColors.push({ date: item.d, background: '#eeeeee', cellCssClass: 'vacation-booked' });
                  else if (yi != -1 && res.data.data[yi].a == 1 && ti != -1 && res.data.data[ti].a == 0) {
                    //console.log('checkin', item.d);
                    newColors.push({ date: item.d, cellCssClass: 'check-in' });
                  } else if (yi != -1 && res.data.data[yi].a == 0 && ti != -1 && res.data.data[ti].a == 1) {
                    //console.log('checkout', item.d);
                    newColors.push({ date: item.d, cellCssClass: 'check-out' });
                  } else if (yi != -1 && res.data.data[yi].a == 1 && ti != -1 && res.data.data[ti].a == 1) {
                    //console.log('checkout', item.d);
                    newColors.push({ date: item.d, cellCssClass: 'check-in' });
                  }
                }
              } else {
                /*z = newInvalids.findIndex(start => start == item.d);
                if (z == -1) {
                  newInvalids.splice(z, 1);
                }*/
              }
            });
            return {
              cColors: newColors,
              available: newItems,
              cLabels: newLabels,
              cInvalids: newInvalids,
              loadingCalendar: false,
            };
          });
        }
      });
    } else {
      if (this.state.available2.find(({ d }) => d == myFormatDate(firstDay)) != null)
        if (this.state.available2.find(({ d }) => d == myFormatDate(lastDay)) != null)
          if (this.state.available2.find(({ d }) => d == myFormatDate(middleDay)) != null) return;
      /*if (this.state.available2.find(({ d }) => d == myFormatDate(firstDay)) != null)
        if (this.state.available2.find(({ d }) => d == myFormatDate(lastDay)) != null) return;*/

      this.setState({ loadingCalendar: true });
      const senddata = {
        Action: 'getAvailable',
        Data: {
          hotelId: this.state.currentPiD == 0 ? this.state.paramHotel : this.state.currentPiD,
          fromDate: myFormatDate(firstDay),
          roomId: this.state.selRoomId,
          toDate: myFormatDate(lastDay),
          coupon: this.state.coupon,
        },
      };
      const currencyCode = this.state.currencyCode;
      axios.post(`https://secure.webbookingpro.com/bapi/booking.php`, senddata).then(res => {
        //console.log(res.data);
        if (res.data != null && res.data.data != null) {
          this.setState(prevState => {
            const newItems = [...prevState.available2];
            const newInvalids = [...prevState.cInvalids2];
            const newColors = [...prevState.cColors2];
            const newLabels = [...prevState.cLabels2];
            const gtoday = moment().format('YYYY-MM-DD');

            const locale = this.context.locale.locale;
            res.data.data.forEach(function(item) {
              newItems.push(item);

              var z = newLabels.findIndex(({ date }) => date == item.d);
              if (z == -1 && item.d >= gtoday && item.p > 0) {
                newLabels.push({ date: item.d, text: formatPrice(item.p, locale) + ' ' + currencyCode });
              } else {
                //newLabels[z].text = formatPrice(item.p, locale) + ' ' + currencyCode;
              }

              if (item.a == 0) {
                z = newInvalids.findIndex(start => start == item.d);
                if (z == -1) {
                  newInvalids.push(item.d);
                  var today = new Date(item.d);
                  var tomorrow = new Date(today.valueOf());
                  tomorrow.setDate(today.getDate() + 1);
                  var yesterday = new Date(today.valueOf());
                  yesterday.setDate(today.getDate() - 1);
                  var ti = res.data.data.findIndex(({ d }) => d == myFormatDate(tomorrow));
                  var yi = res.data.data.findIndex(({ d }) => d == myFormatDate(yesterday));
                  //                newColors.splice()
                  if (ti != -1 && res.data.data[ti].a == 0 && yi != -1 && res.data.data[yi].a == 0)
                    newColors.push({ date: item.d, background: '#eeeeee', cellCssClass: 'vacation-booked' });
                  else if (yi != -1 && res.data.data[yi].a == 1 && ti != -1 && res.data.data[ti].a == 0) {
                    //console.log('checkin', item.d);
                    newColors.push({ date: item.d, cellCssClass: 'check-in' });
                  } else if (yi != -1 && res.data.data[yi].a == 0 && ti != -1 && res.data.data[ti].a == 1) {
                    //console.log('checkout', item.d);
                    newColors.push({ date: item.d, cellCssClass: 'check-out' });
                  } else if (yi != -1 && res.data.data[yi].a == 1 && ti != -1 && res.data.data[ti].a == 1) {
                    //console.log('checkout', item.d);
                    newColors.push({ date: item.d, cellCssClass: 'check-in' });
                  }
                }
              } else {
                /*z = newInvalids.findIndex(start => start == item.d);
                if (z == -1) {
                  newInvalids.splice(z, 1);
                }*/
              }
            });
            return {
              cColors2: newColors,
              available2: newItems,
              cLabels2: newLabels,
              cInvalids2: newInvalids,
              loadingCalendar: false,
            };
          });
        }
      });
    }
  }

  handleMonthChange(day2) {
    //console.log(day2);
    var date = new Date(day2),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);

    const dayFormatted = myFormatDate(firstDay);
    //console.log(dayFormatted);
    //console.log(this.state.available);
    if (this.state.available.find(({ d }) => d == dayFormatted) != null) {
      const dayFormatted = myFormatDate(lastDay);
      if (this.state.available.find(({ d }) => d == dayFormatted) != null) return;
    }

    this.updateAvailability(day2);
  }

  getDayElement(date, selectedDates, pickersDayProps, dayComponent) {
    //generate boolean
    var isSunny = false;
    if (date == null) return;
    const day = new Date(date);

    const dayFormated = myFormatDate(day);

    var isSelected = false;
    var isToday = true;
    const { classes } = this.props;
    if (this.state.available.find(({ d }) => d == dayFormated) != null)
      if (this.state.available.find(({ d }) => d == dayFormated).a == 1) isSunny = true;

    let dateTile;
    if (1 == 1) {
      //conditionally return appropriate Element of date tile.
      if (isSunny) {
        dateTile = (
          <Paper className={isSelected ? classes.selectedDayPaper : isToday ? classes.todayPaper : classes.normalDayPaper}>
            {dayComponent}
          </Paper>
        );
      } else {
        dateTile = (
          <Paper className={isSelected ? classes.selectedDayPaper : isToday ? classes.todayPaper2 : classes.normalDayPaper2}>
            {dayComponent}
          </Paper>
        );
      }
    } else {
      dateTile = (
        <Paper className={classes.notInThisMonthDayPaper}>
          <Grid item>
            <br />
          </Grid>
          <Grid item style={{ color: 'lightGrey' }}>
            {day.getDate()}
          </Grid>
        </Paper>
      );
    }
    return dateTile;
  }
  showAvailableTerms(data, roomData) {
    //  console.log(roomData);
    return (
      <Button
        variant="contained"
        color="primary"
        size="normal"
        onClick={() => {
          var days = 1;
          var days2 = 62;
          if (roomData.packageId != null) {
            days = roomData.nights;
            days2 = roomData.night;
          }

          this.setState(
            {
              available2: [],
              cColors2: [],
              cInvalids2: [],
              cLabels2: [],
              isSearchAvailable: true,
              searchMinDays: days,
              searchMaxDays: days2,
              selectedDate2: [null, null],
              selRoomId: roomData.roomId,
              selPackageId: null,
              selectedView: 3,
            },
            () => {
              this.updateAvailability(/*this.state.date1*/ moment().format('YYYY-MM-DD'), true);
            },
          );
        }}>
        <IntlMessages id="findAvailableTerms" />
      </Button>
    );

    const locale = this.context.locale.locale;
    if (!Array.isArray(data)) return;
    var res = data.map(
      function(item) {
        var today = new Date(item.day);

        var tomorrow = new Date(today.valueOf());
        //tomorrow.setDate(tomorrow.getDate() + res.data.data.general.defaultDays);

        tomorrow.setDate(today.getDate() + 1);

        if (item.a > 0)
          return (
            <Chip
              label={getMyDate2(item.day, locale)}
              className="wbpAvailableDay"
              style={{ backgroundColor: '#00B294', marginRight: 10, borderRadius: 0, marginBottom: 10 }}
              onClick={() => {
                this.setState({ date1: item.day, date2: getMyDate(tomorrow), selectedDate: [today, tomorrow] }, () => {
                  this.loadResults();
                });
              }}
            />
          );

        if (item.a == 0)
          return (
            <Chip
              label={getMyDate2(item.day, locale)}
              className="wbpUnAvailableDay"
              style={{ backgroundColor: '#E74856', marginRight: 10, borderRadius: 0, marginBottom: 10 }}
            />
          );
      }.bind(this),
    );
    return res;
  }

  showAvailableTerms2(data, upsell = 0, roomData) {
    const locale = this.context.locale.locale;
    var res2 = (
      <>
        <MyAlert severity="warning">
          <IntlMessages id="noPackageAvailable" />
        </MyAlert>
        <br />
      </>
    );
    if (data == null) return;
    var res = (
      <Button
        variant="contained"
        color="primary"
        size="normal"
        onClick={() => {
          var days = 1,
            days2 = 62;
          if (data.packageId != null) {
            days = data.nights;
            days2 = data.nights;
          }
          this.setState(
            {
              available2: [],
              cColors2: [],
              cInvalids2: [],
              cLabels2: [],
              isSearchAvailable: true,
              searchMinDays: days,
              searchMaxDays: days2,
              selectedDate2: [null, null],
              selRoomId: data.roomId,
              selPackageId: 1,
              selectedView: 3,
            },
            () => {
              this.updateAvailability(this.state.date1, true);
            },
          );
        }}>
        <IntlMessages id="findAvailableTerms" />
      </Button>
    );
    /*var res = data.map(
      function(item) {
        var today = new Date(item.day);

        var tomorrow = new Date(today.valueOf());
        //tomorrow.setDate(tomorrow.getDate() + res.data.data.general.defaultDays);

        tomorrow.setDate(today.getDate() + 1);

        if (item.a > 0)
          return (
            <Chip
              label={getMyDate2(item.day1, locale) + ' - ' + getMyDate2(item.day2, locale)}
              className="wbpAvailableDay"
              style={{ backgroundColor: '#00B294', marginRight: 10, borderRadius: 0, marginBottom: 10 }}
              onClick={() => {
                this.setState({ date1: item.day1, date2: item.day2 }, () => {
                  this.loadResults();
                });
              }}
            />
          );

        if (item.a == 0)
          return (
            <Chip
              className="wbpUnAvailableDay"
              label={getMyDate2(item.day1, locale) + ' - ' + getMyDate2(item.day2, locale)}
              style={{ backgroundColor: '#E74856', marginRight: 10, borderRadius: 0, marginBottom: 10 }}
            />
          );
      }.bind(this),
    );*/
    if (upsell == 1) return res;
    else return [res2, res];
  }

  checkDates(run) {
    const date1 = new Date(this.state.date1);
    const date2 = new Date(this.state.date2);
    const diffTime = Math.abs(date2 - date1);
    var nights = dateDiffInDays(date1, date2); //Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    this.setState({ nights: nights });

    if (this.state.date2 == null || getMyDate(this.state.date2) <= getMyDate(this.state.date1)) {
      var newdate = new Date(this.state.date1);

      newdate.setDate(newdate.getDate() + 1);
      this.setState({ date2: newdate }, () => {
        if (run) this.loadResults();
      });
    }

    if (run == true) this.loadResults();
  }
  handleMessage = ev => {
    //console.log(ev.data);

    if (typeof ev.data !== 'object') return;
    if (!ev.data.type) return;
    if (ev.data.type !== 'closePaymentDialog') return;
    if (ev.data.message !== 0 && ev.data.message !== 1) return;
    this.setState({ showPayment: false, activeStep: 2, errorCode: ev.data.message });
  };

  componentDidMount() {
    window.addEventListener('message', this.handleMessage);
    /*window.addEventListener('message', (ev: MessageEvent<{ type: string, message: string }>) => {
      console.log(ev.data);
      if (typeof ev.data !== 'object') return;
      if (!ev.data.type) return;
      if (ev.data.type !== 'closePaymentDialog') return;
      if (ev.data.message !== 0 && ev.data.message !== 1) return;
      this.setState({ showPayment: false, activeStep: 2, error: ev.data.message });
    });*/

    const { intl } = this.props;
    var hotelId = this.props.match.params.hotelId;
    var bookingId = this.props.match.params.bookingId;
    var amount = this.props.match.params.amount;
    var control = this.props.match.params.control;
    var action = '';
    var maintanance = 0;

    var userCurrency = '',
      userCheckin = '',
      userCheckout = '',
      userAdults = 2,
      userChildren = 0,
      userShowLogo = '',
      userDisableBackdrop = 0,
      userDisableCheckOut = 0,
      userBookingMode = 'room',
      userDisableGuests = 0,
      userDisableCoupons = 0,
      userPackage = '',
      userRateId = '',
      userDisableSearch = 0,
      userShowProperty = 1,
      userShowFooter = 1,
      userShowSummary = 1,
      userColumns = 1,
      userMaxRates = 1,
      userDisableFocus = 0,
      minDays = 1,
      userSource = '',
      userShowWBP = 1,
      userShowLanguage = 1,
      userShowAvailableFirst = 1,
      userRoomId = -1,
      userpayment = '',
      userCoupon = '',
      userPriorityRoomId = -1;

    //if (hotelId == 11266) userShowSummary = 1;
    if (window.wbpSettings != null) {
      const settings = window.wbpSettings;
      if (settings.hotelId != null) hotelId = settings.hotelId;
      if (settings.hotelId != null) hotelId = settings.hotelId;
      if (settings.roomId != null) userRoomId = settings.roomId;
      if (settings.priorityRoomId != null) userPriorityRoomId = settings.priorityRoomId;

      if (settings.currency != null) userCurrency = settings.currency;
      if (settings.showLogo != null) userShowLogo = settings.showLogo;
      if (settings.disableBackdrop != null) userDisableBackdrop = settings.disableBackdrop;
      if (settings.disableSearch != null) userDisableSearch = settings.disableSearch;
      if (settings.showFooter != null) userShowFooter = settings.showFooter;
      if (settings.showSummary != null) userShowSummary = settings.showSummary;
      if (settings.columns != null) userColumns = settings.columns;
      if (settings.maxRates != null) userMaxRates = settings.maxRates;
      if (settings.disableFocus != null) userDisableFocus = settings.disableFocus;
      if (settings.showWBP != null) userShowWBP = settings.showWBP;
      if (settings.showLanguage != null) userShowLanguage = settings.showLanguage;
      if (settings.showAvailableFirst != null) userShowAvailableFirst = settings.showAvailableFirst;
      if (settings.bookingMode != null) userBookingMode = settings.bookingMode;
      if (settings.showProperty != null) userShowProperty = settings.showProperty;
      if (settings.source != null) userSource = settings.source;
      if (settings.fixedAdults != null) {
        userAdults = parseInt(settings.fixedAdults);
        if (userAdults <= 0 || isNaN(userAdults)) userAdults = 2;
      }
      if (settings.fixedChildren != null && settings.fixedChildren != '') userChildren = parseInt(settings.fixedChildren);
      if (settings.fixedCheckIn != null && settings.fixedCheckIn != '') userCheckin = settings.fixedCheckIn;
      if (settings.fixedCheckOut != null && settings.fixedCheckOut != '') userCheckout = settings.fixedCheckOut;
      if (settings.disableCheckOut != null) userDisableCheckOut = settings.disableCheckOut;
      if (settings.disableGuests != null) userDisableGuests = settings.disableGuests;
      if (settings.disableCoupons != null) userDisableCoupons = settings.disableCoupons;
      if (settings.coupon != null) userCoupon = settings.coupon;
      if (settings.checkIn != null) userCheckin = settings.checkIn;
    }
    const params = queryString.parse(window.location.search.substring(1));
    if (params != null && params.currency != null) {
      userCurrency = params.currency;
    }
    if (params != null && params.checkin != null) {
      userCheckin = unescape(params.checkin);
      if (userCheckin.includes('/')) {
        userCheckin = userCheckin
          .split('/')
          .reverse()
          .join('-');
      }
    }
    if (params != null && params.bid != null) {
      bookingId = params.bid;
      control = params.control;
      action = 'cc';
    }
    if (params != null && params.checkout != null) {
      userCheckout = unescape(params.checkout);

      if (userCheckout.includes('/')) {
        userCheckout = userCheckout
          .split('/')
          .reverse()
          .join('-');
      }
    }
    if (
      params != null &&
      params.check_in_day != null &&
      params.check_in_month != null &&
      params.check_in_year != null &&
      params.nights != null
    ) {
      userCheckin = params.check_in_year + '-' + params.check_in_month + '-' + params.check_in_day;
      userCheckout = moment(userCheckin)
        .add(params.nights, 'days')
        .format('YYYY-MM-DD');
    }
    if (params != null && params.paymentid != null) {
      userpayment = params.paymentid;
    }
    if (params != null && params.checkin_date != null) {
      userCheckin = unescape(params.checkin_date);
      if (userCheckin.includes('/')) {
        userCheckin = userCheckin
          .split('/')
          .reverse()
          .join('-');
      }

      if (userCheckin.includes('.')) {
        userCheckin = userCheckin
          .split('.')
          .reverse()
          .join('-');
      }
    }
    if (params != null && params.from_date != null) {
      userCheckin = unescape(params.from_date);
      if (userCheckin.includes('/')) {
        userCheckin = userCheckin
          .split('/')
          .reverse()
          .join('-');
      }
    }
    if (params != null && params.to_date != null) {
      userCheckout = unescape(params.to_date);
      if (userCheckout.includes('/')) {
        userCheckout = userCheckout
          .split('/')
          .reverse()
          .join('-');
      }
    }
    if (params != null && params.checkout_date != null) {
      userCheckout = unescape(params.checkout_date);
      if (userCheckout.includes('/')) {
        userCheckout = userCheckout
          .split('/')
          .reverse()
          .join('-');
      }
      if (userCheckout.includes('.')) {
        userCheckout = userCheckout
          .split('.')
          .reverse()
          .join('-');
      }
    }
    if (params != null && params.adults != null) {
      userAdults = parseInt(params.adults);

      if (userAdults <= 0 || isNaN(userAdults)) userAdults = 2;
    }
    if (params != null && params.bookingMode != null) {
      userBookingMode = params.bookingMode;
    }
    if (params != null && params.coupon != null) {
      userCoupon = params.coupon;
    }

    if (params != null && params.source != null) {
      userSource = params.source;
    }

    if (params != null && params.children != null) {
      userChildren = parseInt(params.children);
      if (userChildren <= 0 || isNaN(userChildren)) userChildren = 0;
    }
    if (params != null && params.showProperty != null) {
      userShowProperty = params.showProperty;
    }
    if (params != null && params.showAvailableFirst != null) {
      userShowAvailableFirst = params.showAvailableFirst;
    }
    if (params != null && params.roomId != null) {
      userRoomId = params.roomId;
    }
    if (params != null && params.hotelId != null) {
      hotelId = params.hotelId;
    }
    if (params != null && params.packageId != null) {
      userPackage = params.packageId;
    }
    if (params != null && params.minDays != null) {
      minDays = parseInt(params.minDays);
    }
    if (params != null && params.rateId != null) {
      userRateId = params.rateId;
    }

    if (params != null && params.packageid != null) {
      userPackage = params.packageid;
    }

    if (params != null && params.showlogo != null) {
      userShowLogo = params.showlogo;
    }
    if (params != null && params.disableBackdrop != null) {
      userDisableBackdrop = params.disableBackdrop;
    }
    if (params != null && params.disableGuests != null) {
      userDisableGuests = params.disableGuests;
    }
    if (params != null && params.disableCheckOut != null) {
      userDisableCheckOut = params.disableCheckOut;
    }
    if (params != null && params.priorityRoomId != null) {
      userPriorityRoomId = params.priorityRoomId;
    }
    if (params != null && params.showfooter != null) {
      userShowFooter = params.showfooter;
    }

    if (params != null && params.showsummary != null) {
      userShowSummary = params.showsummary;
    }
    if (params != null && params.columns != null) {
      userColumns = params.columns;
    }
    if (params != null && params.maxrates != null) {
      userMaxRates = params.maxrates;
    }
    if (params != null && params.disableFocus != null) {
      userDisableFocus = params.disableFocus;
    }

    if (hotelId == null || hotelId == '') {
      if (window.location.hostname === 'apartmani-toplice.com') hotelId = 36;
      if (window.location.hostname === 'www.apartmani-toplice.com') hotelId = 36;
      if (window.location.hostname === 'villasalvia-rovinj.com') hotelId = 11242;
      if (window.location.hostname === 'www.villasalvia-rovinj.com') hotelId = 11242;
    }

    const senddata = {
      Action: 'getHotelInfo',
      Data: {
        hotelId: hotelId,
      },
    };

    axios.post(`https://secure.webbookingpro.com/bapi/booking.php`, senddata).then(res => {
      //console.log(res.data);
      if (res.data != null && res.data.maintanance != null && res.data.maintanance == 1) {
        this.setState({ maintanance: 1, maintananceMessage: res.data.Message });
      }
      if (res.data != null && res.data.data != null) {
        if (res.data.data.general.brandId == null || res.data.data.general.brandId == 0) {
          var link = document.createElement('link'),
            oldLink = document.getElementById('dynamic-favicon');
          link.id = 'dynamic-favicon';
          link.rel = 'icon';
          link.href = '/images/favicon1.svg';
          if (oldLink) {
            document.head.removeChild(oldLink);
          }
          document.head.appendChild(link);
          document.title = 'WebBookingPro';
        }
        if (res.data.data.general.brandId == 1) {
          document.title = 'forotel';
          var link = document.createElement('link'),
            oldLink = document.getElementById('dynamic-favicon');
          link.id = 'dynamic-favicon';
          link.rel = 'icon';
          link.href = '/images/favicon2.svg';
          if (oldLink) {
            document.head.removeChild(oldLink);
          }
          document.head.appendChild(link);
        }

        if (res.data.data.general.startDate != '') {
          var uca = res.data.data.general.startDate.split('-');
          var today = new Date(uca[0], uca[1] - 1, uca[2], 12, 0, 0, 0);
          //var today = getMyDate(res.data.data.general.startDate);
        } else var today = new Date();

        if (userPackage != '') {
          res.data.data.general.defaultDays = res.data.data.packages.filter(({ id }) => id == userPackage)[0].nights;
          minDays = res.data.data.general.defaultDays;
        }
        console.log('mindays' + minDays);

        var tomorrow = new Date(today.valueOf());
        var x = res.data.data.general.defaultDays;
        if (minDays > x) x = minDays;
        tomorrow.setDate(tomorrow.getDate() + x);

        if (userCheckin != '') {
          console.log(userCheckin);
          var uca = userCheckin.split('-');
          var today = new Date(uca[0], uca[1] - 1, uca[2], 12, 0, 0, 0);

          console.log('today');
          console.log(today);
          var tomorrow = new Date(today.valueOf());
          tomorrow.setDate(tomorrow.getDate() + res.data.data.general.defaultDays);
        }
        if (userCheckout != '') {
          var uca = userCheckout.split('-');
          var tomorrow = new Date(uca[0], uca[1] - 1, uca[2], 12, 0, 0, 0);
        }
        var defaultcurrency = '',
          defaultsymbol = '',
          defaultratio = 1;
        if (res.data.data.currencies.length > 0) {
          var curId = 0;
          if (userCurrency != '') curId = res.data.data.currencies.findIndex(({ code }) => code == userCurrency);

          defaultcurrency = res.data.data.currencies[curId].code;
          defaultsymbol = res.data.data.currencies[curId].symbol;
          defaultratio = res.data.data.currencies[curId].ratio;
        }

        const diffTime = Math.abs(tomorrow - today);
        var nights = dateDiffInDays(tomorrow, today); //Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        var languageData = [];
        const myArray = res.data.data.general.languages.split(',');
        myArray.forEach(function(item) {
          var languageItem = {};
          if (item == 'en') {
            languageItem = {
              languageId: 'english',
              locale: 'en',
              name: 'English',
              icon: 'us',
            };
          }
          if (item == 'en') {
            languageItem = {
              languageId: 'english',
              locale: 'en',
              name: 'English',
              icon: 'us',
            };
          }
          if (item == 'hr') {
            languageItem = {
              languageId: 'croatian',
              locale: 'hr',
              name: 'Hrvatski',
              icon: 'hr',
            };
          }
          if (item == 'al') {
            languageItem = {
              languageId: 'albanian',
              locale: 'al',
              name: 'Shqip',
              icon: 'al',
            };
          }
          if (item == 'de') {
            languageItem = {
              languageId: 'german',
              locale: 'de',
              name: 'Deutsch',
              icon: 'de',
            };
          }
          if (item == 'it') {
            languageItem = {
              languageId: 'italian',
              locale: 'it',
              name: 'Italiano',
              icon: 'it',
            };
          }
          if (item == 'sl') {
            languageItem = {
              languageId: 'slovenian',
              locale: 'sl',
              name: 'Slovenščina',
              icon: 'sl',
            };
          }
          if (item == 'sr') {
            languageItem = {
              languageId: 'serbian',
              locale: 'sr',
              name: 'Srpski',
              icon: 'rs',
            };
          }
          if (item == 'es') {
            languageItem = {
              languageId: 'spanish',
              locale: 'es',
              name: 'Espaniol',
              icon: 'es',
            };
          }
          if (item == 'ba') {
            languageItem = {
              languageId: 'bosnian',
              locale: 'ba',
              name: 'Bosnian',
              icon: 'ba',
            };
          }
          if (item == 'me') {
            languageItem = {
              languageId: 'montenegro',
              locale: 'me',
              name: 'Montenegro',
              icon: 'me',
            };
          }
          if (item == 'mk') {
            languageItem = {
              languageId: 'macedonian',
              locale: 'mk',
              name: 'Macedonian',
              icon: 'mk',
            };
          }
          languageData.push(languageItem);
        });
        var panel = 'panel3';
        res.data.data.properties.sort((a, b) =>
          a.propertyType > b.propertyType ? 1 : a.propertyType < b.propertyType ? -1 : 0,
        );
        if (Array.isArray(res.data.data.extras) && res.data.data.extras.length == 0) panel = 'panel2';
        var myTitle = '';
        if (res.data.data.properties.length > 0) myTitle = res.data.data.properties[0].title['en'];
        else myTitle = 'WebBookingPro';
        if (
          res.data.data.general.ownerName != undefined &&
          res.data.data.general.ownerName != null &&
          res.data.data.general.ownerName != ''
        )
          myTitle = res.data.data.general.ownerName;
        var bookingMode = this.state.bookingMode;
        document.title = myTitle + ': ' + intl.formatMessage({ id: 'step1' });

        if (userShowLogo != '') res.data.data.general.showLogo = userShowLogo;

        if (userShowFooter != '') res.data.data.general.showFooter = userShowFooter;
        if (userShowSummary != '') res.data.data.general.showSummary = userShowSummary;
        if (userColumns != '') res.data.data.general.columns = userColumns;
        if (userMaxRates != '') res.data.data.general.maxRates = userMaxRates;
        if (userShowWBP != '') res.data.data.general.showWBP = userShowWBP;
        if (userShowLanguage != '') res.data.data.general.showLanguage = userShowLanguage;
        if (userShowAvailableFirst != '') res.data.data.general.showAvailableFirst = userShowAvailableFirst;

        if (res.data.data.properties.length > 0 && res.data.data.properties[0].footer == '')
          res.data.data.general.showFooter = 0;
        var pid = 0;
        if (res.data.data.properties.length == 1) pid = res.data.data.properties[0].id;

        var childAge = Array(10).fill(res.data.data.general.maxChildrenAge);
        var selPayment = '';

        if (Array.isArray(res.data.data.payments) && res.data.data.payments.length == 1)
          selPayment = res.data.data.payments.filter(({ hotelId }) => hotelId == hotelId)[0].id;

        var amenities = [];
        var destinations = [];
        var propertytypes = [];
        res.data.data.properties.forEach(function(item) {
          if (item.destination != '' && destinations.indexOf(item.destination) == -1) destinations.push(item.destination);
          if (propertytypes.indexOf(item.propertyType) == -1) propertytypes.push(item.propertyType);
        });
        res.data.data.properties.forEach(function(item) {
          if (Array.isArray(item.facilities))
            item.facilities.map(function(item2) {
              if (amenities.indexOf(item2) == -1) amenities.push(item2);
            });
        });
        /*amenities.sort(function(a, b) {
          if (ax < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });*/
        destinations.sort(function(a, b) {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        });

        this.setState(
          {
            maintanance: maintanance,
            date1: getMyDate(today),
            paramHotel: hotelId,
            bookingId: bookingId,
            control: control,
            action: action,
            destinations: destinations,
            propertytypes: propertytypes,
            selpropertytypes: JSON.parse(JSON.stringify(propertytypes)),
            seldestinations: JSON.parse(JSON.stringify(destinations)),
            amenities: amenities,
            //            selamenities: [,
            nights: nights,
            paymentAmount: amount,
            title: myTitle,
            disableFocus: userDisableFocus,
            disableCheckOut: userDisableCheckOut,
            currentPiD: pid,
            date2: getMyDate(tomorrow),
            currencyCode: defaultsymbol,
            currencyRatio: defaultratio,
            selCurrency: defaultcurrency,
            general: res.data.data.general,
            currencies: res.data.data.currencies,
            properties: res.data.data.properties,
            rooms: res.data.data.rooms,
            services: res.data.data.services,
            treatmentsGroups: res.data.data.treatmentsGroups,
            treatments: res.data.data.treatments,
            treatmentsPersons: res.data.data.treatmentsPersons,
            childAge: childAge,
            rates: res.data.data.rates,
            packages: res.data.data.packages,
            extras: res.data.data.extras,
            policies: res.data.data.policies,
            userPackage: userPackage,
            userRateId: userRateId,
            payments: res.data.data.payments,
            adults: userAdults,
            source: userSource,
            bookingMode: userBookingMode,
            children: userChildren,
            showProperty: userShowProperty,
            disableSearch: userDisableSearch,
            disableBackdrop: userDisableBackdrop,
            disableGuests: userDisableGuests,
            showAvailableFirst: userShowAvailableFirst,
            userDisableCoupons: userDisableCoupons,
            minDays: minDays,
            coupon: userCoupon,
            userRoomId: userRoomId,
            userPriorityRoomId: userPriorityRoomId,
            expanded: panel,
            //            selectedDate: [getMyDate(today), getMyDate(tomorrow)],
            selPayment: selPayment,
            languageData: languageData,
            loading: false,
          },
          () => {
            if (userBookingMode == 'room') {
              this.loadResults();
              this.updateAvailability(today);
              if (today.getMonth() != tomorrow.getMonth()) this.updateAvailability(tomorrow);
            }
          },
        );

        //const dispatch = useDispatch();
        //dispatch(fetchSuccess());
      }
    });
  }

  getPriceModel(propertyId) {
    var pricePerRoom = this.state.properties.find(({ id }) => id == propertyId).pricePerRoom;
    const { intl } = this.props;
    if (pricePerRoom == 1) return intl.formatMessage({ id: '_RATE_PER_NIGHT' });
    if (pricePerRoom == 0) return intl.formatMessage({ id: '_PERSON_PER_NIGHT' });
  }
  getRoomPrice(price, propertyId) {
    const locale = this.context.locale.locale;
    var pricePerRoom = this.state.properties.find(({ id }) => id == propertyId).pricePerRoom;
    var finalPrice = this.state.properties.find(({ id }) => id == propertyId).finalPrice;
    if (pricePerRoom == 0) price = price / this.state.adults / this.state.nights;
    var x = 2;
    //  if (finalPrice != 'nochange') x = 0;
    price = price * this.state.currencyRatio;
    var res = formatPrice(price, locale, x) + ' ' + this.state.currencyCode;
    return res;
  }

  getFormated(price) {
    const locale = this.context.locale.locale;

    price = price * this.state.currencyRatio;
    var finalPrice = this.state.properties[0].finalPrice;
    var x = 2;
    var res = '';
    if (formatPrice(price, locale, x) != 'NaN') res = formatPrice(price, locale, x) + ' ' + this.state.currencyCode;
    return res;
  }

  getPayment(id) {
    const { intl } = this.props;
    if (id == 'online') return intl.formatMessage({ id: '_CREDIT_CARD' });
    else if (id == 'bank.transfer') return intl.formatMessage({ id: '_BANK_TRANSFER' });
    else if (id == 'poa') return intl.formatMessage({ id: '_PAY_ON_ARRIVAL' });
    else return intl.formatMessage({ id: 'creditCardPayment' }) + id;

    //return id;
  }

  getPaymentInfo() {
    var pid = this.state.selPayment;
    if (pid == null || pid == '') return '';
    if (pid == 'contract') return 'Payment by Contract.';
    var info = this.state.payments.find(({ id }) => id == pid).paymentInfo;
    return info;
  }

  isSelected(data) {
    var result = false;
    this.state.items.forEach(function(item) {
      if (
        item.roomId == data.roomId &&
        data.serviceId != null &&
        item.serviceId == data.serviceId &&
        item.policyId == data.policyId
      ) {
        result = true;
      }
      if (
        item.roomId == data.roomId &&
        data.packageId != null &&
        item.packageId == data.packageId &&
        item.policyId == data.policyId
      ) {
        result = true;
      }
      if (
        item.roomId == data.roomId &&
        data.rateId != null &&
        item.rateId == data.rateId &&
        item.policyId == data.policyId
      ) {
        result = true;
      }
    });
    return result;
  }

  visiblePackage(data) {
    var result = false;
    if (this.state.visiblePackage[data] == 1) result = true;
    return result;
  }

  largePackage(data) {
    //var p = false;
    var s = this.state.results.filter(function(sitem) {
      return sitem.roomId == data.roomId && sitem.packageId != null;
    });
    if (s.length <= 2) return false;

    const locale = this.context.locale.locale;
    var p = this.state.packages.find(({ id }) => id == data.packageId).description[locale];
    if (String(p).length > 512) return true;
    return false;
  }

  getRoomCount(data) {
    var result = false;
    this.state.items.forEach(function(item) {
      if (item.roomId == data.roomId && item.serviceId == data.serviceId && item.policyId == data.policyId) {
        result = item.count;
      }
    });
    return 1;
  }

  isExtraSelected(id) {
    var result = false;
    this.state.extrasSel.forEach(function(item) {
      if (item.extraId == id) {
        result = true;
      }
    });
    return result;
  }

  extrasSelected(id) {
    var result = '';
    this.state.extrasSel.forEach(function(item) {
      if (item.extraId == id) {
        result = item.count + ' x ';
      }
    });
    return result;
  }

  setValue(index, price, setupPrice, checkedValue, myvid) {
    var tp = 0,
      ts = 0;
    this.setState(prevState => {
      const solPrices = [...prevState.solPrices];
      const solPrices2 = [...prevState.solPrices2];
      const vid = [...prevState.vid];
      const checked = [...prevState.checked];
      solPrices[index] = price;
      solPrices2[index] = setupPrice;
      vid[index] = myvid;
      checked[index] = checkedValue;
      solPrices.forEach(function(item) {
        if (item != null && item != -1) tp += parseFloat(item);
      });
      solPrices2.forEach(function(item) {
        if (item != null && item != -1) ts += parseFloat(item);
      });
      //alert(JSON.stringify(solPrices2) + ' ' + ts);
      return { vid: vid, solPrices2: solPrices2, solPrices: solPrices, checked: checked, totalPrice: tp, totalSetup: ts };
    });
  }

  setLoyalty(email, day) {
    const { intl } = this.props;
    const senddata = {
      Action: 'getLoyaltyDiscount',
      Data: {
        hotelId: this.state.paramHotel,
        email: email,
        startDay: day,
        items: this.state.items,
      },
    };
    axios.post(`https://secure.webbookingpro.com/bapi/booking.php`, senddata).then(res => {
      //console.log(res.data);
      if (res.data != null && res.data.data != null) {
        this.setState(prevState => {
          const itemsx = [...prevState.items];
          var has = 0;
          itemsx.forEach(function(items, index) {
            itemsx[index].loyaltyDiscount = res.data.data[index];
            if (res.data.data[index] > 0) has = 1;
          });
          if (has == 1)
            Swal.fire({
              title: intl.formatMessage({ id: 'hasLoyaltyTitle' }),
              text: intl.formatMessage({ id: 'hasLoyaltyDiscount' }),
              icon: 'information',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: intl.formatMessage({ id: 'ok' }),
            }).then(result => {
              //this.doRefresh();
            });
          return { items: itemsx };
        });
      }
    });
  }

  handleChange(name, value, value2) {
    if (value == null) return;
    console.log(name, value);

    if (name == 'roomCount') {
      this.setState(prevState => {
        const roomCount = [...prevState.roomCount];
        roomCount[value2] = value;
        return { roomCount: roomCount };
      });
      return;
    }
    if (name == 'childAge') {
      console.log(this.state.childAge);
      console.log(value2);
      this.setState(
        prevState => {
          const childAge = [...prevState.childAge];
          childAge[value2] = value;

          return { childAge: childAge };
        },
        () => {
          //  this.loadResults();
          console.log(this.state.items);
        },
      );
      return;
    }
    if (name == 'childAgex') {
      console.log(this.state.childAge);
      console.log(value2);
      this.setState(
        prevState => {
          const childAge = [...prevState.childAgex];
          childAge[value2] = value;

          return { childAgex: childAge };
        },
        () => {
          //  this.loadResults();
          console.log(this.state.items);
        },
      );
      return;
    }
    if (name == 'selCurrency') {
      var ca = this.state.currencies.filter(function(item) {
        return item.code == value;
      });
      var ci = ca[0];
      this.setState({ selCurrency: value, currencyRatio: ci.ratio, currencyCode: ci.symbol });
    }
    if (name == 'amenities') {
      if (value) {
        if (!this.state.selamenities.includes(value2)) this.state.selamenities.push(value2);
      } else {
        if (this.state.selamenities.includes(value2))
          this.state.selamenities.splice(this.state.selamenities.indexOf(value2), 1);
      }
      return;
    }
    if (name == 'destinations') {
      if (value) {
        if (!this.state.seldestinations.includes(value2)) this.state.seldestinations.push(value2);
      } else {
        if (this.state.seldestinations.includes(value2))
          this.state.seldestinations.splice(this.state.seldestinations.indexOf(value2), 1);
      }
      return;
    }
    if (name == 'propertyType') {
      if (value) {
        if (!this.state.selpropertytypes.includes(value2)) this.state.selpropertytypes.push(value2);
      } else {
        if (this.state.selpropertytypes.includes(value2))
          this.state.selpropertytypes.splice(this.state.selpropertytypes.indexOf(value2), 1);
      }
      return;
    }
    if (name == 'check1') {
      if (this.state.check1 == true) {
        this.setState({ check1: false });
      } else {
        var hotelId = this.state.items[0].hotelId;
        var windowPolicy = this.state.properties.filter(({ id }) => id == hotelId)[0].windowPolicy;

        if (windowPolicy == 1) this.setState({ showLegal: true });
        else this.setState({ check1: true });
      }
      return;
    }
    if (name == 'check2') {
      if (this.state.check2 == true) {
        this.setState({ check2: false });
      } else {
        this.setState({ check2: true });
      }
      return;
    }

    this.setState({ [name]: value }, () => {
      //   this.updateRooms();
      if (name == 'adults' || name == 'children') {
        //  this.loadResults();
      }
      if (name == 'email2' && this.state.email == this.state.email2 && this.state.email2 != '') {
        this.setLoyalty(this.state.email2, this.state.date1);
      }
      if (name == 'choosedPerson') {
        this.getValidTreatments(this.state.currentTreatment, false, value);
      }
    });
    if (name == 'adults' || name == 'children') {
      this.setState({ selectedView: 0 });
      //    this.loadResults();
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ showSuccess: false });
  };

  handleClose3 = () => {
    this.setState({
      showSlide: false,
      showPackageData: false,
      showRateData: false,
      showPrivacy2: false,
      isSearchAvailable: false,
      selectedView: 0,
      showPrivacy: false,
    });
  };

  handleClose2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ errorCode: 0 });
  };

  handleSubmit(action, data, data2) {
    const locale = this.context.locale.locale;
    const { intl } = this.props;
    if (action == 'RunAgain') {
      this.setState({ bookingId: null, activeStep: 0, extrasSel: [], items: [] }, () => {
        this.loadResults();
      });
    }
    if (action == 'hideProperty') {
      this.setState({ currentPiD: 0, cColors: [], items: [], available: [], cLabels: [], cInvalids: [] }, () => {
        if (this.firstProperty != null && this.state.disableFocus == 0)
          try {
            this.firstProperty.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          } catch (error) {}
        return;
      });
    }

    if (action == 'showProperty') {
      this.setState({ currentPiD: data, cColors: [], available: [], cLabels: [], cInvalids: [] }, () => {
        this.updateAvailability(today);
        if (this.firstService != null && this.state.disableFocus == 0)
          try {
            this.firstService.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          } catch (error) {}
        if (this.firstService == null && this.firstRoom != null && this.state.disableFocus == 0)
          try {
            this.firstRoom.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          } catch (error) {}

        return;
      });
    }
    if (action == 'ShowPackage') {
      var pd = this.state.packages.find(({ id }) => id == data.packageId).description[locale];

      var pdt = this.state.packages.find(({ id }) => id == data.packageId).title[locale];

      this.setState({
        showPrivacy2: false,
        showSlide: true,
        showPackageData: true,
        showRateData: false,
        dialogTitle: pdt,
        sliderData: data2.pictures,
        roomData: data2,
        packageData: pd,
      });
      /*this.setState(prevState => {
        const oitems = [...prevState.visiblePackage];
        oitems[data] = 1;
        return { packageData: pd, visiblePackage: oitems };
      });*/
    }
    if (action == 'HidePackage') {
      this.setState(prevState => {
        const oitems = [...prevState.visiblePackage];
        oitems[data] = 0;
        return { visiblePackage: oitems };
      });
    }
    if (action == 'Remove') {
      this.setState(prevState => {
        const oitems = [...prevState.items];
        var i = oitems.findIndex(
          ({ roomId, serviceId, policyId, unitId }) =>
            roomId === data.roomId && serviceId === data.serviceId && policyId === data.policyId && unitId === data.unitId,
        );
        oitems.splice(i, 1);

        const oitems2 = [...prevState.extrasSel];
        var exid = prevState.extras.findIndex(
          item =>
            item.mandatory == 1 && Array.isArray(item.rooms) && item.rooms.length > 0 && item.rooms.includes(data.roomId),
        );
        if (exid != -1) {
          var i = oitems2.findIndex(x => x.extraId == this.state.extras[exid].id);

          if (i != -1) oitems2.splice(i, 1);
        }
        document.title = this.state.title + ': ' + intl.formatMessage({ id: 'step1' });
        return { extrasSel: oitems2, items: oitems, activeStep: 0 };
      });
    }
    if (action == 'Remove2') {
      this.setState(prevState => {
        const oitems = [...prevState.items];
        console.log('delete');
        console.log(oitems);
        console.log(data);
        var i = oitems.findIndex(
          ({ treatmentId, personId, datetime }) =>
            datetime === data.datetime && personId === data.personId && treatmentId === data.treatmentId,
        );
        console.log('delete ' + i);
        oitems.splice(i, 1);
        document.title = this.state.title + ': ' + intl.formatMessage({ id: 'step1' });
        return { items: oitems, activeStep: 0 };
      });
    }
    if (action == 'showSlide') {
      var dialogTitle = data.title[locale];

      this.setState({
        showPrivacy2: false,
        showSlide: true,
        showPackageData: false,
        showRateData: false,
        dialogTitle: dialogTitle,
        sliderData: data.pictures,
        roomData: data,
      });
    }
    if (action == 'Select') {
      var newNights = data.nights;

      var today = new Date(this.state.date1);
      var tomorrow = new Date(today.valueOf());
      //tomorrow.setDate(tomorrow.getDate() + res.data.data.general.defaultDays);
      tomorrow.setDate(tomorrow.getDate() + parseInt(newNights));
      this.setState({ date1: getMyDate(today), date2: getMyDate(tomorrow), selectedDate: [today, tomorrow] }, () => {
        this.loadResults();
      });
    }
    if (action == 'Reserve') {
      var canAdd = true;
      var message = '';
      var selRoomId = data.roomId;
      var selRoomCount = this.state.roomCount[data.index];
      var selHotelId = this.state.rooms.find(({ id }) => id == selRoomId).hotelId;
      var roomName = this.state.rooms.find(({ id }) => id == selRoomId).title['en'];
      var unitSelect = this.state.rooms.find(({ id }) => id == selRoomId).unitSelect;
      var onRequest = this.state.properties.find(({ id }) => id == selHotelId).onRequest;
      if (unitSelect == undefined || unitSelect == null) unitSelect = 0;

      var alreadyCount = 0;
      this.state.items.forEach(function(item) {
        if (item.roomId == selRoomId) alreadyCount += item.count;
        if (item.hotelId != selHotelId) {
          canAdd = false;
          message = intl.formatMessage({ id: 'error1' });
        }

        if (item.policyId != data.policyId) {
          canAdd = false;
          message = intl.formatMessage({ id: 'error2' });
        }
      });
      //alert(alreadyCount + ' ' + data.maxRooms);
      if (alreadyCount + selRoomCount > data.maxRooms && onRequest == 0) {
        canAdd = false;
        message = intl.formatMessage({ id: 'error3' });
      }

      if (message != '') {
        Swal.fire({
          title: intl.formatMessage({ id: '_MESSAGE' }),
          text: message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: intl.formatMessage({ id: 'ok' }),
        }).then(result => {
          //this.doRefresh();
        });
      }
      if (unitSelect == 1 && data.unitId == null) {
        const senddata = {
          Action: 'getUnits',
          Data: {
            hotelId: this.state.paramHotel,
            roomId: selRoomId,
            fromDate: getMyDate(this.state.date1),
            toDate: getMyDate(this.state.date2),
          },
        };

        axios.post(`https://secure.webbookingpro.com/bapi/booking.php`, senddata).then(res => {
          //console.log(res.data);
          if (res.data != null && res.data.result != null) {
            var y2 = res.data.result;
            var y = [];
            y2.map(
              function(item) {
                var z = this.state.items.filter(itemx => itemx.unitId == item.id);
                if (z.length == 0) y.push(item);
              }.bind(this),
            );
            var x = 0;
            if (y.length > 0) x = y[0].id;
            this.setState({
              selectUnitVisible: true,
              selectUnitData: data,
              selectUnitUnitId: x,
              selectUnitUnits: y,
            });
          }
        });
        canAdd = false;
      }
      if (onRequest == 1) alreadyCount = 0;
      if (canAdd) {
        this.setState(
          prevState => {
            const oeitems = [...prevState.extrasSel];

            const oitems = [...prevState.items];
            data = JSON.parse(JSON.stringify(data));
            data.hotelId = this.state.rooms.find(({ id }) => id == data.roomId).hotelId;

            var selPayment = prevState.selPayment;
            if (prevState.payments.filter(({ hotelId }) => hotelId == data.hotelId).length == 1)
              selPayment = prevState.payments.filter(({ hotelId }) => hotelId == data.hotelId)[0].id;
            const policyId = data.policyId;
            var ge = prevState.email,
              ge2 = prevState.email2;

            if (prevState.policies.filter(({ hotelId, id }) => hotelId == data.hotelId && data.policyId == id).length == 1) {
              if (
                prevState.policies.filter(({ hotelId, id }) => hotelId == data.hotelId && data.policyId == id)[0]
                  .payByContract == 1
              )
                selPayment = 'contract';
              ge = prevState.policies.filter(({ hotelId, id }) => hotelId == data.hotelId && data.policyId == id)[0]
                .customEmail;
              ge2 = prevState.policies.filter(({ hotelId, id }) => hotelId == data.hotelId && data.policyId == id)[0]
                .customEmail;
            }

            data.count = prevState.roomCount[data.index];
            data.extraBedCharge = 0;
            data.extraBeds = 0;
            data.roomName = roomName;
            data.adults = this.state.adults;
            data.children = this.state.children;
            var myCA = JSON.parse(JSON.stringify(this.state.childAge));
            data.childAge = myCA.slice(0, this.state.children).toString();
            oitems.push(data);
            //console.log(oitems);
            var myTitle = this.state.title + ': ' + intl.formatMessage({ id: 'step2' });
            document.title = myTitle;

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'pageview',
              page: {
                title: myTitle,
              },
            });
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'begin_checkout',
              items: this.getEItems(oitems),
            });
            this.state.extras.forEach(
              function(item) {
                var roomSel = true,
                  rateSel = true,
                  rCount = 1;
                if (Array.isArray(item.rooms) && item.rooms.length > 0) {
                  roomSel = false;
                  oitems.map(function(x) {
                    if (item.rooms.includes(x.roomId)) {
                      roomSel = true;
                    }
                  });
                }
                if (Array.isArray(item.excludeRates) && item.excludeRates.length > 0) {
                  oitems.map(function(x) {
                    if (item.excludeRates.includes(x.rateId)) {
                      roomSel = false;
                    }
                  });
                }
                if (item.mandatory == 1) {
                  //    this.setState(prevState => {
                  if (item.hotelId == selHotelId && roomSel && rateSel) {
                    var i = oeitems.findIndex(({ extraId }) => extraId == item.id);
                    var price = item.price * 1;
                    var eprice = this.getExtraPrice(item.id, this.state.date1);
                    /*if (item.pricesPeriods != null && item.pricesPeriods.length > 0)
                          eprice = item.pricesPeriods.filter(({ from,to })=>(from<=mdate1 && to>=mdate1))[0].price;*/
                    if (i >= 0) {
                      oeitems[i].count = rCount;
                      oeitems[i].price = eprice * oeitems[i].count;
                    } else oeitems.push({ extraId: item.id, count: rCount, price: eprice });
                  } else {
                    var i = oeitems.findIndex(({ extraId }) => extraId == item.id);
                    if (i >= 0) oeitems.splice(i, 1);
                  }
                }
                //return { };
                //x
                //  });
              }.bind(this),
            );

            return {
              email: ge,
              email2: ge2,
              selPayment: selPayment,
              extrasSel: oeitems,
              items: oitems,
              activeStep: 1,
              selectUnitVisible: false,
              selectUnitUnitId: null,
            };
          },
          () => {
            this.firstName.focus();
          },
        );
      }
    }
    if (action == 'RemoveExtra') {
      this.setState(prevState => {
        const oitems = [...prevState.extrasSel];
        var i = oitems.findIndex(({ extraId }) => extraId == data);
        if (i >= 0) {
          if (oitems[i].count > 1) {
            oitems[i].count--;
            var eitem = this.state.extras.find(({ id }) => id == data);
            /*var eprice = eitem.price, mdate1=getMyDate(this.state.date1);
            if (eitem.pricesPeriods != null && eitem.pricesPeriods.length > 0)
              eprice = eitem.pricesPeriods.filter(({ from,to })=>(from<=mdate1 && to>=mdate1))[0].price;*/
            var eprice = this.getExtraPrice(data, this.state.date1);

            oitems[i].price = eprice * oitems[i].count;
          } else oitems.splice(i, 1);
        }

        return { extrasSel: oitems };
      });
    }
    if (action == 'AddExtra') {
      this.setState(prevState => {
        const oitems = [...prevState.extrasSel];
        var i = oitems.findIndex(({ extraId }) => extraId == data);
        var price = this.state.extras.find(({ id }) => id == data).price * 1;
        var eprice = this.getExtraPrice(data, this.state.date1);

        if (i >= 0) {
          oitems[i].count++;
          var eitem = this.state.extras.find(({ id }) => id == data);
          /*var eprice = eitem.price, mdate1=getMyDate(this.state.date1);
          if (eitem.pricesPeriods != null && eitem.pricesPeriods.length > 0)
            eprice = eitem.pricesPeriods.filter(({ from,to })=>(from<=mdate1 && to>=mdate1))[0].price;*/
          oitems[i].price = eprice * oitems[i].count;
        } else oitems.push({ extraId: data, count: 1, price: eprice });
        console.log(oitems);
        return { extrasSel: oitems };
      });
    }
    if (action == 'Next2') {
      if (this.state.activeStep == 0) {
        var count = this.state.items.length;

        if (count == 0) {
          return;
        }

        //  document.title = this.state.title + ': ' + intl.formatMessage({ id: 'step2' });
        var myTitle = this.state.title + ': ' + intl.formatMessage({ id: 'step2' });
        document.title = myTitle;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'pageview',
          page: {
            title: myTitle,
          },
        });

        this.setState({ activeStep: 1 });
        //     alert(JSON.stringify(this.state.vid));
      }
    }
    if (action == 'Next') {
      if (this.state.activeStep == 0) {
        var count = this.state.items.length;

        if (count == 0) {
          return;
        }

        //  document.title = this.state.title + ': ' + intl.formatMessage({ id: 'step2' });
        var myTitle = this.state.title + ': ' + intl.formatMessage({ id: 'step2' });
        document.title = myTitle;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'pageview',
          page: {
            title: myTitle,
          },
        });

        this.setState({ activeStep: 1 });
        //     alert(JSON.stringify(this.state.vid));
      }
      if (this.state.activeStep == 1) {
        var canAdd = true;
        var message = '';
        var propertyId = 0;
        if (this.state.items.length > 0) propertyId = this.state.items[0].hotelId;
        var onRequest = this.state.properties.find(item => item.id == propertyId).onRequest;
        if (this.state.selPayment == '' && this.state.opMode != 1 && onRequest == 0) {
          message = intl.formatMessage({ id: 'selectPayment' });
          canAdd = false;
        }
        if (this.state.check1 == false && message == '' && onRequest == 0) {
          message = intl.formatMessage({ id: '_CONFIRM_TERMS_CONDITIONS' });
          canAdd = false;
        }
        /*if (this.state.check2 == false && message == '') {
          message = intl.formatMessage({ id: '_CONFIRM_TERMS_CONDITIONS' });
          canAdd = false;
        }*/
        if (this.state.opMode == 0) {
          if ((this.state.firstName == null || this.state.firstName.length < 2) && message == '') {
            message = intl.formatMessage({ id: '_FIRST_NAME_EMPTY_ALERT' });
            this.firstName.focus();
            canAdd = false;
          }
          if ((this.state.lastName == null || this.state.lastName.length < 2) && message == '') {
            message = intl.formatMessage({ id: '_LAST_NAME_EMPTY_ALERT' });
            this.lastName.focus();
            canAdd = false;
          }
          if (
            (this.state.email == null || this.state.email.length < 3 || this.state.email.indexOf('@') < 0) &&
            message == ''
          ) {
            message = intl.formatMessage({ id: '_EMAIL_IS_WRONG' });
            this.email.focus();
            canAdd = false;
          }
          if ((this.state.email2 == null || this.state.email != this.state.email2) && message == '') {
            message = intl.formatMessage({ id: '_EMAIL_IS_WRONG' });
            this.email2.focus();
            canAdd = false;
          }
          /*console.log(propertyId);
          console.log(this.state.properties.find(({ id }) => id == propertyId).beAddress);*/
          if (
            (this.state.address == null || this.state.address.length < 2) &&
            message == '' &&
            this.state.selPayment != 'contract' &&
            this.state.properties.find(({ id }) => id == propertyId).beAddress == 1
          ) {
            message = intl.formatMessage({ id: '_ADDRESS_EMPTY_ALERT' });
            this.address.focus();
            canAdd = false;
          }
          if (
            (this.state.city == null || this.state.city.length < 2) &&
            message == '' &&
            this.state.selPayment != 'contract' &&
            this.state.properties.find(({ id }) => id == propertyId).beCity == 1
          ) {
            message = intl.formatMessage({ id: '_CITY_EMPTY_ALERT' });
            this.city.focus();
            canAdd = false;
          }
          if (
            (this.state.postalCode == null || this.state.postalCode.length < 2) &&
            message == '' &&
            this.state.selPayment != 'contract' &&
            this.state.properties.find(({ id }) => id == propertyId).beZip == 1
          ) {
            message = intl.formatMessage({ id: '_ZIPCODE_EMPTY_ALERT' });
            this.postalCode.focus();
            canAdd = false;
          }
          if (
            (this.state.country == null || this.state.country.length < 2) &&
            message == '' &&
            this.state.selPayment != 'contract' &&
            this.state.properties.find(({ id }) => id == propertyId).beCountry == 1
          ) {
            message = intl.formatMessage({ id: '_COUNTRY_EMPTY_ALERT' });
            this.country.focus();
            canAdd = false;
          }
          if (
            this.state.birthDate == null &&
            message == '' &&
            this.state.properties.find(({ id }) => id == propertyId).beBirth == 1
          ) {
            message = intl.formatMessage({ id: '_BIRTH_DATE_VALID_ALERT' });
            //          alert(JSON.stringify(this.birthDate));
            //  this.birthDate.focus();
            canAdd = false;
          }
          if (
            (this.state.phone == null || this.state.phone.length < 6) &&
            message == '' &&
            this.state.selPayment != 'contract'
          ) {
            message = intl.formatMessage({ id: '_PHONE_EMPTY_ALERT' });
            this.phone.focus();
            canAdd = false;
          }

          if (this.state.selPayment == 'online') {
            if ((this.state.creditCard == null || this.state.creditCard.length < 12) && message == '') {
              message = intl.formatMessage({ id: '_CC_CARD_INVALID_NUMBER' });
              this.creditCard.focus();
              canAdd = false;
            }
            if ((this.state.creditCardHolder == null || this.state.creditCardHolder.length < 3) && message == '') {
              message = intl.formatMessage({ id: '_CC_CARD_HOLDER_NAME_EMPTY' });
              this.creditCardHolder.focus();
              canAdd = false;
            }
            /*if (
              (this.state.creditCardExpireMonth == null ||
                !(this.state.creditCardExpireMonth.length == 1 || this.state.creditCardExpireMonth.length == 2)) &&
              message == ''
            ) {
              message = intl.formatMessage({ id: '_CC_CARD_WRONG_EXPIRE_DATE' });
              this.creditCardExpireMonth.focus();
              canAdd = false;
            }
            if (
              (this.state.creditCardExpireYear == null ||
                !(this.state.creditCardExpireYear.length == 2 || this.state.creditCardExpireYear.length == 4)) &&
              message == ''
            ) {
              this.creditCardExpireYear.focus();
              message = intl.formatMessage({ id: '_CC_CARD_WRONG_EXPIRE_DATE' });
              canAdd = false;
            }*/
            var ccinfo = this.state.payments.find(({ id }) => id == 'online').paymentInfo2;
            if (ccinfo.cc6 == 1)
              if (
                (this.state.creditCardCVV == null ||
                  !(this.state.creditCardCVV.length == 3 || this.state.creditCardCVV.length == 4)) &&
                message == ''
              ) {
                message = intl.formatMessage({ id: '_CC_CARD_NO_CVV_NUMBER' });
                this.creditCardCVV.focus();
                canAdd = false;
              }
          }
        }
        if (message != '') {
          Swal.fire({
            title: intl.formatMessage({ id: '_MESSAGE' }),
            text: message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: intl.formatMessage({ id: 'ok' }),
          }).then(result => {
            //this.doRefresh();
          });
        }

        if (canAdd == false) return;
        var totalTax = 0;
        var totalExtras = 0;
        var totalRoom = 0;
        var total = 0,
          nights = this.state.nights,
          persons = 0,
          units = 0;
        var lD = 0;

        this.state.items.forEach(function(item) {
          totalRoom += item.priceFinal * item.count;
          if (item.loyaltyDiscount != null && item.loyaltyDiscount != 0) {
            var lD2 = (totalRoom * item.loyaltyDiscount) / 100;
            //  totalRoom -= lD2;
            lD += lD2;
          }

          totalTax += item.taxes * item.count;
          units += item.count;
          persons += item.count * (item.adults + item.children);
        });
        this.state.extrasSel.forEach(
          function(item) {
            //var price = this.state.extras.find(({ id }) => id == item.extraId).price;
            var eitem = this.state.extras.find(({ id }) => id == item.extraId);
            /*    var eprice = eitem.price, mdate1=getMyDate(this.state.date1);
            if (eitem.pricesPeriods != null && eitem.pricesPeriods.length > 0)
              eprice = eitem.pricesPeriods.filter(({ from,to })=>(from<=mdate1 && to>=mdate1))[0].price;*/
            var eprice = this.getExtraPrice(item.extraId, this.state.date1);
            var unitsx = units;
            if (Array.isArray(eitem.rooms) && eitem.rooms.length > 0) {
              unitsx = 0;
              this.state.items.map(function(x) {
                if (eitem.rooms.includes(x.roomId)) unitsx += x.count;
              });
            }

            var model = this.state.extras.find(({ id }) => id == item.extraId).model;
            if (model == 0) totalExtras += eprice * item.count;
            if (model == 1) totalExtras += eprice * item.count * nights;
            if (model == 2) totalExtras += eprice * item.count * nights * persons;
            if (model == 3) totalExtras += eprice * item.count * persons;
            if (model == 4) totalExtras += eprice * item.count * unitsx;
          }.bind(this),
        );

        total = totalRoom + totalTax + totalExtras;
        var items = [],
          eitems = [];
        console.log(this.state.date1);
        console.log(this.state.date2);
        var myDate2 = getMyDate(this.state.date2);
        if (this.state.disableCheckOut == 1) myDate2 = getMyDate(this.state.date1);
        this.state.items.forEach(
          function(item) {
            if (this.state.bookingMode == 'wellness') {
              var myitem = {
                datetime: item.datetime,
                hotelId: item.hotelId,
                treatmentId: item.treatmentId,
                price: item.priceFinal * this.state.currencyRatio,
                personId: item.personId,
              };
              var myitem2 = {
                /*                fromDate: getMyDate(this.state.date1),
                toDate: myDate2,
                adults: this.state.adults,
                children: this.state.children,
                item_id: item.roomId,
                item_name: this.getRoom(item.roomId),
                quantity: item.count,
                price: item.priceFinal * this.state.currencyRatio,
                discount: (item.priceOriginal - item.priceFinal) * this.state.currencyRatio,
                coupon: this.state.coupon,
                extraBedCharge: 0,
                extraBeds: 0,
                taxPrice: item.taxes * this.state.currencyRatio,
                policy: item.policyId,*/
              };
            }
            if (this.state.bookingMode == 'room') {
              var myitem = {
                fromDate: getMyDate(this.state.date1),
                toDate: myDate2,
                adults: item.adults,
                children: item.children,
                childAge: item.childAge,
                hotelId: item.hotelId,
                roomId: item.roomId,
                unitId: item.unitId,
                roomCount: item.count,
                customPolicyId: item.customPolicyId,
                room_info: item.room_info,
                code: item.code,
                price: item.priceFinal * this.state.currencyRatio,
                mealPrice: item.mealPrice * this.state.currencyRatio,
                extraBedCharge: 0,
                extraBeds: 0,
                taxPrice: item.taxes * this.state.currencyRatio,
                policy: item.policyId,
              };
              var myitem2 = {
                fromDate: getMyDate(this.state.date1),
                toDate: myDate2,
                adults: item.adults,
                children: item.children,
                childAge: item.childAge,
                item_id: item.roomId,
                item_name: this.getRoom(item.roomId),
                quantity: item.count,
                customPolicyId: item.customPolicyId,
                room_info: item.room_info,
                price: item.priceFinal * this.state.currencyRatio,
                discount: (item.priceOriginal - item.priceFinal) * this.state.currencyRatio,
                coupon: this.state.coupon,
                extraBedCharge: 0,
                extraBeds: 0,
                taxPrice: item.taxes * this.state.currencyRatio,
                policy: item.policyId,
              };
              if (item.serviceId != null) myitem.serviceId = item.serviceId;
              if (item.rateId != null) myitem.rateId = item.rateId;
              if (item.packageId != null) myitem.packageId = item.packageId;
            }
            items.push(myitem);
            eitems.push(myitem2);
          }.bind(this),
        );
        total = total * this.state.currencyRatio;
        totalTax = totalTax * this.state.currencyRatio;
        totalExtras = totalExtras * this.state.currencyRatio;
        var newsletterCheck = 0;
        if (this.state.check2) newsletterCheck = 1;
        var birthDate = '0000-00-00';
        if (this.state.birthDate != null) birthDate = getMyDate(this.state.birthDate);
        var dataToSend2 = {
          bookingId: this.state.bookingId,
          control: this.props.match.params.control,
        };
        var dataToSend = {
          currency: this.state.selCurrency,
          locale: locale,
          totalPrice: total,
          totalTax: totalTax,
          totalExtras: totalExtras,
          comment: this.state.guestComment,
          source: this.state.source,
          newsletterCheck: newsletterCheck,
          coupon: this.state.coupon,
          guestInfo: {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
            address: this.state.address,
            city: this.state.city,
            postalCode: this.state.postalCode,
            country: this.state.country,
            birthDate: birthDate,
          },
          payment: this.state.selPayment,
          CCType: this.state.creditCardType,
          CCNumber: this.state.creditCard,
          CCHolder: this.state.creditCardHolder,
          CCExpireMonth: this.state.creditCardExpireMonth,
          CCExpireYear: this.state.creditCardExpireYear,
          CCCVV: this.state.creditCardCVV,

          extras: this.state.extrasSel,
          items: items,
          bookingId: this.state.bookingId,
        };
        const params2 = queryString.parse(window.location.search.substring(1));
        if (params2 != null && params2.amount != null) {
          dataToSend.customAmount = params2.amount;
        }

        /*alert(JSON.stringify(dataToSend));
        return;*/
        var senddata = {};
        if (this.state.opMode == 0) {
          senddata = {
            Action: 'newReservation',
            Data: dataToSend,
          };
        }
        if (this.state.opMode == 2) {
          dataToSend.amount = this.state.paymentAmount;
          senddata = {
            Action: 'requestPayment',
            Data: dataToSend,
          };
        }
        if (this.state.opMode == 1) {
          senddata = {
            Action: 'cancelReservation',
            Data: dataToSend2,
          };
        }
        //    return;
        this.setState({ loading: true });

        axios.post(`https://secure.webbookingpro.com/bapi/booking.php`, senddata).then(res => {
          //console.log(res.data);
          if (res.data != null && res.data.result != null) {
            this.setState({ loading: false });
            if (res.data.result == 'true') {
              var myTitle = '';
              //if (res.data.data.properties.length > 0) myTitle = res.data.data.properties[0].title['en'];
              /*else*/ myTitle = 'WebBookingPro';

              this.setState({
                errorCode: 0,
                bookingNumber: res.data.bookingNumber,
                activeStep: 2,
                hotelEmail: res.data.contactEmail,
              });

              if (res.data.paymentUrl != null) {
                if (res.data.paymentUrlIFrame === 1) this.setState({ showPayment: true, paymentUrl: res.data.paymentUrl });
                else window.location = res.data.paymentUrl;
              } else {
                var myTitle2 = myTitle + ': ' + intl.formatMessage({ id: 'step3' });
                document.title = myTitle2;
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: 'pageview',
                  page: {
                    title: myTitle2,
                  },
                });
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: 'purchase',
                  transaction_id: res.data.bookingNumber,
                  affiliation: '',
                  value: total,
                  tax: totalTax,
                  extras: totalExtras,
                  coupon: this.state.coupon,
                  currency: this.state.selCurrency,
                  items: eitems,
                });

                if (res.data.url != null) {
                  window.location = res.data.url;
                }
              }
            } else {
              this.setState({ errorCode: res.data.errorCode });
            }
            //this.setState({ loadingResults: false, results: res.data.data });
          }
        });

        //this.setState({ activeStep: 2 });
        //        this.setState({ activeStep: 1 });
      }
    }
    if (action == 'Back') {
      if (this.state.activeStep == 1) {
        document.title = this.state.title + ': ' + intl.formatMessage({ id: 'step1' });

        this.setState({ activeStep: 0 });
      }
      if (this.state.activeStep == 2) {
        this.setState({ activeStep: 1 });
      }
    }
  }

  showPrice(price, useDiscount, duration) {
    var factor = 1;
    var discount = '';
    // alert(price + ' ' + useDiscount + ' ' + duration);

    var ratio = this.state.currencies.filter(
      function(item) {
        return item.code == this.state.selCurrency;
      }.bind(this),
    )[0].ratio;
    if (useDiscount) factor = factor * duration;
    var currencyCode = this.state.currencies.filter(
      function(item) {
        return item.code == this.state.selCurrency;
      }.bind(this),
    )[0].symbol;
    if (price == null) return '0,00 ' + currencyCode;
    var priceint = parseFloat(price * ratio * factor);
    /*    if (lang == 'en') {
      return price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }*/
    //if (lang == 'hr') {
    // this.setState({ discount: discount, factor: factor });
    return priceint.toLocaleString('hr-HR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' ' + currencyCode;
    //}
  }

  handleSliderChange(value) {
    var factor = 1;
    var discount = '';

    this.setState({ duration: value, factor: factor, discount: discount });
  }

  render() {
    const { classes } = this.props;
    const { intl } = this.props;

    const hotelName = this.props.auth.authUser.name;
    var i = 1,
      j = 0,
      k = 0;
    const locale = this.context.locale.locale;
    var sliderWidth = '100%',
      sliderHeight = window.innerHeight - 100;
    sliderHeight += 'px';

    var userGrid = 12;
    if (this.state.general.columns != null) userGrid = userGrid / this.state.general.columns;

    var totalTax = 0,
      totalAdults = 0,
      index = 0,
      totalChildren = 0;
    var totalExtras = 0;
    var totalRoom = 0;
    var total = 0,
      amountToPay = 0;
    var minRange = 2,
      maxRange = 62;

    const today = myFormatDate(moment());
    const today2 = moment();
    var myLocale = localeEn;
    if (locale == 'hr') myLocale = localeHr;
    if (locale == 'sr') myLocale = localeSr;
    if (locale == 'me') myLocale = localeSr;
    if (locale == 'mk') myLocale = localeEn;

    if (locale == 'de') myLocale = localeDe;

    if (locale == 'it') myLocale = localeIt;
    if (locale == 'sl') myLocale = localeEn;
    if (locale == 'ba') myLocale = localeHr;

    var onRequest = 0;

    var allowedIds = JSON.parse(JSON.stringify(this.getAllowedIds()));

    const maxEnd = myFormatDate(moment().add(2, 'year'));
    var currentYear = new Date().getFullYear();
    var years = [];
    for (let i = currentYear; i < currentYear + 10; i++) years.push(i);
    var xx = 0;
    var addLegal = [];
    var prePaymentNow = 0,
      prePaymentLater = 0,
      prePaymentDue = '';
    var roomCount = 0,
      lD = 0;
    this.state.items.forEach(
      function(item) {
        totalRoom += item.priceFinal * item.count;
        if (item.loyaltyDiscount != null && item.loyaltyDiscount != 0) {
          var lD2 = (totalRoom * item.loyaltyDiscount) / 100;
          //      totalRoom -= lD2;
          lD += lD2;
        }

        totalTax += item.taxes * item.count;
        totalAdults += item.count * item.adults;
        totalChildren += item.count * item.children;
        roomCount += item.count;
        xx++;
        if (xx == 1) {
          if (item.freeCancel != '' && this.state.bookingMode != 'wellness')
            addLegal.push(
              <li>
                {intl.formatMessage({ id: 'freeCancellation' }) +
                  this.getCancelInfo2(item.freeCancel, this.getTime(item.roomId, false))}
              </li>,
            );
          if (item.freeCancel == '' && item.partialCancel == '')
            addLegal.push(<li>{intl.formatMessage({ id: 'noFreeCancel' })}</li>);
          if (item.partialCancel != '' && this.state.bookingMode != 'wellness')
            addLegal.push(
              <li>
                {intl.formatMessage({ id: 'partialCancellation' }) +
                  this.getCancelInfo2(item.partialCancel, this.getTime(item.roomId, false))}
              </li>,
            );
          if (item.prePayment == 0) addLegal.push(<li>{intl.formatMessage({ id: 'noPrepayment' })}</li>);
          if (item.prePayment > 0 && item.prePaymentDue == '') prePaymentNow += item.prePayment;

          if (item.prePayment > 0 && item.prePaymentDue != '') {
            prePaymentLater += item.prePayment;
            prePaymentDue = item.prePaymentDue;
          }
        }
      }.bind(this),
    );
    var isWBPOffer = 0;
    const params = queryString.parse(window.location.search.substring(1));
    if (params.addCode != null && params.addCode != '') isWBPOffer = 1;

    if (
      this.state.selPayment == 'wspay' ||
      this.state.selPayment == 'stripe' ||
      this.state.selPayment == 'cpay' ||
      this.state.selPayment == 'teya' ||
      this.state.selPayment == 'monri'
    ) {
      if (prePaymentNow > 0)
        addLegal.push(
          <li>
            {intl.formatMessage({ id: 'pay' }) +
              ' ' +
              formatPrice(prePaymentNow, locale) +
              ' ' +
              this.state.currencyCode +
              ' ' +
              intl.formatMessage({ id: 'now' })}
          </li>,
        );
      if (prePaymentLater > 0)
        addLegal.push(
          <li>
            {intl.formatMessage({ id: 'pay' }) +
              ' ' +
              formatPrice(prePaymentLater, locale) +
              ' ' +
              this.state.currencyCode +
              ' ' +
              intl.formatMessage({ id: 'until' }) +
              ' ' +
              getMyDate2(prePaymentDue, locale)}
          </li>,
        );
    }
    addLegal.push(<br />);
    //    addLegal.push(<br />);

    var persons = totalAdults + totalChildren;
    var nights = this.state.nights;
    this.state.extrasSel.forEach(
      function(item) {
        var eitem = this.state.extras.find(({ id }) => id == item.extraId);
        /*var eprice = eitem.price, mdate1=getMyDate(this.state.date1);
        if (eitem.pricesPeriods != null && eitem.pricesPeriods.length > 0)
          eprice = eitem.pricesPeriods.filter(({ from,to })=>(from<=mdate1 && to>=mdate1))[0].price;*/
        var eprice = this.getExtraPrice(item.extraId, this.state.date1);
        var unitsx = roomCount;
        if (Array.isArray(eitem.rooms) && eitem.rooms.length > 0) {
          unitsx = 0;
          this.state.items.map(function(x) {
            if (eitem.rooms.includes(x.roomId)) unitsx += x.count;
          });
        }
        //var price = this.state.extras.find(({ id }) => id == item.extraId).price;
        var model = this.state.extras.find(({ id }) => id == item.extraId).model;
        if (model == 0) totalExtras += eprice * item.count;
        if (model == 1) totalExtras += eprice * item.count * nights;
        if (model == 2) totalExtras += eprice * item.count * nights * persons;
        if (model == 3) totalExtras += eprice * item.count * persons;
        if (model == 4) totalExtras += eprice * item.count * unitsx;
      }.bind(this),
    );

    total = totalRoom + totalTax + totalExtras;
    /*var lD=0;
    if (this.state.loyaltyDiscount!=0)
    {
      lD=total*this.state.loyaltyDiscount/100;
      total-=lD;
    }*/
    var disableBackdrop = this.state.disableBackdrop;
    if (this.state.opMode == 2 && this.state.paymentAmount > 0) {
      amountToPay = this.state.paymentAmount / 100;
    }
    var carouselHeight = '24rem';

    var showLogo = this.state.general.showLogo;
    var showRight = this.state.items.length > 0 || this.state.general.showSummary == 1;

    var showFooter = this.state.general.showFooter;
    var maxRates = this.state.general.maxRates;
    var showWBP = this.state.general.showWBP;
    var showLanguage = this.state.general.showLanguage;

    var mystyle = {};
    if (this.state.properties.length > 0 && this.state.properties[0].backgroundImage != '')
      mystyle = {
        background: 'url(' + this.state.properties[0].backgroundImage + ') no-repeat center center fixed',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        WebkitBackgroundSize: 'cover',
      };

    var bankinfo = '';
    //const params = queryString.parse(window.location.search.substring(1));
    if (params != null && params.paymentid != null && params.paymentid == 'bank.transfer') {
      bankinfo = this.state.bankinfo;
    }

    var propertyType = 1;
    if (this.state.properties.length > 0) propertyType = this.state.properties[0].propertyType;
    if (propertyType == null) propertyType = 1;
    //
    //mystyle={ back ground: url('https://secure.webbookingpro.com/images/hotels/'+this.state.properties[0].backgroundImage) no-repeat center center fixed;  background-size: cover; background-position: center; background-repeat: no-repeat;     background-attachment: fixed; -webkit-background-size: cover;
    //-moz-background-size: cover; -o-background-size: cover;}</style>";
    var cc1 = 1,
      cc2 = 1,
      cc3 = 1,
      cc4 = 1,
      cc5 = 1,
      cc6 = 0,
      cc7 = 0,
      cc8 = 0,
      cc9 = 0,
      cvc = 1;
    /* {
      addLegal = [];
    }*/
    if (
      this.state.selPayment == 'online' ||
      this.state.selPayment == 'wspay' ||
      this.state.selPayment == 'monri' ||
      this.state.selPayment == 'teya' ||
      this.state.selPayment == 'stripe'
    ) {
      var ccinfo = this.state.payments.find(({ id }) => id == this.state.selPayment).paymentInfo2;
      cc1 = ccinfo.cc1;
      cc2 = ccinfo.cc2;
      cc3 = ccinfo.cc3;
      cc4 = ccinfo.cc4;
      cc5 = ccinfo.cc5;
      cc7 = ccinfo.cc7;
      cc8 = ccinfo.cc8;
      cc9 = ccinfo.cc9;
      cvc = ccinfo.cc6;
    }
    var orientation = HORIZONTAL_ORIENTATION;
    var userPackage = this.state.userPackage;
    var userRateId = this.state.userRateId;
    var userDisableCheckOut = this.state.disableCheckOut;
    if (this.state.bookingMode == 'wellness') userDisableCheckOut = 1;
    var isMobile = false;
    if (window.innerWidth < 768) {
      orientation = VERTICAL_ORIENTATION;
      isMobile = true;
      sliderWidth = '850px';
      sliderHeight = '300px';

      carouselHeight = '12rem';
    }
    if (userPackage != '' && this.state.minDays > 0) {
      minRange = this.state.minDays + 1;
      maxRange = this.state.minDays + 1;
    }
    if (userRateId != '' && this.state.minDays > 0) {
      minRange = this.state.minDays + 1;
      maxRange = this.state.minDays + 365;
    }
    var propertyId = 0;
    if (this.state.items.length > 0) {
      propertyId = this.state.items[0].hotelId;
      onRequest = this.state.properties.find(({ id }) => id == propertyId).onRequest;
    }

    var ucurrencies = [];
    this.state.currencies.map(function(item) {
      if (ucurrencies.filter(itemx => itemx.code == item.code).length == 0) ucurrencies.push(item);
    });

    //console.log(minRange, maxRange);

    return (
      <>
        <Grid container style={mystyle}>
          <Grid item xs={12}>
            {this.state.maintanance == 1 && (
              <CmtCard className={classes.card}>
                <CmtCardContent className={classes.cardContent}>
                  <Box>
                    <MyAlert severity="warning">{this.state.maintananceMessage}</MyAlert>
                  </Box>
                </CmtCardContent>
              </CmtCard>
            )}
            {this.state.properties.length == 0 /*|| (Array.isArray(this.state.rooms) && this.state.rooms.length == 0)*/ &&
              this.state.loading == false && (
                <CmtCard className={classes.card}>
                  <CmtCardContent className={classes.cardContent}>
                    <Box>
                      <MyAlert severity="warning">_OWNER_NOT_ASSIGNED_TO_HOTEL</MyAlert>
                    </Box>
                  </CmtCardContent>
                </CmtCard>
              )}

            {showLogo == 1 && this.state.properties.length > 0 && (
              <CmtCard className={classes.card}>
                <CmtCardContent className={classes.cardContent2}>
                  <GridContainer>
                    <Grid
                      item
                      xl={6}
                      sm={6}
                      xs={12}
                      style={{ margin: 'auto', width: '50%', textAlign: isMobile == 1 ? 'center' : 'right' }}>
                      <>
                        <a href={this.state.properties[0].website}>
                          <img src={this.state.properties[0].logoImage} className={classes.logoImage} />
                        </a>
                      </>
                    </Grid>
                    <Grid
                      item
                      xl={6}
                      sm={6}
                      xs={12}
                      style={{ margin: 'auto', width: '50%', textAlign: isMobile == 1 ? 'center' : 'left' }}>
                      <Typography component="label" className={classes.title + ' wbpTitle ' + classes.titleSize2}>
                        {this.state.properties[0].title[locale]}
                      </Typography>
                      <br />
                      {this.state.properties[0].phone != '' && (
                        <>
                          <Home size="small" />
                          <Typography component="label" className={classes.title2 + ' wbpTitle2 ' + classes.titleSize}>
                            {this.state.properties[0].address}
                            {this.state.properties[0].address != '' && ', ' + this.state.properties[0].city}
                            {this.state.properties[0].city != '' && ', ' + this.state.properties[0].country}
                          </Typography>
                          <br />
                        </>
                      )}
                      <br />
                      {this.state.properties[0].phone != '' && (
                        <>
                          <Phone size="small" />
                          <a href={'tel://' + this.state.properties[0].phone}>
                            <Typography component="label" className={classes.titlex2 + ' wbpTitle2 ' + classes.titleSize2}>
                              {this.state.properties[0].phone}
                            </Typography>
                          </a>

                          <br />
                        </>
                      )}
                      {this.state.properties[0].email != '' && (
                        <>
                          <Mail />
                          <a href={'mailto://' + this.state.properties[0].email}>
                            <Typography component="label" className={classes.titlex2}>
                              {this.state.properties[0].email}
                            </Typography>
                          </a>
                        </>
                      )}
                    </Grid>
                  </GridContainer>
                </CmtCardContent>
              </CmtCard>
            )}
          </Grid>
          <Grid item xs={12} xl={showRight == 1 ? 8 : 12} sm={showRight == 1 ? 8 : 12}>
            {this.state.activeStep != 2 &&
            this.state.loading == false &&
            this.state.bookingId == null &&
            this.state.properties.length >
              0 /*&&
              Array.isArray(this.state.rooms) &&
              this.state.rooms.length > 0 */ && (
                <CmtCard className={classes.card}>
                  <CmtCardContent className={classes.cardContent}>
                    <Box>
                      <Stepper activeStep={this.state.activeStep} alternativeLabel>
                        <Step key={0} onClick={() => (isWBPOffer == 0 ? this.handleSubmit('Back') : null)}>
                          <StepLabel>
                            <Typography component="label" className={classes.titleSize15}>
                              {this.state.bookingMode == 'room' && <IntlMessages id="step1" />}
                              {this.state.bookingMode == 'wellness' && <IntlMessages id="step1w" />}
                            </Typography>
                            {this.state.properties.length > 1 && this.state.currentPiD != 0 && (
                              <>
                                <br />
                                <Button
                                  onClick={() => this.handleSubmit('hideProperty')}
                                  variant="contained"
                                  color="primary"
                                  size="large">
                                  <IntlMessages id="hideProperty" />
                                </Button>
                              </>
                            )}
                          </StepLabel>
                        </Step>
                        <Step key={1} onClick={() => this.handleSubmit('Next2')}>
                          <StepLabel>
                            <Typography component="label" className={classes.titleSize15}>
                              <IntlMessages id="step2" />
                            </Typography>
                          </StepLabel>
                        </Step>
                      </Stepper>
                    </Box>

                    <Box>
                      <Grid container alignContent="flex-end" justifyContent="flex-end">
                        <Grid
                          container
                          xs={2}
                          sm={2}
                          xl={2}
                          direction="row"
                          alignItems="center"
                          alignContent="flex-start"
                          justifyContent="flex-start">
                          {this.state.activeStep == 1 && (
                            <IconButton size="small" onClick={() => this.handleSubmit('Back')}>
                              <ArrowBack />
                            </IconButton>
                          )}
                        </Grid>
                        <Grid
                          container
                          xs={10}
                          sm={10}
                          xl={10}
                          direction="row"
                          alignItems="center"
                          alignContent="flex-end"
                          justifyContent="flex-end">
                          {this.state.properties.length > 1 && this.state.currentPiD == 0 && (
                            <Box
                              className={classes.cardContentInner2}
                              onClick={() =>
                                this.setState({
                                  filterDialog: true,
                                })
                              }>
                              <FilterList />
                            </Box>
                          )}
                          {this.state.currencies.length > 1 && (
                            <TextField
                              id="priceModel"
                              name="priceModel"
                              select
                              variant="outlined"
                              size="small"
                              margin="small"
                              className={classes.currency}
                              value={this.state.selCurrency}
                              onChange={event => this.handleChange('selCurrency', event.target.value)}>
                              {ucurrencies.map((item, index) => (
                                <MenuItem value={item.code}>{item.title}</MenuItem>
                              ))}
                            </TextField>
                          )}

                          {showLanguage == 1 && <LanguageSwitcher languageData={this.state.languageData} />}
                        </Grid>
                      </Grid>
                    </Box>
                  </CmtCardContent>
                </CmtCard>
              )}

            {this.state.activeStep == 0 && this.state.bookingId == null && (
              <>
                {this.state.properties.length > 0 &&
                  Array.isArray(this.state.rooms) &&
                  this.state.rooms.length > 0 &&
                  this.state.disableSearch == 0 && (
                    <CmtCard className={classes.card + ' wbpSearchBox'}>
                      <CmtCardContent className={classes.cardContent}>
                        {this.state.availableBookingModes.length > 0 && (
                          <ToggleButtonGroup
                            color="primary"
                            value={this.state.bookingMode}
                            exclusive
                            onChange={(e, v) => this.handleChange('bookingMode', v)}>
                            <ToggleButton value="room">
                              <IntlMessages id="bookingMode1" />
                            </ToggleButton>
                            <ToggleButton value="wellness">
                              <IntlMessages id="bookingMode2" />
                            </ToggleButton>
                          </ToggleButtonGroup>
                        )}
                        {this.state.bookingMode == 'room' && (
                          <GridContainer>
                            <Grid xl={4} sm={4} xs={12} item>
                              {this.state.disableGuests == 0 && (
                                <>
                                  <Box
                                    className={classes.cardContentInner}
                                    onClick={() =>
                                      this.setState({
                                        adultsx: this.state.adults,
                                        childrenx: this.state.children,
                                        childAgex: JSON.parse(JSON.stringify(this.state.childAge)),
                                        personDialog: true,
                                      })
                                    }>
                                    <Typography
                                      component="label"
                                      className={
                                        classes.title + ' wbpSearchTitle ' + classes.rateReserve + ' ' + classes.titleSize25
                                      }>
                                      <IntlMessages id="_GUESTS" />
                                    </Typography>
                                  </Box>
                                  <Box
                                    className={classes.cardContentInner2}
                                    onClick={() =>
                                      this.setState({
                                        adultsx: this.state.adults,
                                        childrenx: this.state.children,
                                        childAgex: JSON.parse(JSON.stringify(this.state.childAge)),
                                        personDialog: true,
                                      })
                                    }>
                                    <Typography
                                      component="label"
                                      className={
                                        classes.title2 +
                                        ' wbpSearchTitle2 ' +
                                        classes.rateReserve +
                                        ' ' +
                                        classes.titleSize15
                                      }>
                                      {this.state.adults}{' '}
                                      {this.state.adults == 1 ? <IntlMessages id="_ADULT" /> : <IntlMessages id="_ADULTS" />}
                                      {this.state.children > 0 && (
                                        <>
                                          , {this.state.children}{' '}
                                          {this.state.adults == 1 ? (
                                            <IntlMessages id="_CHILD" />
                                          ) : (
                                            <IntlMessages id="_CHILDREN" />
                                          )}
                                        </>
                                      )}{' '}
                                      <EditIcon />
                                    </Typography>
                                  </Box>
                                </>
                              )}
                            </Grid>
                            <Grid item xl={4} sm={4} xs={6}>
                              <Box className={classes.cardContentInner}>
                                <Typography
                                  component="label"
                                  className={
                                    classes.title + ' wbpSearchTitle ' + classes.rateReserve + ' ' + classes.titleSize25
                                  }>
                                  <IntlMessages id="_CHECK_IN" />
                                </Typography>
                              </Box>
                              <Box className={classes.cardContentInner2} onClick={() => this.toggleView(3)}>
                                <Typography
                                  component="label"
                                  className={
                                    classes.title2 + ' wbpSearchTitle2 ' + classes.rateReserve + ' ' + classes.titleSize15
                                  }>
                                  {getMyDate2(this.state.date1, locale)}
                                  <EditIcon />
                                </Typography>
                                {/*<DatePicker
                              variant="inline"
                              renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) =>
                                this.getDayElement(day, selectedDate, isInCurrentMonth, dayComponent)
                              }
                              format="DD/MM/yyyy"
                              margin="small
                              loading={this.state.loadingCalendar}
                              id="date1"
                              name="date1"
                              inputProps={{ className: classes.wbpInput }}
                              onMonthChange={day => this.handleMonthChange(day)}
                              renderLoading={() => <Skeleton animation="wave" />}
                              autoOk="true"
                              disablePast="true"
                              value={this.state.date1}
                              onChange={date => {
                                this.setState({ date1: date, items: [] }, () => {
                                  this.checkDates(true);
                                  //this.loadResults();
                                });
                              }}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />*/}
                              </Box>
                            </Grid>
                            <Grid item xl={4} sm={4} xs={6}>
                              {this.state.disableCheckOut == 0 && (
                                <>
                                  <Box className={classes.cardContentInner}>
                                    <Typography
                                      component="label"
                                      className={
                                        classes.title + ' wbpSearchTitle ' + classes.rateReserve + ' ' + classes.titleSize25
                                      }>
                                      <IntlMessages id="_CHECK_OUT" />
                                    </Typography>
                                  </Box>
                                  <Box className={classes.cardContentInner2} onClick={() => this.toggleView(3)}>
                                    <Typography
                                      component="label"
                                      className={
                                        classes.title2 +
                                        ' wbpSearchTitle2 ' +
                                        classes.rateReserve +
                                        ' ' +
                                        classes.titleSize15
                                      }>
                                      {getMyDate2(this.state.date2, locale)}
                                      <EditIcon />
                                    </Typography>
                                    {/*       <DatePicker
                              variant="inline"
                              format="DD/MM/yyyy"
                              renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) =>
                                this.getDayElement(day, selectedDate, isInCurrentMonth, dayComponent)
                              }
                              margin="small"
                              id="date2"
                              loading={this.state.loadingCalendar}
                              renderLoading={() => <Skeleton animation="wave" />}
                              name="date2"
                              onMonthChange={day => this.handleMonthChange(day)}
                              autoOk="true"
                              disablePast="true"
                              inputProps={{ className: classes.wbpInput }}
                              value={this.state.date2}
                              onChange={date => {
                                this.setState({ date2: date, items: [] }, () => {
                                  this.checkDates(false);
                                  this.loadResults();
                                });
                              }}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />*/}
                                  </Box>
                                </>
                              )}
                            </Grid>
                            {this.state.selectedView == 3 && isMobile == false && (
                              <Grid item xl={12} sm={12} xs={12}>
                                <div ref={el => (this.datesForm = el)}></div>
                                {this.state.disableCheckOut == 1 && (
                                  <DatePicker
                                    variant="inline"
                                    renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) =>
                                      this.getDayElement(day, selectedDate, isInCurrentMonth, dayComponent)
                                    }
                                    format="DD/MM/yyyy"
                                    margin="small"
                                    loading={this.state.loadingCalendar}
                                    id="date1"
                                    name="date1"
                                    inputProps={{ className: classes.wbpInput }}
                                    onMonthChange={day => this.handleMonthChange(day)}
                                    renderLoading={() => <Skeleton animation="wave" />}
                                    autoOk="true"
                                    disablePast="true"
                                    value={this.state.date1}
                                    onChange={date => {
                                      this.setState(
                                        {
                                          date1: moment(date).format('YYYY-MM-DD'),
                                          date2: moment(date)
                                            .add(0, 'days')
                                            .format('YYYY-MM-DD'),
                                          items: [],
                                        },
                                        () => {
                                          this.checkDates(true);
                                          //this.loadResults();
                                        },
                                      );
                                    }}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                  />
                                )}
                                {this.state.disableCheckOut == 0 && (
                                  <Datepicker
                                    cssClass="md-book-rental"
                                    controls={['calendar']}
                                    select="range"
                                    touchUi={true}
                                    display="inline"
                                    calendarType="month"
                                    calendarSize={2}
                                    min={today}
                                    minRange={minRange}
                                    maxRange={maxRange}
                                    locale={myLocale}
                                    onPageChange={ev => {
                                      this.updateAvailability(myFormatDate(ev.firstDay), false);
                                    }}
                                    max={maxEnd}
                                    value={this.state.selectedDate}
                                    onChange={ev => {
                                      //console.log(ev.value);
                                      if (ev.value[0] != null && ev.value[1] != null) {
                                        var startDate = ev.value[0];
                                        var run = true;
                                        var endDate = ev.value[1];
                                        var endDate2 = moment(endDate).format('YYYY-MM-DD');
                                        /*if (userPackage != '')
                                        endDate2 = moment(startDate)
                                          .add(this.state.minDays, 'days')
                                          .format('YYYY-MM-DD');
                                      */ if (
                                          userDisableCheckOut == 1 /*|| endDate == null*/
                                        )
                                          endDate2 = moment(startDate)
                                            .add(1, 'days')
                                            .format('YYYY-MM-DD');

                                        if (endDate2 == moment(startDate).format('YYYY-MM-DD')) {
                                          endDate2 = moment(startDate)
                                            .add(1, 'days')
                                            .format('YYYY-MM-DD');
                                          // run = false;

                                          /* if (this.state.available.find(({ d }) => d == endDate3) != null)
                                          if (this.state.available.find(({ d }) => d == endDate3).a == 0) endDate2 = endDate3;*/
                                        }

                                        this.setState(
                                          {
                                            date1: moment(startDate).format('YYYY-MM-DD'),
                                            date2: endDate2,
                                            selectedDate: [moment(startDate), moment(endDate2)],
                                            items: [],
                                          },
                                          () => {
                                            this.checkDates(run);
                                            //this.loadResults();
                                          },
                                        );
                                      } else this.setState({ selectedDate: ev.value });
                                    }}
                                    showRangeLabels={false}
                                    inRangeInvalid={false}
                                    rangeEndInvalid={true}
                                    invalid={this.state.cInvalids}
                                    labels={this.state.cLabels}
                                    colors={this.state.cColors}
                                  />
                                )}
                                {this.state.disableCheckOut == 0 && this.state.loadingCalendar && <LinearProgress />}
                              </Grid>
                            )}
                            <Grid xl={1} sm={1} xs={1}></Grid>
                            <Grid
                              xl={10}
                              sm={10}
                              xs={10}
                              container
                              direction="row"
                              alignItems="center"
                              justifyContent="center">
                              {this.state.disableCoupons == 0 && (
                                <span onClick={() => this.toggleView(2)}>
                                  <Typography
                                    component="label"
                                    className={classes.title5 + ' wbpSearchTitle3 ' + classes.titleSize15}>
                                    <IntlMessages id="_SPECIAL_CODES" />
                                  </Typography>
                                  {this.state.selectedView != 2 && <ExpandMore />}
                                  {this.state.selectedView == 2 && <ExpandLess />}
                                </span>
                              )}
                            </Grid>
                            <Grid xl={1} sm={1} xs={1}></Grid>

                            {this.state.selectedView == 1 && (
                              <>
                                <Grid item xl={12} sm={6} xs={6}></Grid>

                                <Grid item xl={6} sm={12} xs={12}></Grid>
                              </>
                            )}
                            {this.state.selectedView == 2 && (
                              <Grid container xl={12} sm={12} xs={12} spacing={2}>
                                <Grid
                                  container
                                  xl={4}
                                  sm={4}
                                  xs={12}
                                  direction="row"
                                  alignItems="center"
                                  justifyContent={isMobile == 1 ? 'center' : 'flex-end'}>
                                  <Typography component="label" className={classes.title + ' wbpSearchTitle'}>
                                    <IntlMessages id="_COUPON_CODE" />
                                  </Typography>
                                </Grid>
                                <Grid
                                  container
                                  xl={4}
                                  sm={4}
                                  xs={12}
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="center">
                                  <TextField
                                    id="priceModel"
                                    name="priceModel"
                                    variant="outlined"
                                    size="small"
                                    fullWidth={isMobile == false}
                                    margin="small"
                                    className={classes.combo2 + ' wbpCouponInput'}
                                    value={this.state.coupon}
                                    onChange={event => this.handleChange('coupon', event.target.value)}
                                  />
                                </Grid>
                                <Grid
                                  container
                                  xl={4}
                                  sm={4}
                                  xs={12}
                                  direction="row"
                                  alignItems="center"
                                  justifyContent={isMobile == 1 ? 'center' : 'flex-start'}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => this.loadResults()}>
                                    <IntlMessages id="_APPLY" />
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </GridContainer>
                        )}
                      </CmtCardContent>
                    </CmtCard>
                  )}
              </>
            )}

            {this.state.activeStep == 0 && (
              <>
                {this.state.properties.length > 1 &&
                  this.state.hotelId == 0 &&
                  this.state.currentPiD == 0 &&
                  this.getAllowedIds(2) > 1 && (
                    <Box>
                      <Grid container alignContent="center" justifyContent="center">
                        <Pagination
                          page={this.state.pageNum}
                          count={this.getAllowedIds(2)}
                          onChange={(event, value) =>
                            this.setState({ results: [], pageNum: value }, () => {
                              this.loadResults();
                            })
                          }
                        />
                      </Grid>
                    </Box>
                  )}
                <Grid container>
                  {this.state.properties
                    .filter(
                      function(pitem) {
                        var filterYes = true;
                        if (this.state.hotelId == 0) {
                          if (!allowedIds.includes(pitem.id)) filterYes = false;
                          /*if (!this.state.seldestinations.includes(pitem.destination)) filterYes = false;
                        if (this.state.selamenities.length > 0)
                          for (var x = 0; x < this.state.selamenities.length; x++)
                            if (!pitem.facilities.includes(this.state.selamenities[x])) filterYes = false;*/
                        }
                        return (pitem.id == this.state.hotelId || this.state.hotelId == 0) && filterYes;
                      }.bind(this),
                    )
                    .map((pitem, sindex) => (
                      <>
                        {sindex == 0 && <div ref={el => (this.firstProperty = el)}></div>}
                        {((this.state.showProperty == 1 && pitem.id == this.state.currentPiD) ||
                          this.state.currentPiD == 0) && (
                          <Grid
                            item
                            xs={12}
                            xl={this.state.currentPiD == 0 && showRight == 0 ? userGrid : 12}
                            sm={this.state.currentPiD == 0 && showRight == 0 ? userGrid : 12}>
                            <CmtCard className={this.state.currentPiD == 0 ? classes.cardp : classes.card}>
                              <CmtCardContent
                                className={this.state.currentPiD == 0 ? classes.cardContentP : classes.cardContent}>
                                <GridContainer>
                                  <Grid xs={12} xl={12} sm={12}>
                                    <div
                                      className={this.state.currentPiD == 0 ? classes.propertyMain : classes.propertyMain2}>
                                      {pitem.id == this.state.currentPiD && (
                                        <Carousel
                                          data={pitem.pictures}
                                          id={'p' + pitem.id}
                                          time={2000}
                                          width="100%"
                                          height={carouselHeight}
                                          radius="0px"
                                          slideNumber={false}
                                          captionPosition="bottom"
                                          onClick={() => this.handleSubmit('showSlide', pitem)}
                                          automatic={false}
                                          dots={true}
                                          pauseIconColor="white"
                                          pauseIconSize="40px"
                                          slideBackgroundColor="white"
                                          showZoom={true}
                                          slideImageFit="cover"
                                          thumbnails={false}
                                          thumbnailWidth="0px"
                                          style={{
                                            textAlign: 'center',
                                            maxWidth: '100%',
                                            maxHeight: '100%',
                                            margin: '0px auto',
                                            overflow: 'hidden',
                                          }}
                                        />
                                      )}

                                      {this.state.properties.length > 1 && pitem.id != this.state.currentPiD && (
                                        <img
                                          src={pitem.pictures[0].image}
                                          alt="Snow"
                                          className={classes.propertyMainImage}
                                          onClick={() => this.handleSubmit('showSlide', pitem)}
                                        />
                                      )}
                                      <div className={classes.propertyMainTitle}>
                                        <Typography
                                          component="label"
                                          className={classes.ptitle2 + ' wbpPTitle2'}
                                          onClick={() => this.handleSubmit('showSlide', pitem)}>
                                          {pitem.title[locale]}
                                        </Typography>
                                        <Typography
                                          component="label"
                                          className={classes.ptitle + ' wbpPTitle'}
                                          onClick={() => this.handleSubmit('showSlide', pitem)}>
                                          <br />
                                          <Room /> {pitem.destination}
                                        </Typography>
                                        {pitem.facilities.length > 0 && (
                                          <Typography
                                            component="label"
                                            className={classes.ptitle + ' wbpPTitle'}
                                            onClick={() => this.handleSubmit('showSlide', pitem)}>
                                            <br />
                                            {this.showIcons(pitem.facilities, '1x')}
                                          </Typography>
                                        )}
                                        <Typography component="label" className={classes.ptitle + ' wbpPTitle'}>
                                          <br />
                                          {this.state.loading == false &&
                                            (this.state.currentPiD == 0 || this.state.currentPiD != pitem.id) && (
                                              <>{this.getBestPrice(pitem.id)}</>
                                            )}
                                        </Typography>
                                        {this.state.properties.length > 1 && pitem.id != this.state.currentPiD && (
                                          <Box className={classes.cardContentInner2}>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              onClick={() => this.handleSubmit('showProperty', pitem.id)}>
                                              <IntlMessages id="selectProperty" />
                                            </Button>
                                          </Box>
                                        )}
                                      </div>
                                    </div>
                                  </Grid>

                                  {pitem.id == this.state.currentPiD && (
                                    <Grid xs={12} xl={12} sm={12}>
                                      <Box className={classes.cardContentInner2}>
                                        <Typography component="label" className={classes.description}>
                                          {parse(pitem.description[locale])}
                                        </Typography>
                                      </Box>

                                      {pitem.additionalData[locale] != '' && (
                                        <Box className={classes.cardContentInner2}>
                                          {parse(pitem.additionalData[locale])}
                                        </Box>
                                      )}
                                      {(this.state.currentPiD == 0 || this.state.currentPiD != pitem.id) && (
                                        <Box className={classes.cardContentInner2}>{this.getBestPrice(pitem.id)}</Box>
                                      )}
                                    </Grid>
                                  )}
                                </GridContainer>
                              </CmtCardContent>
                            </CmtCard>
                          </Grid>
                        )}
                        {this.state.bookingMode == 'wellness' && this.state.currentPiD == pitem.id && (
                          <CmtCard className={classes.card}>
                            <CmtCardContent className={classes.cardContent}>
                              {this.state.treatmentsGroups
                                .filter(function(tgitem) {
                                  return tgitem.hotelId == pitem.id;
                                })
                                .map((tgitem, cindex) => (
                                  <>
                                    <Box className={classes.cardContentInner2}>
                                      <Typography component="label" className={classes.title + ' wbpTitle'}>
                                        {tgitem.title[locale]}
                                      </Typography>
                                    </Box>
                                    {this.state.treatments
                                      .filter(function(titem) {
                                        return titem.hotelId == pitem.id && tgitem.id == titem.tgId;
                                      })
                                      .map(titem => (
                                        <>
                                          <Grid container>
                                            <Grid item xs={12} md={6} xl={6}>
                                              <Box className={classes.cardContentInner2}>
                                                <Typography component="label" className={classes.title + ' wbpTitle'}>
                                                  {titem.title[locale]}
                                                  <br />
                                                </Typography>
                                                {titem.description[locale]}
                                              </Box>
                                              <Box className={classes.cardContentInner2}>
                                                <Typography component="label" className={classes.title + ' wbpTitle'}>
                                                  {this.state.items
                                                    .filter(({ treatmentId }) => treatmentId == titem.id)
                                                    .map(rtitem => (
                                                      <>
                                                        {'1 x '}
                                                        {rtitem.datetime != null ? (
                                                          getMyDate3(rtitem.datetime, locale)
                                                        ) : (
                                                          <IntlMessages id="noSelectedTime" />
                                                        )}
                                                        {rtitem.datetime != null && ' ('}
                                                        {rtitem.datetime != null &&
                                                          this.state.treatmentsPersons.filter(
                                                            ({ id }) => id == rtitem.personId,
                                                          )[0].name}
                                                        {rtitem.datetime != null && ')'}

                                                        <IconButton
                                                          size="small"
                                                          onClick={() => this.handleSubmit('Remove2', rtitem)}>
                                                          <DeleteIcon />
                                                        </IconButton>
                                                        <br />
                                                      </>
                                                    ))}
                                                </Typography>
                                              </Box>
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              md={2}
                                              xl={2}
                                              alignContent="flex-end"
                                              justifyContent="flex-end">
                                              {this.state.validTreatments.filter(({ tid }) => tid == titem.id).length ==
                                                0 && (
                                                <Box className={classes.cardContentInner2}>
                                                  <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() =>
                                                      this.getValidTreatments(
                                                        titem.id,
                                                        !Array(this.state.loadedTreatmetns).includes(parseInt(titem.id)),
                                                      )
                                                    }>
                                                    <IntlMessages id="findAvailableTreatments" />
                                                  </Button>
                                                </Box>
                                              )}
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              md={2}
                                              xl={2}
                                              alignContent="flex-end"
                                              justifyContent="flex-end">
                                              {this.state.validTreatments.filter(({ tid }) => tid == titem.id).length ==
                                                0 && (
                                                <Box className={classes.cardContentInner2}>
                                                  <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    onClick={() => this.addTreatment(titem.id, 0, null)}>
                                                    <IntlMessages id="addWithoutTime" />
                                                  </Button>
                                                </Box>
                                              )}
                                            </Grid>

                                            <Grid
                                              item
                                              xs={12}
                                              md={2}
                                              xl={2}
                                              alignContent="flex-end"
                                              justifyContent="flex-end"
                                              className={classes.rateReserve}>
                                              <Typography component="label" className={classes.title + ' wbpTitle'}>
                                                {this.getFormated(titem.price)}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                          {this.state.validTreatments.filter(({ tid }) => tid == titem.id).length == 0 &&
                                            this.state.currentTreatment == titem.id && (
                                              <MyAlert severity="warning">
                                                <IntlMessages id="noAvailableTerms" />
                                              </MyAlert>
                                            )}
                                          {this.state.validTreatments.filter(({ tid }) => tid == titem.id).length > 0 && (
                                            <>
                                              <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={12} md={1} xl={1} alignItems="baseline">
                                                  <IconButton
                                                    size="small"
                                                    onClick={() => this.getValidTreatments(titem.id, false, -1)}>
                                                    <CloseIcon />
                                                  </IconButton>
                                                </Grid>
                                                <Grid item xs={12} md={3} xl={3} alignItems="baseline">
                                                  <TextField
                                                    id="priceModel"
                                                    name="priceModel"
                                                    select
                                                    variant="filled"
                                                    size="small"
                                                    margin="small"
                                                    fullWidth={true}
                                                    className={classes.combo2}
                                                    label={<IntlMessages id="choosePerson" />}
                                                    value={this.state.choosedPerson}
                                                    onChange={event =>
                                                      this.handleChange('choosedPerson', event.target.value)
                                                    }>
                                                    <MenuItem value={0}>
                                                      <IntlMessages id="anyPerson" />
                                                    </MenuItem>
                                                    {this.state.validPersons.map(ptitem => (
                                                      <MenuItem value={ptitem}>
                                                        {
                                                          this.state.treatmentsPersons.filter(({ id }) => id == ptitem)[0]
                                                            .name
                                                        }
                                                      </MenuItem>
                                                    ))}
                                                  </TextField>
                                                </Grid>
                                                <Grid item xs={12} md={3} xl={3} alignItems="baseline">
                                                  <Datepicker
                                                    display="bottom"
                                                    locale={myLocale}
                                                    value={this.state.choosedDateTime}
                                                    touchUi={false}
                                                    controls={['calendar', 'timegrid']}
                                                    stepMinute={30}
                                                    min={today2}
                                                    inputProps={{
                                                      placeholder: intl.formatMessage({ id: 'pleaseSelect' }),
                                                    }}
                                                    onChange={ev => {
                                                      //console.log(ev.value);
                                                      var startDate = ev.value;
                                                      this.setState(
                                                        {
                                                          choosedDateTime: startDate,
                                                        },
                                                        () => {
                                                          //this.loadResults();
                                                        },
                                                      );
                                                    }}
                                                    valid={this.state.validTreatments}
                                                  />
                                                </Grid>

                                                <Grid item xs={12} md={2} xl={2} alignItems="baseline">
                                                  <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="small"
                                                    disabled={this.state.choosedDateTime == null}
                                                    onClick={() =>
                                                      this.addTreatment(
                                                        titem.id,
                                                        this.state.choosedPerson,
                                                        this.state.choosedDateTime,
                                                      )
                                                    }>
                                                    <IntlMessages id="addTreatment" />
                                                  </Button>
                                                </Grid>
                                                <Grid item xs={12} md={3} xl={3}></Grid>
                                              </Grid>
                                            </>
                                          )}
                                        </>
                                      ))}
                                  </>
                                ))}
                            </CmtCardContent>
                          </CmtCard>
                        )}
                        {this.state.bookingMode == 'room' && this.state.currentPiD == pitem.id && (
                          <>
                            {this.state.rooms
                              .filter(
                                function(ritem) {
                                  if (this.state.userRoomId != -1) return ritem.id == this.state.userRoomId;
                                  return ritem.hotelId == pitem.id;
                                }.bind(this),
                              )
                              .sort(
                                function(r1item, r2item) {
                                  if (this.state.userPriorityRoomId != -1) {
                                    //console.log('tu');
                                    if (r1item.id == this.state.userPriorityRoomId) return -1;
                                    else return 1;
                                  }
                                  if (this.state.showAvailableFirst == 1) {
                                    const m1a = this.state.results.filter(function(sitem) {
                                      return sitem.roomId == r1item.id && sitem.serviceId != null;
                                    });

                                    const m2a = this.state.results.filter(function(sitem) {
                                      return sitem.roomId == r2item.id && sitem.serviceId != null;
                                    });
                                    var m1 = 0,
                                      m2 = 0;
                                    if (m1a.length > 0) m1 = m1a[0].maxRooms;
                                    if (m2a.length > 0) m2 = m2a[0].maxRooms;
                                    //console.log(r1item, r2item, m1, m2);
                                    if (m1 > 1) m1 = 1;
                                    if (m2 > 1) m2 = 1;

                                    return m2 - m1;
                                  } else return 0;
                                }.bind(this),
                              )
                              .map((ritem, cindex) => (
                                <Grid item xs={12} xl={12} sm={12}>
                                  {cindex == 0 && <div ref={el => (this.firstRoom = el)}></div>}
                                  <CmtCard className={classes.card}>
                                    <CmtCardContent className={classes.cardContent}>
                                      <GridContainer>
                                        <Grid xs={12} xl={12} sm={12}>
                                          <Box className={classes.cardContentInner2}>
                                            <GridContainer>
                                              <Grid xs={12} xl={12} sm={12}>
                                                <Box className={classes.cardContentInner2}>
                                                  {index == 0 &&
                                                    this.state.results
                                                      .filter(function(sitem) {
                                                        return (
                                                          sitem.roomId == ritem.id &&
                                                          sitem.serviceId != null &&
                                                          sitem.maxRooms > 0
                                                        );
                                                      })
                                                      .map(
                                                        function(sitem, sindex) {
                                                          {
                                                            index++;
                                                            if (index == 1)
                                                              return <div ref={el => (this.firstService = el)}></div>;
                                                          }
                                                        }.bind(this),
                                                      )}
                                                </Box>
                                              </Grid>
                                              <Grid xs={12} xl={12} sm={12}>
                                                <Box className={classes.cardContentInner2}>
                                                  <Carousel
                                                    data={ritem.pictures}
                                                    id={ritem.id}
                                                    time={2000}
                                                    height={carouselHeight}
                                                    width="100%"
                                                    radius="0px"
                                                    slideNumber={false}
                                                    captionPosition="bottom"
                                                    onClick={() => this.handleSubmit('showSlide', ritem)}
                                                    automatic={false}
                                                    dots={false}
                                                    pauseIconColor="white"
                                                    pauseIconSize="40px"
                                                    slideBackgroundColor="white"
                                                    showZoom={true}
                                                    slideImageFit="cover"
                                                    thumbnails={false}
                                                    thumbnailWidth="0px"
                                                    style={{
                                                      textAlign: 'center',
                                                      maxWidth: '100%',
                                                      maxHeight: '100%',
                                                      margin: '0px auto',
                                                      overflow: 'hidden',
                                                    }}
                                                  />
                                                </Box>
                                                <Box
                                                  className={classes.cardContentInner6 + ' ' + classes.cardContentInner2x}>
                                                  {this.showLast(ritem)}
                                                  {this.showIcons(ritem.facilities, '1x')}
                                                </Box>
                                              </Grid>

                                              <Grid xs={12} xl={12} sm={12}>
                                                <Box
                                                  className={classes.cardContentInner2 + ' ' + classes.cardContentInner2x}>
                                                  <Grid
                                                    xs={12}
                                                    xl={12}
                                                    sm={12}
                                                    container
                                                    alignItems="center"
                                                    alignContent="flex-start"
                                                    justifyContent="flex-start"
                                                    direction="row">
                                                    <Typography
                                                      component="label"
                                                      className={classes.title + ' wbpTitle ' + classes.titleSize25}>
                                                      <span onClick={() => this.handleSubmit('showSlide', ritem)}>
                                                        {ritem.title[locale]}
                                                      </span>
                                                    </Typography>
                                                    <div style={{ marginLeft: 10 }}></div>
                                                    <Typography
                                                      component="label"
                                                      className={classes.title + ' wbpTitle ' + classes.titleSize25}>
                                                      {propertyType != 5 && this.showPersons(ritem)}
                                                    </Typography>
                                                  </Grid>
                                                </Box>
                                                {ritem.description[locale] != '' && (
                                                  <Box
                                                    className={classes.cardContentInner2 + ' ' + classes.cardContentInner2x}>
                                                    <Typography component="label" className={classes.description}>
                                                      {parse(ritem.description[locale])}
                                                    </Typography>
                                                  </Box>
                                                )}
                                                <Box
                                                  className={classes.cardContentInner2 + ' ' + classes.cardContentInner2x}>
                                                  {this.state.loadingResults == true && <LinearProgress />}

                                                  {this.state.results
                                                    .filter(function(sitem) {
                                                      return (
                                                        (userRateId == '' &&
                                                          sitem.roomId == ritem.id &&
                                                          sitem.serviceId != null &&
                                                          userPackage == '') ||
                                                        (userRateId != '' &&
                                                          sitem.roomId == ritem.id &&
                                                          sitem.rateId != null &&
                                                          sitem.rateId == userRateId)
                                                      );
                                                    })
                                                    .sort(function(r1item, r2item) {
                                                      if (r1item.featured != null && r1item.featured == 2) return -1;
                                                      if (r1item.priceFinal > r2item.priceFinal) return 1;
                                                      else if (r1item.priceFinal < r2item.priceFinal) return -1;
                                                      else return 0;
                                                    })
                                                    .map(
                                                      function(sitem, sindex) {
                                                        if (pitem.onRequest == 1) {
                                                          return (
                                                            <GridContainer className={classes.rateBox}>
                                                              <Grid item xs={12} xl={8} sm={8}>
                                                                {this.showRoomMessage2(sitem.messageId)}
                                                              </Grid>
                                                              <Grid
                                                                container
                                                                xs={12}
                                                                xl={4}
                                                                sm={4}
                                                                className={classes.rateReserveRight}>
                                                                <Button
                                                                  variant="contained"
                                                                  color="primary"
                                                                  size="normal"
                                                                  onClick={() => this.handleSubmit('Reserve', sitem)}>
                                                                  <IntlMessages id="_INQUIRY" />
                                                                </Button>
                                                              </Grid>
                                                            </GridContainer>
                                                          );
                                                        }
                                                        if (sitem.maxRooms == 0) {
                                                          return (
                                                            <GridContainer className={classes.rateBox}>
                                                              <Grid item xs={12} xl={12} sm={12}>
                                                                {this.showRoomMessage(0)}
                                                                {this.showRoomMessage2(sitem.messageId)}
                                                                {this.showAvailableTerms(sitem.availableDays, sitem)}
                                                              </Grid>
                                                            </GridContainer>
                                                          );
                                                        }
                                                        if (
                                                          sitem.maxRooms > 0 &&
                                                          sindex == this.state.general.maxRates &&
                                                          (ritem.showAllRates == 0 || ritem.showAllRates == null)
                                                        )
                                                          return (
                                                            <GridContainer className={classes.rateBox}>
                                                              <Grid
                                                                item
                                                                xs={12}
                                                                xl={12}
                                                                sm={12}
                                                                onClick={() => this.toggleView2(ritem, 1)}>
                                                                <Typography
                                                                  component="label"
                                                                  className={classes.title4 + ' wbpShowMore'}>
                                                                  <IntlMessages id="showMoreRates" />
                                                                  <ExpandMore />
                                                                </Typography>
                                                              </Grid>
                                                            </GridContainer>
                                                          );

                                                        if (
                                                          sitem.maxRooms > 0 &&
                                                          (sindex < this.state.general.maxRates || ritem.showAllRates == 1)
                                                        )
                                                          return (
                                                            <GridContainer
                                                              className={
                                                                sitem.featured != null && sitem.featured == 1
                                                                  ? classes.rateBox + ' ' + classes.featured + ' wbpFeatured'
                                                                  : classes.rateBox
                                                              }>
                                                              {sitem.featured != null && sitem.featured == 1 && (
                                                                <div className={classes.featuredLabel + ' wbpFeaturedLabel'}>
                                                                  Special Offer
                                                                </div>
                                                              )}
                                                              <Grid item xs={12} xl={12} sm={12}>
                                                                <Typography
                                                                  component="label"
                                                                  className={
                                                                    classes.title + ' wbpTitle ' + classes.titleSize20
                                                                  }>
                                                                  {sitem.serviceId != 0 &&
                                                                    this.state.services.find(
                                                                      ({ id }) => id == sitem.serviceId,
                                                                    ).title[locale]}
                                                                  {sitem.rateId != null &&
                                                                    this.state.rates.find(({ id }) => id == sitem.rateId)
                                                                      .title[locale]}
                                                                  {sitem.upsell == 1 && <> {sitem.upselltext}</>}
                                                                  {sitem.featured != null && sitem.featured > 0 && (
                                                                    <>
                                                                      {' '}
                                                                      <Chip
                                                                        className={'wbpFeatured'}
                                                                        style={{
                                                                          backgroundColor: '#ffffff',
                                                                          border: '1px solid #008000',
                                                                          borderRadius: 4,
                                                                          marginRight: 5,
                                                                          marginBottom: 5,
                                                                        }}
                                                                        size="small"
                                                                        label="Special Offer"
                                                                      />
                                                                    </>
                                                                  )}
                                                                </Typography>
                                                              </Grid>
                                                              <Grid item xs={12} xl={8} sm={8}>
                                                                <Typography
                                                                  component="label"
                                                                  className={classes.description}>
                                                                  {sitem.serviceId != 0 &&
                                                                    this.state.services.find(
                                                                      ({ id }) => id == sitem.serviceId,
                                                                    ).description[locale]}
                                                                  {sitem.rateId != null &&
                                                                    parse(
                                                                      shorten(
                                                                        this.state.rates.find(({ id }) => id == sitem.rateId)
                                                                          .description[locale],
                                                                      ),
                                                                    )}
                                                                </Typography>
                                                                <br />
                                                                {sitem.mealPlan != null && (
                                                                  <Chip
                                                                    className={'wbpMealPlan'}
                                                                    style={{
                                                                      backgroundColor: '#ffffff',
                                                                      border: '1px solid #E1E1E1',
                                                                      borderRadius: 4,
                                                                      marginRight: 5,
                                                                      marginBottom: 5,
                                                                    }}
                                                                    size="small"
                                                                    label={this.getMealPlan(sitem.mealPlan)}
                                                                  />
                                                                )}
                                                                {sitem.freeCancel != '' && (
                                                                  <Chip
                                                                    className={'wbpFreeCancellation'}
                                                                    style={{
                                                                      backgroundColor: '#ffffff',
                                                                      border: '1px solid #E1E1E1',
                                                                      borderRadius: 4,
                                                                      marginRight: 5,
                                                                      marginBottom: 5,
                                                                    }}
                                                                    size="small"
                                                                    label={
                                                                      intl.formatMessage({ id: 'freeCancellation' }) +
                                                                      this.getCancelInfo2(sitem.freeCancel, pitem.cit)
                                                                    }
                                                                  />
                                                                )}
                                                                {sitem.partialCancel != null && sitem.partialCancel != '' && (
                                                                  <Chip
                                                                    className={'wbpPartialCancellation'}
                                                                    style={{
                                                                      backgroundColor: '#ffffff',
                                                                      border: '1px solid #E1E1E1',
                                                                      borderRadius: 4,
                                                                      marginBottom: 5,
                                                                      marginRight: 5,
                                                                    }}
                                                                    size="small"
                                                                    label={
                                                                      intl.formatMessage({ id: 'partialCancellation' }) +
                                                                      this.getCancelInfo2(sitem.partialCancel, pitem.cit)
                                                                    }
                                                                  />
                                                                )}
                                                                {sitem.badge != null && sitem.badge != '' && (
                                                                  <Chip
                                                                    className={'wbpBadge'}
                                                                    style={{
                                                                      backgroundColor: '#ffffff',
                                                                      border: '1px solid #E1E1E1',
                                                                      borderRadius: 4,
                                                                      marginBottom: 5,
                                                                      marginRight: 5,
                                                                    }}
                                                                    size="small"
                                                                    label={this.getOfferText(sitem.badge)}
                                                                  />
                                                                )}
                                                                {sitem.pbadge[locale] != null &&
                                                                  sitem.pbadge[locale] != '' &&
                                                                  this.state.selPayment != '' &&
                                                                  this.state.selPayment != 'online' &&
                                                                  this.state.selPayment != 'contract' &&
                                                                  this.state.selPayment != 'poa' &&
                                                                  this.state.selPayment != 'bank.transfer' && (
                                                                    <Chip
                                                                      className="wbpBadge"
                                                                      style={{
                                                                        backgroundColor: '#ffffff',
                                                                        border: '1px solid #E1E1E1',
                                                                        borderRadius: 4,
                                                                        marginRight: 5,
                                                                        marginBottom: 5,
                                                                      }}
                                                                      size="small"
                                                                      label={sitem.pbadge[locale]}
                                                                    />
                                                                  )}
                                                                {sitem.policyId == 1 && (
                                                                  <Chip
                                                                    className="wbpNonRefundable"
                                                                    style={{
                                                                      backgroundColor: '#ffffff',
                                                                      border: '1px solid #E1E1E1',
                                                                      borderRadius: 4,
                                                                      marginRight: 5,
                                                                      marginBottom: 5,
                                                                    }}
                                                                    size="small"
                                                                    label=<IntlMessages id="nonRef" />
                                                                  />
                                                                )}
                                                                {pitem.showConditions == 1 && (
                                                                  <Chip
                                                                    className="wbpShowConditions"
                                                                    style={{
                                                                      backgroundColor: '#ffffff',
                                                                      border: '3px solid rgb(1 125 171)',
                                                                      borderRadius: 4,
                                                                      marginRight: 5,
                                                                      marginBottom: 5,
                                                                    }}
                                                                    size="small"
                                                                    onClick={() =>
                                                                      this.setState({
                                                                        showSlide: true,
                                                                        dialogTitle: ritem.title[locale],
                                                                        sliderData: ritem.pictures,
                                                                        roomData: ritem,
                                                                        selItem: sitem,
                                                                        showPrivacy2: true,
                                                                        showPackageData: false,
                                                                        showRateData: true,
                                                                      })
                                                                    }
                                                                    label=<IntlMessages id="moreInfo" />
                                                                  />
                                                                )}

                                                                {sitem.priceOriginal != sitem.priceFinal &&
                                                                  ((sitem.priceFinal - sitem.priceOriginal) /
                                                                    sitem.priceOriginal) *
                                                                    100 <
                                                                    -4 && (
                                                                    <Chip
                                                                      className="wbpDiscount"
                                                                      style={{
                                                                        backgroundColor: '#ffffff',
                                                                        border: '3px solid #bddcf2',
                                                                        borderRadius: 4,
                                                                        marginRight: 5,
                                                                        marginBottom: 5,
                                                                      }}
                                                                      sx={{
                                                                        '& .MuiChip-label': {
                                                                          fontSize: '6px',
                                                                        },
                                                                      }}
                                                                      size="small"
                                                                      label={
                                                                        formatPrice(
                                                                          ((sitem.priceFinal - sitem.priceOriginal) /
                                                                            sitem.priceOriginal) *
                                                                            100,
                                                                          locale,
                                                                        ) + '%'
                                                                      }
                                                                    />
                                                                  )}
                                                                {sitem.maxRooms > 1 &&
                                                                  sitem.upsell == 0 &&
                                                                  ritem.unitSelect == 0 && (
                                                                    <>
                                                                      <br />
                                                                      <br />
                                                                      <IntlMessages id="_ROOMS" />
                                                                      <br />
                                                                      <TextField
                                                                        id="priceModel"
                                                                        name="priceModel"
                                                                        select
                                                                        variant="outlined"
                                                                        size="small"
                                                                        margin="small"
                                                                        className={classes.combo2}
                                                                        value={this.state.roomCount[sitem.index]}
                                                                        onChange={event =>
                                                                          this.handleChange(
                                                                            'roomCount',
                                                                            event.target.value,
                                                                            sitem.index,
                                                                          )
                                                                        }>
                                                                        {Array(sitem.maxRooms)
                                                                          .fill()
                                                                          .map((_, idx) => (
                                                                            <MenuItem value={idx + 1}>{idx + 1}</MenuItem>
                                                                          ))}
                                                                      </TextField>
                                                                    </>
                                                                  )}
                                                              </Grid>

                                                              <Grid
                                                                container
                                                                xs={12}
                                                                xl={4}
                                                                sm={4}
                                                                className={classes.rateReserveRight}>
                                                                <br />
                                                                <Typography component="label" className={classes.title4}>
                                                                  {this.state.disableGuests == 0 && (
                                                                    <>
                                                                      {this.state.roomCount[sitem.index] * this.state.adults}{' '}
                                                                      {this.state.roomCount[sitem.index] *
                                                                        this.state.adults ==
                                                                      1 ? (
                                                                        <IntlMessages id="_ADULT" />
                                                                      ) : (
                                                                        <IntlMessages id="_ADULTS" />
                                                                      )}
                                                                      {this.state.roomCount[sitem.index] *
                                                                        this.state.children >
                                                                        0 && (
                                                                        <>
                                                                          {', '}
                                                                          {this.state.roomCount[sitem.index] *
                                                                            this.state.children}{' '}
                                                                          {this.state.roomCount[sitem.index] *
                                                                            this.state.children ==
                                                                          1 ? (
                                                                            <IntlMessages id="_CHILD" />
                                                                          ) : (
                                                                            <IntlMessages id="_CHILDREN" />
                                                                          )}
                                                                        </>
                                                                      )}
                                                                    </>
                                                                  )}
                                                                </Typography>
                                                                {this.state.disableCheckOut == 0 && (
                                                                  <Typography component="label" className={classes.title4}>
                                                                    {sitem.nights}{' '}
                                                                    {sitem.nights == 1 ? (
                                                                      <IntlMessages id="_NIGHT" />
                                                                    ) : (
                                                                      <IntlMessages id="_NIGHTS" />
                                                                    )}
                                                                  </Typography>
                                                                )}
                                                                <Typography component="label" className={classes.title4}>
                                                                  <IntlMessages id="_INCLUDETAX" />
                                                                </Typography>
                                                                <br />
                                                                <Typography
                                                                  component="label"
                                                                  className={
                                                                    classes.title + ' wbpTitle ' + classes.titleSize25
                                                                  }>
                                                                  {sitem.priceOriginal != sitem.priceFinal &&
                                                                    ((sitem.priceFinal - sitem.priceOriginal) /
                                                                      sitem.priceOriginal) *
                                                                      100 <
                                                                      -4 && (
                                                                      <strike className={classes.titleSize20}>
                                                                        {this.getFormated(
                                                                          this.state.roomCount[sitem.index] *
                                                                            (sitem.priceOriginal + sitem.taxes),
                                                                        )}
                                                                      </strike>
                                                                    )}{' '}
                                                                  {this.getFormated(
                                                                    this.state.roomCount[sitem.index] *
                                                                      (sitem.priceFinal + sitem.taxes),
                                                                  )}
                                                                </Typography>
                                                                <br />
                                                                {/*!this.isSelected(sitem) &&*/ sitem.upsell == 0 && (
                                                                  <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    size="normal"
                                                                    onClick={() => this.handleSubmit('Reserve', sitem)}>
                                                                    <IntlMessages id="_RESERVE" />
                                                                  </Button>
                                                                )}
                                                                {sitem.upsell == 1 && (
                                                                  <Button
                                                                    variant="contained"
                                                                    color="primary"
                                                                    size="normal"
                                                                    onClick={() => this.handleSubmit('Select', sitem)}>
                                                                    <IntlMessages id="_SELECT" /> {sitem.nights}{' '}
                                                                    {sitem.nights == 1 ? (
                                                                      <IntlMessages id="_NIGHT" />
                                                                    ) : (
                                                                      <IntlMessages id="_NIGHTS" />
                                                                    )}
                                                                  </Button>
                                                                )}
                                                                {/*this.isSelected(sitem) && (
                                                              <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                size="normal"
                                                                onClick={() => this.handleSubmit('Remove', sitem)}>
                                                                <IntlMessages id="_REMOVE" />
                                                              </Button>
                                                            )*/}
                                                                <br />
                                                                <br />
                                                              </Grid>
                                                            </GridContainer>
                                                          );
                                                      }.bind(this),
                                                    )}
                                                  {this.state.results
                                                    .filter(function(sitem) {
                                                      return (
                                                        sitem.roomId == ritem.id &&
                                                        sitem.serviceId != null &&
                                                        userPackage == ''
                                                      );
                                                    })
                                                    .map(
                                                      function(sitem, sindex) {
                                                        if (
                                                          sitem.maxRooms > 0 &&
                                                          sindex == this.state.general.maxRates &&
                                                          ritem.showAllRates === 1
                                                        )
                                                          return (
                                                            <GridContainer className={classes.rateBox}>
                                                              <Grid
                                                                item
                                                                xs={12}
                                                                xl={12}
                                                                sm={12}
                                                                onClick={() => this.toggleView2(ritem, 0)}>
                                                                <Typography component="label" className={classes.title4}>
                                                                  <IntlMessages id="showLessRates" />
                                                                  <ExpandLess />
                                                                </Typography>
                                                              </Grid>
                                                            </GridContainer>
                                                          );
                                                      }.bind(this),
                                                    )}
                                                </Box>

                                                {pitem.showPackages == 1 &&
                                                  this.state.results.filter(function(sitem) {
                                                    return sitem.roomId == ritem.id && sitem.packageId != null;
                                                  }).length > 0 && (
                                                    <>
                                                      <Grid xs={12} xl={12} sm={12} className={classes.rateBox}>
                                                        <Box className={classes.cardContentInner2x}>
                                                          <Typography
                                                            component="label"
                                                            className={classes.title + ' wbpTitle'}>
                                                            <IntlMessages id="_PACKAGES" />
                                                          </Typography>
                                                        </Box>
                                                      </Grid>
                                                      <Grid xs={12} xl={12} sm={12}>
                                                        <Box className={classes.cardContentInner2x}>
                                                          {this.state.loadingResults == true && <LinearProgress />}
                                                          {this.state.results
                                                            .filter(
                                                              function(sitem) {
                                                                return (
                                                                  (this.state.userPackage == '' &&
                                                                    sitem.roomId == ritem.id &&
                                                                    sitem.packageId != null) ||
                                                                  (this.state.userPackage != '' &&
                                                                    sitem.roomId == ritem.id &&
                                                                    sitem.packageId != null &&
                                                                    sitem.packageId == this.state.userPackage) ||
                                                                  (this.state.userRateId != '' &&
                                                                    sitem.roomId == ritem.id &&
                                                                    sitem.rateId != null &&
                                                                    sitem.rateId == this.state.userRateId)
                                                                );
                                                              }.bind(this),
                                                            )
                                                            .sort(function(r1item, r2item) {
                                                              if (r1item.priceFinal > r2item.priceFinal) return 1;
                                                              else if (r1item.priceFinal < r2item.priceFinal) return -1;
                                                              else return 0;
                                                            })

                                                            .map(
                                                              function(sitem, sindex) {
                                                                return (
                                                                  <>
                                                                    <GridContainer
                                                                      className={
                                                                        sitem.featured != null && sitem.featured == 1
                                                                          ? classes.rateBox +
                                                                            ' ' +
                                                                            classes.featured +
                                                                            ' wbpFeatured'
                                                                          : classes.rateBox
                                                                      }>
                                                                      {sitem.featured != null && sitem.featured == 1 && (
                                                                        <div
                                                                          className={
                                                                            classes.featuredLabel + ' wbpFeaturedLabel'
                                                                          }>
                                                                          Special Offer
                                                                        </div>
                                                                      )}
                                                                      <Grid item xs={12} xl={12} sm={12}>
                                                                        <Typography
                                                                          component="label"
                                                                          className={
                                                                            classes.title +
                                                                            ' wbpTitle ' +
                                                                            classes.titleSize20
                                                                          }>
                                                                          {
                                                                            this.state.packages.find(
                                                                              ({ id }) => id == sitem.packageId,
                                                                            ).title[locale]
                                                                          }
                                                                        </Typography>
                                                                      </Grid>
                                                                      {(sitem.maxRooms == 0 || sitem.upsell == 1) && (
                                                                        <Grid item xs={12} xl={12} sm={12}>
                                                                          {this.showAvailableTerms2(
                                                                            sitem,
                                                                            sitem.upsell,
                                                                            ritem,
                                                                          )}
                                                                        </Grid>
                                                                      )}
                                                                      <Grid item xs={12} xl={8} sm={8}>
                                                                        <Typography
                                                                          component="label"
                                                                          className={classes.description}>
                                                                          {parse(
                                                                            shorten(
                                                                              this.state.packages.find(
                                                                                ({ id }) => id == sitem.packageId,
                                                                              ).description[locale],
                                                                            ),
                                                                          )}
                                                                        </Typography>
                                                                        <br />
                                                                        <Chip
                                                                          className="wbpShowConditions"
                                                                          style={{
                                                                            backgroundColor: '#ffffff',
                                                                            border: '3px solid rgb(1 125 171)',
                                                                            borderRadius: 4,
                                                                            marginBottom: 5,
                                                                            marginRight: 5,
                                                                          }}
                                                                          size="small"
                                                                          onClick={() =>
                                                                            this.handleSubmit('ShowPackage', sitem, ritem)
                                                                          }
                                                                          label=<IntlMessages id="moreInfo" />
                                                                        />

                                                                        {sitem.mealPlan != null && (
                                                                          <Chip
                                                                            className="wbpMealPlan"
                                                                            style={{
                                                                              backgroundColor: '#ffffff',
                                                                              border: '1px solid #E1E1E1',
                                                                              marginBottom: 5,
                                                                              borderRadius: 4,
                                                                              marginRight: 5,
                                                                            }}
                                                                            size="small"
                                                                            label={this.getMealPlan(sitem.mealPlan)}
                                                                          />
                                                                        )}
                                                                        {sitem.policyId < 0 &&
                                                                          this.getCancelInfo(
                                                                            this.state.policies.filter(
                                                                              ({ id }) => id == sitem.policyId,
                                                                            )[0].ci,
                                                                            pitem.cit,
                                                                          ) != 0 && (
                                                                            <Chip
                                                                              className="wbpFreeCancellation"
                                                                              style={{
                                                                                backgroundColor: '#ffffff',
                                                                                border: '1px solid #E1E1E1',
                                                                                marginBottom: 5,
                                                                                borderRadius: 4,
                                                                                marginRight: 5,
                                                                              }}
                                                                              size="small"
                                                                              label={
                                                                                intl.formatMessage({
                                                                                  id: 'freeCancellation',
                                                                                }) +
                                                                                this.getCancelInfo(
                                                                                  this.state.policies.filter(
                                                                                    ({ id }) => id == sitem.policyId,
                                                                                  )[0].ci,
                                                                                  pitem.cit,
                                                                                )
                                                                              }
                                                                            />
                                                                          )}

                                                                        {sitem.maxRooms > 1 && (
                                                                          <>
                                                                            <br />
                                                                            <IntlMessages id="_ROOMS" />
                                                                            <br />
                                                                            <TextField
                                                                              id="priceModel"
                                                                              name="priceModel"
                                                                              select
                                                                              variant="outlined"
                                                                              size="small"
                                                                              margin="small"
                                                                              className={classes.combo2}
                                                                              value={this.state.roomCount[sitem.index]}
                                                                              onChange={event =>
                                                                                this.handleChange(
                                                                                  'roomCount',
                                                                                  event.target.value,
                                                                                  sitem.index,
                                                                                )
                                                                              }>
                                                                              {Array(sitem.maxRooms)
                                                                                .fill()
                                                                                .map((_, idx) => (
                                                                                  <MenuItem value={idx + 1}>
                                                                                    {idx + 1}
                                                                                  </MenuItem>
                                                                                ))}
                                                                            </TextField>
                                                                          </>
                                                                        )}
                                                                      </Grid>
                                                                      <Grid
                                                                        item
                                                                        xs={12}
                                                                        xl={4}
                                                                        sm={4}
                                                                        className={classes.rateReserveRight}>
                                                                        {(sitem.maxRooms > 0 || sitem.upsell == 1) && (
                                                                          <>
                                                                            <Typography
                                                                              component="label"
                                                                              className={classes.title4}>
                                                                              {this.state.roomCount[sitem.index] *
                                                                                this.state.adults}{' '}
                                                                              {this.state.roomCount[sitem.index] *
                                                                                this.state.adults ==
                                                                              1 ? (
                                                                                <IntlMessages id="_ADULT" />
                                                                              ) : (
                                                                                <IntlMessages id="_ADULTS" />
                                                                              )}
                                                                              {this.state.roomCount[sitem.index] *
                                                                                this.state.children >
                                                                                0 && (
                                                                                <>
                                                                                  {', '}
                                                                                  {this.state.roomCount[sitem.index] *
                                                                                    this.state.children}{' '}
                                                                                  {this.state.roomCount[sitem.index] *
                                                                                    this.state.children ==
                                                                                  1 ? (
                                                                                    <IntlMessages id="_CHILD" />
                                                                                  ) : (
                                                                                    <IntlMessages id="_CHILDREN" />
                                                                                  )}
                                                                                </>
                                                                              )}
                                                                            </Typography>

                                                                            <Typography
                                                                              component="label"
                                                                              className={classes.title4}>
                                                                              {sitem.nights}{' '}
                                                                              {sitem.nights == 1 ? (
                                                                                <IntlMessages id="_NIGHT" />
                                                                              ) : (
                                                                                <IntlMessages id="_NIGHTS" />
                                                                              )}
                                                                            </Typography>
                                                                            {sitem.upsell != 1 && (
                                                                              <Typography
                                                                                component="label"
                                                                                className={classes.title4}>
                                                                                <IntlMessages id="_INCLUDETAX" />
                                                                              </Typography>
                                                                            )}
                                                                            <br />
                                                                          </>
                                                                        )}
                                                                        <Typography
                                                                          component="label"
                                                                          className={
                                                                            classes.title +
                                                                            ' wbpTitle ' +
                                                                            classes.titleSize25
                                                                          }>
                                                                          {sitem.priceOriginal != sitem.priceFinal &&
                                                                            ((sitem.priceFinal - sitem.priceOriginal) /
                                                                              sitem.priceOriginal) *
                                                                              100 <
                                                                              -4 && (
                                                                              <strike>
                                                                                {this.getFormated(
                                                                                  this.state.roomCount[sitem.index] *
                                                                                    (sitem.priceOriginal + sitem.taxes),
                                                                                )}
                                                                              </strike>
                                                                            )}{' '}
                                                                          {this.getFormated(
                                                                            this.state.roomCount[sitem.index] *
                                                                              (sitem.priceFinal + sitem.taxes),
                                                                          )}
                                                                        </Typography>
                                                                        <br />
                                                                        {sitem.upsell == 1 && (
                                                                          <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            size="normal"
                                                                            onClick={() =>
                                                                              this.handleSubmit('Select', sitem)
                                                                            }>
                                                                            <IntlMessages id="_SELECT" /> {sitem.nights}{' '}
                                                                            {sitem.nights == 1 ? (
                                                                              <IntlMessages id="_NIGHT" />
                                                                            ) : (
                                                                              <IntlMessages id="_NIGHTS" />
                                                                            )}
                                                                          </Button>
                                                                        )}
                                                                        {!this.isSelected(sitem) &&
                                                                          sitem.upsell == 0 &&
                                                                          sitem.maxRooms > 0 && (
                                                                            <Button
                                                                              variant="contained"
                                                                              color="primary"
                                                                              size="normal"
                                                                              onClick={() =>
                                                                                this.handleSubmit('Reserve', sitem)
                                                                              }>
                                                                              <IntlMessages id="_RESERVE" />
                                                                            </Button>
                                                                          )}
                                                                        {this.isSelected(sitem) && sitem.upsell == 0 && (
                                                                          <Button
                                                                            variant="contained"
                                                                            color="secondary"
                                                                            size="normal"
                                                                            onClick={() =>
                                                                              this.handleSubmit('Remove', sitem)
                                                                            }>
                                                                            <IntlMessages id="_REMOVE" />
                                                                          </Button>
                                                                        )}
                                                                      </Grid>
                                                                    </GridContainer>
                                                                  </>
                                                                );
                                                              }.bind(this),
                                                            )}
                                                        </Box>
                                                      </Grid>
                                                    </>
                                                  )}
                                              </Grid>
                                            </GridContainer>
                                          </Box>
                                        </Grid>
                                      </GridContainer>
                                    </CmtCardContent>
                                  </CmtCard>
                                </Grid>
                              ))}
                          </>
                        )}
                      </>
                    ))}
                </Grid>
                {this.state.properties.length > 1 &&
                  this.state.hotelId == 0 &&
                  this.state.currentPiD == 0 &&
                  this.getAllowedIds(2) > 1 && (
                    <Box>
                      <Grid container alignContent="center" justifyContent="center">
                        <Pagination
                          page={this.state.pageNum}
                          count={this.getAllowedIds(2)}
                          onChange={(event, value) =>
                            this.setState({ results: [], pageNum: value }, () => {
                              this.loadResults();
                            })
                          }
                        />
                      </Grid>
                    </Box>
                  )}
              </>
            )}

            {this.state.activeStep == 1 && (
              <>
                <CmtCard className={classes.card}>
                  <CmtCardContent className={classes.cardContent}>
                    <GridContainer spacing={20}>
                      <Grid xs={12} sm={12} xl={12}>
                        <Box className={classes.cardContentInner}>
                          <Typography component="label" className={classes.title + ' wbpTitle'}>
                            <IntlMessages id="_PERSONAL_INFORMATION" />
                          </Typography>
                        </Box>
                        <GridContainer spacing={10}>
                          <Grid item xs={12} sm={6} xl={6}>
                            <TextField
                              error={this.state.firstName != null && this.state.firstName.length < 2}
                              helperText={
                                this.state.firstName != null && this.state.firstName.length < 2 ? (
                                  <IntlMessages id="_FIRST_NAME_EMPTY_ALERT" />
                                ) : (
                                  ''
                                )
                              }
                              id="firstName"
                              name="firstName"
                              inputRef={el => (this.firstName = el)}
                              label=<IntlMessages id="_FIRST_NAME" />
                              size="small"
                              variant="filled"
                              margin="small"
                              required
                              fullWidth
                              inputProps={{ className: classes.wbpInput }}
                              value={this.state.firstName}
                              onChange={event => this.handleChange('firstName', event.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} xl={6}>
                            <TextField
                              error={this.state.lastName != null && this.state.lastName.length < 2}
                              helperText={
                                this.state.lastName != null && this.state.lastName.length < 2 ? (
                                  <IntlMessages id="_LAST_NAME_EMPTY_ALERT" />
                                ) : (
                                  ''
                                )
                              }
                              id="priceModel"
                              name="priceModel"
                              label=<IntlMessages id="_LAST_NAME" />
                              size="small"
                              inputProps={{ className: classes.wbpInput }}
                              margin="small"
                              variant="filled"
                              fullWidth
                              required
                              inputRef={el => (this.lastName = el)}
                              value={this.state.lastName}
                              onChange={event => this.handleChange('lastName', event.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} xl={6}>
                            <TextField
                              error={
                                this.state.email != null &&
                                (this.state.email.length < 3 || this.state.email.indexOf('@') < 0)
                              }
                              helperText={
                                this.state.email != null &&
                                (this.state.email.length < 3 || this.state.email.indexOf('@') < 0) ? (
                                  <IntlMessages id="_EMAIL_IS_WRONG" />
                                ) : (
                                  ''
                                )
                              }
                              id="priceModel"
                              name="priceModel"
                              inputProps={{ className: classes.wbpInput }}
                              label=<IntlMessages id="_EMAIL" />
                              size="small"
                              required
                              margin="small"
                              variant="filled"
                              fullWidth
                              inputRef={el => (this.email = el)}
                              value={this.state.email}
                              onChange={event => this.handleChange('email', event.target.value)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6} xl={6}>
                            <TextField
                              error={
                                this.state.email2 != null &&
                                (this.state.email2.length < 3 || this.state.email2.indexOf('@') < 0)
                              }
                              helperText={
                                this.state.email2 != null &&
                                (this.state.email2.length < 3 || this.state.email2.indexOf('@') < 0) ? (
                                  <IntlMessages id="_EMAIL_IS_WRONG" />
                                ) : (
                                  ''
                                )
                              }
                              id="priceModel"
                              name="priceModel"
                              label=<IntlMessages id="_EMAIL2" />
                              size="small"
                              inputProps={{ className: classes.wbpInput }}
                              margin="small"
                              required
                              fullWidth
                              variant="filled"
                              inputRef={el => (this.email2 = el)}
                              value={this.state.email2}
                              onChange={event => this.handleChange('email2', event.target.value)}
                            />
                          </Grid>

                          {this.state.opMode != 2 &&
                            this.state.properties.find(({ id }) => id == propertyId).beAddress == 1 && (
                              <Grid item xs={12} sm={6} xl={6}>
                                <TextField
                                  error={this.state.address != null && this.state.address.length < 2}
                                  helperText={
                                    this.state.address != null && this.state.address.length < 2 ? (
                                      <IntlMessages id="_ADDRESS_EMPTY_ALERT" />
                                    ) : (
                                      ''
                                    )
                                  }
                                  id="priceModel"
                                  name="priceModel"
                                  label=<IntlMessages id="_ADDRESS" />
                                  size="small"
                                  margin="small"
                                  inputProps={{ className: classes.wbpInput }}
                                  variant="filled"
                                  required
                                  fullWidth
                                  value={this.state.address}
                                  inputRef={el => (this.address = el)}
                                  onChange={event => this.handleChange('address', event.target.value)}
                                />
                              </Grid>
                            )}
                          {this.state.opMode != 2 && this.state.properties.find(({ id }) => id == propertyId).beZip == 1 && (
                            <Grid item xs={12} sm={6} xl={6}>
                              <TextField
                                error={this.state.postalCode != null && this.state.postalCode.length < 2}
                                helperText={
                                  this.state.postalCode != null && this.state.postalCode.length < 2 ? (
                                    <IntlMessages id="_ZIPCODE_EMPTY_ALERT" />
                                  ) : (
                                    ''
                                  )
                                }
                                id="priceModel"
                                name="priceModel"
                                label=<IntlMessages id="_ZIP_CODE" />
                                size="small"
                                variant="filled"
                                required
                                inputProps={{ className: classes.wbpInput }}
                                inputRef={el => (this.postalCode = el)}
                                margin="small"
                                fullWidth
                                value={this.state.postalCode}
                                onChange={event => this.handleChange('postalCode', event.target.value)}
                              />
                            </Grid>
                          )}
                          {this.state.opMode != 2 && this.state.properties.find(({ id }) => id == propertyId).beCity == 1 && (
                            <Grid item xs={12} sm={6} xl={6}>
                              <TextField
                                error={this.state.city != null && this.state.city.length < 2}
                                helperText={
                                  this.state.city != null && this.state.city.length < 2 ? (
                                    <IntlMessages id="_CITY_EMPTY_ALERT" />
                                  ) : (
                                    ''
                                  )
                                }
                                id="priceModel"
                                name="priceModel"
                                label=<IntlMessages id="_CITY" />
                                size="small"
                                inputProps={{ className: classes.wbpInput }}
                                margin="small"
                                variant="filled"
                                required
                                fullWidth
                                value={this.state.city}
                                inputRef={el => (this.city = el)}
                                onChange={event => this.handleChange('city', event.target.value)}
                              />
                            </Grid>
                          )}
                          {this.state.opMode != 2 &&
                            this.state.properties.find(({ id }) => id == propertyId).beCountry == 1 && (
                              <Grid item xs={12} sm={6} xl={6}>
                                <TextField
                                  error={this.state.country != null && this.state.country.length < 2}
                                  helperText={
                                    this.state.country != null && this.state.country.length < 2 ? (
                                      <IntlMessages id="_COUNTRY_EMPTY_ALERT" />
                                    ) : (
                                      ''
                                    )
                                  }
                                  id="priceModel"
                                  name="priceModel"
                                  label=<IntlMessages id="_COUNTRY" />
                                  size="small"
                                  margin="small"
                                  fullWidth
                                  required
                                  variant="filled"
                                  inputProps={{ className: classes.wbpInput }}
                                  value={this.state.country}
                                  inputRef={el => (this.country = el)}
                                  onChange={event => this.handleChange('country', event.target.value)}
                                />
                              </Grid>
                            )}
                          {this.state.opMode != 2 && (
                            <Grid item xs={12} sm={6} xl={6}>
                              <TextField
                                error={this.state.phone != null && this.state.phone.length < 6}
                                helperText={
                                  this.state.phone != null && this.state.phone.length < 6 ? (
                                    <IntlMessages id="_PHONE_EMPTY_ALERT" />
                                  ) : (
                                    ''
                                  )
                                }
                                id="priceModel"
                                name="priceModel"
                                variant="filled"
                                label=<IntlMessages id="_PHONE" />
                                size="small"
                                margin="small"
                                inputProps={{ className: classes.wbpInput }}
                                fullWidth
                                required
                                value={this.state.phone}
                                inputRef={el => (this.phone = el)}
                                onChange={event => this.handleChange('phone', event.target.value)}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} sm={6} xl={6}>
                            {this.state.opMode != 2 &&
                              this.state.properties.find(({ id }) => id == propertyId).beBirth == 1 && (
                                <TextField
                                  error={this.state.birthDate == null || this.state.birthDate == ''}
                                  helperText={
                                    this.state.birthDate == null || this.state.birthDate == '' ? (
                                      <IntlMessages id="_BIRTH_DATE_VALID_ALERT" />
                                    ) : (
                                      ''
                                    )
                                  }
                                  id="priceModel"
                                  name="priceModel"
                                  variant="filled"
                                  label=<IntlMessages id="_BIRTH_DATE" />
                                  size="small"
                                  margin="small"
                                  inputProps={{ className: classes.wbpInput }}
                                  fullWidth
                                  required
                                  type="date"
                                  value={this.state.birthDate}
                                  inputRef={el => (this.phone = el)}
                                  onChange={event => this.handleChange('birthDate', event.target.value)}
                                />
                              )}
                          </Grid>
                        </GridContainer>
                      </Grid>
                      <Grid xs={12} sm={1} xl={1}></Grid>
                    </GridContainer>
                  </CmtCardContent>
                </CmtCard>

                {this.state.opMode == 0 && (
                  <CmtCard className={classes.card}>
                    <CmtCardContent className={classes.cardContent}>
                      <GridContainer>
                        <Grid xs={12}>
                          <Box className={classes.cardContentInner}>
                            <Accordion
                              square
                              expanded={this.state.expanded === 'panel3'}
                              onChange={() => this.handleChange('expanded', 'panel3')}>
                              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                <Typography className={classes.title + ' wbpTitle'}>
                                  <IntlMessages id="_ADDITIONAL" />
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <TextField
                                  label={<IntlMessages id="_ADDITIONAL_INFO" />}
                                  id="guestComment"
                                  name="guestComment"
                                  margin="normal"
                                  rows={6}
                                  variant="filled"
                                  fullWidth
                                  multiline="true"
                                  value={this.state.guestComment}
                                  onChange={event => this.handleChange('guestComment', event.target.value)}
                                />
                              </AccordionDetails>
                            </Accordion>
                            {this.state.extras.length > 0 &&
                              this.state.bookingId == null &&
                              this.state.bookingMode == 'room' && (
                                <Accordion
                                  square
                                  expanded={this.state.expanded === 'panel1'}
                                  onChange={() => this.handleChange('expanded', 'panel1')}>
                                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                    <Typography className={classes.title + ' wbpTitle'}>
                                      <IntlMessages id="_EXTRAS" />
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Typography>
                                      <GridContainer>
                                        {this.state.extras
                                          .filter(
                                            function(eitem) {
                                              return this.hotelSel(eitem.hotelId) && eitem.mandatory == 0;
                                            }.bind(this),
                                          )
                                          .map(
                                            function(eitem) {
                                              return [
                                                <Grid xl={1} xs={12} sm={1}>
                                                  {eitem.icon != '' && (
                                                    <img src={eitem.icon} className={classes.logoImage} />
                                                  )}
                                                </Grid>,

                                                <Grid xl={1} xs={2} sm={1}>
                                                  <Typography component="label" className={classes.title + ' wbpTitle'}>
                                                    {this.extrasSelected(eitem.id)}
                                                  </Typography>
                                                </Grid>,

                                                <Grid xl={7} xs={10} sm={7}>
                                                  <Typography component="label" className={classes.title + ' wbpTitle'}>
                                                    {eitem.title[locale]}
                                                  </Typography>
                                                  <br />
                                                  <Typography component="label" className={classes.title3 + ' wbpTitle'}>
                                                    {parse(eitem.description[locale])}
                                                  </Typography>
                                                  <br />
                                                </Grid>,
                                                <Grid xl={3} xs={12} sm={3}>
                                                  <Button
                                                    onClick={() => this.handleSubmit('AddExtra', eitem.id)}
                                                    variant="contained"
                                                    color="primary"
                                                    size="small">
                                                    <IntlMessages id="_ADD" />
                                                  </Button>{' '}
                                                  {this.isExtraSelected(eitem.id) && (
                                                    <Button
                                                      onClick={() => this.handleSubmit('RemoveExtra', eitem.id)}
                                                      variant="contained"
                                                      color="primary"
                                                      size="small">
                                                      <IntlMessages id="_REMOVE" />
                                                    </Button>
                                                  )}
                                                </Grid>,
                                              ];
                                            }.bind(this),
                                          )}
                                      </GridContainer>
                                    </Typography>
                                  </AccordionDetails>
                                </Accordion>
                              )}
                          </Box>
                        </Grid>
                      </GridContainer>
                    </CmtCardContent>
                  </CmtCard>
                )}

                {this.state.opMode != 1 && onRequest == 0 && (
                  <CmtCard className={classes.card}>
                    <CmtCardContent className={classes.cardContent}>
                      <GridContainer>
                        <Grid xs={12}>
                          <Box className={classes.cardContentInner}>
                            <Typography component="label" className={classes.title + ' wbpTitle'}>
                              <IntlMessages id="_PAYMENT_METHOD" />
                            </Typography>
                          </Box>
                          {bankinfo != '' && (
                            <>
                              <Box className={classes.cardContentInner}>
                                <Typography className={classes.legal}>{bankinfo}</Typography>
                              </Box>
                              {amountToPay > 0 && (
                                <>
                                  <Box className={classes.cardContentInner}>
                                    <Typography className={classes.legal}>
                                      <IntlMessages id="amountToPay" />
                                      {': '}
                                      {this.getFormated(amountToPay)}
                                    </Typography>
                                  </Box>
                                </>
                              )}
                            </>
                          )}
                          {bankinfo == '' &&
                            this.state.selPayment != 'contract' &&
                            this.state.payments.filter(
                              function(pitem) {
                                return pitem.hotelId == this.state.items[0].hotelId;
                              }.bind(this),
                            ).length > 1 && (
                              <Box className={classes.cardContentInner}>
                                <RadioGroup
                                  aria-label="gender"
                                  name="gender1"
                                  value={this.state.selPayment}
                                  onChange={event => this.handleChange('selPayment', event.target.value)}>
                                  {this.state.payments
                                    .filter(
                                      function(pitem) {
                                        return pitem.hotelId == this.state.items[0].hotelId;
                                      }.bind(this),
                                    )
                                    .map((pitem, pindex) => (
                                      <FormControlLabel
                                        value={pitem.id}
                                        control={<Radio />}
                                        label={this.getPayment(pitem.id)}
                                      />
                                    ))}
                                </RadioGroup>
                              </Box>
                            )}

                          {(this.state.selPayment == 'wspay' ||
                            this.state.selPayment == 'monri' ||
                            this.state.selPayment == 'stripe' ||
                            this.state.selPayment == 'teya') && (
                            <Box className={classes.cardContentInner}>
                              <GridContainer spacing={10}>
                                <Grid item xs={12} sm={6} xl={6}>
                                  <div className={classes.CCs}>
                                    {cc4 == 1 && (
                                      <a href="https://www.visaeurope.com/" target="_blank" rel="noreferrer">
                                        <img
                                          src="https://booking.webbookingpro.com/images/Visa50.gif"
                                          className={classes.CCImage}
                                        />
                                        <img
                                          src="https://booking.webbookingpro.com/images/visa-secure.png"
                                          className={classes.CCImage}
                                        />
                                      </a>
                                    )}

                                    {cc2 == 1 && (
                                      <a href="https://www.mastercard.com/" target="_blank" rel="noreferrer">
                                        <img
                                          src="https://booking.webbookingpro.com/images/MasterCard.png"
                                          className={classes.CCImage}
                                        />
                                        <img
                                          src="https://booking.webbookingpro.com/images/mastercard-identity-check.png"
                                          className={classes.CCImage}
                                        />
                                      </a>
                                    )}
                                    {cc3 == 1 && (
                                      <a
                                        href="https://brand.mastercard.com/brandcenter/more-about-our-brands.htm"
                                        target="_blank"
                                        rel="noreferrer">
                                        <img
                                          src="https://booking.webbookingpro.com/images/Maestro.png"
                                          className={classes.CCImage}
                                        />
                                      </a>
                                    )}
                                    {cc5 == 1 && (
                                      <a href="https://www.dinersclub.com/" target="_blank" rel="noreferrer">
                                        <img
                                          src="https://booking.webbookingpro.com/images/DinersClub.png"
                                          className={classes.CCImage}
                                        />
                                      </a>
                                    )}
                                    {cc1 == 1 && (
                                      <a href="https://www.americanexpress.com/" target="_blank" rel="noreferrer">
                                        <img
                                          src="https://booking.webbookingpro.com/images/Amex.png"
                                          className={classes.CCImage}
                                        />
                                      </a>
                                    )}
                                    {cc6 == 1 && (
                                      <a href="https://www.global.jcb/" target="_blank" rel="noreferrer">
                                        {' '}
                                        <img
                                          src="https://booking.webbookingpro.com/images/JCB.png"
                                          className={classes.CCImage}
                                        />
                                      </a>
                                    )}
                                    {cc7 == 1 && (
                                      <a href="https://www.unionpayintl.com/" target="_blank" rel="noreferrer">
                                        <img
                                          src="https://booking.webbookingpro.com/images/ChinaUnionPay.png"
                                          className={classes.CCImage}
                                        />
                                      </a>
                                    )}
                                    {cc8 == 1 && (
                                      <a href="https://pay.google.com/" target="_blank" rel="noreferrer">
                                        <img
                                          src="https://booking.webbookingpro.com/images/googlepay.png"
                                          className={classes.CCImage}
                                        />
                                      </a>
                                    )}
                                    {cc9 == 1 && (
                                      <a href="https://www.apple.com/apple-pay/" target="_blank" rel="noreferrer">
                                        <img
                                          src="https://booking.webbookingpro.com/images/applepay.png"
                                          className={classes.CCImage}
                                        />
                                      </a>
                                    )}
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={6} xl={6}></Grid>
                                <Grid item xs={12} sm={6} xl={6}>
                                  {this.state.selPayment == 'wspay' && (
                                    <a
                                      href="http://www.wspay.info"
                                      title="Monri WSpay - Web Secure Payment Gateway"
                                      target="_blank"
                                      rel="noreferrer">
                                      <img
                                        alt="Monri WSpay - Web Secure Payment Gateway"
                                        src="https://www.wspay.info/payment-info/wsPayWebSecureLogo-118x50-transparent.png"
                                        border="0"
                                      />
                                    </a>
                                  )}
                                  {this.state.selPayment == 'teya' && (
                                    <a href="http://www.teya.com" title="Payment by Teya" target="_blank" rel="noreferrer">
                                      <img alt="Teya" src="https://booking.webbookingpro.com/images/teya.png" border="0" />
                                    </a>
                                  )}
                                  {this.state.selPayment == 'stripe' && (
                                    <a
                                      href="http://www.stripe.com"
                                      title="Payment by Stripe"
                                      target="_blank"
                                      rel="noreferrer">
                                      <img alt="Teya" src="https://booking.webbookingpro.com/images/stripe.png" border="0" />
                                    </a>
                                  )}
                                  {this.state.selPayment == 'monri' && (
                                    <a
                                      href="http://monri.com"
                                      title="MONRI PAYMENTS – PAYMENT SERVICE PROVIDER (PSP)"
                                      target="_blank"
                                      rel="noreferrer">
                                      <img
                                        alt="MONRI PAYMENTS – PAYMENT SERVICE PROVIDER (PSP)"
                                        src="https://booking.webbookingpro.com/images/monri.png"
                                        border="0"
                                        height="75"
                                      />
                                    </a>
                                  )}
                                </Grid>
                                <Grid item xs={12} sm={6} xl={6}></Grid>
                              </GridContainer>
                            </Box>
                          )}
                          <Box className={classes.cardContentInner}>{this.getPaymentInfo()}</Box>

                          {this.state.selPayment == 'online' && (
                            <Box className={classes.cardContentInner}>
                              <GridContainer spacing={10}>
                                <Grid item xs={12} sm={6} xl={6}>
                                  <TextField
                                    error={this.state.creditCardHolder != null && this.state.creditCardHolder.length < 2}
                                    helperText={
                                      this.state.creditCardHolder != null && this.state.creditCardHolder.length < 2 ? (
                                        <IntlMessages id="_CC_CARD_HOLDER_NAME_EMPTY" />
                                      ) : (
                                        ''
                                      )
                                    }
                                    id="priceModel"
                                    name="priceModel"
                                    label=<IntlMessages id="_CREDIT_CARD_HOLDER_NAME" />
                                    size="small"
                                    inputProps={{ className: classes.wbpInput }}
                                    variant="filled"
                                    margin="small"
                                    fullWidth
                                    inputRef={el => (this.creditCardHolder = el)}
                                    value={this.state.creditCardHolder}
                                    onChange={event => this.handleChange('creditCardHolder', event.target.value)}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={6}>
                                  <TextField
                                    error={this.state.creditCard != null && this.state.creditCard.length < 12}
                                    helperText={
                                      this.state.creditCard != null && this.state.creditCard.length < 12 ? (
                                        <IntlMessages id="_CC_CARD_INVALID_FORMAT" />
                                      ) : (
                                        ''
                                      )
                                    }
                                    id="priceModel"
                                    name="priceModel"
                                    inputProps={{ className: classes.wbpInput }}
                                    label=<IntlMessages id="_CREDIT_CARD_NUMBER" />
                                    size="small"
                                    margin="small"
                                    fullWidth
                                    variant="filled"
                                    inputRef={el => (this.creditCard = el)}
                                    value={this.state.creditCard}
                                    onChange={event => this.handleChange('creditCard', event.target.value)}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} xl={6}>
                                  <TextField
                                    id="priceModel"
                                    name="priceModel"
                                    label=<IntlMessages id="_CREDIT_CARD_TYPE" />
                                    size="small"
                                    margin="small"
                                    fullWidth
                                    select
                                    inputProps={{ className: classes.wbpInput }}
                                    value={this.state.creditCardType}
                                    variant="filled"
                                    onChange={event => this.handleChange('creditCardType', event.target.value)}>
                                    {cc4 == 1 && <MenuItem value="Visa">Visa</MenuItem>}
                                    {cc2 == 1 && <MenuItem value="Mastercard">Mastercard</MenuItem>}
                                    {cc3 == 1 && <MenuItem value="Maestro">Maestro</MenuItem>}
                                    {cc5 == 1 && <MenuItem value="Diners">Diners</MenuItem>}
                                    {cc1 == 1 && <MenuItem value="American Express">American Express</MenuItem>}
                                  </TextField>
                                </Grid>
                                <Grid item xs={6} sm={2} xl={2}>
                                  <TextField
                                    id="priceModel"
                                    name="priceModel"
                                    label=<IntlMessages id="_MONTH" />
                                    size="small"
                                    margin="small"
                                    fullWidth
                                    variant="filled"
                                    select
                                    inputProps={{ className: classes.wbpInput }}
                                    value={this.state.creditCardExpireMonth}
                                    onChange={event => this.handleChange('creditCardExpireMonth', event.target.value)}>
                                    <MenuItem value="1">01</MenuItem>
                                    <MenuItem value="2">02</MenuItem>
                                    <MenuItem value="3">03</MenuItem>
                                    <MenuItem value="4">04</MenuItem>
                                    <MenuItem value="5">05</MenuItem>
                                    <MenuItem value="6">06</MenuItem>
                                    <MenuItem value="7">07</MenuItem>
                                    <MenuItem value="8">08</MenuItem>
                                    <MenuItem value="9">09</MenuItem>
                                    <MenuItem value="10">10</MenuItem>
                                    <MenuItem value="11">11</MenuItem>
                                    <MenuItem value="12">12</MenuItem>
                                  </TextField>
                                </Grid>
                                <Grid item xs={6} sm={2} xl={2}>
                                  <TextField
                                    id="priceModel"
                                    name="priceModel"
                                    label=<IntlMessages id="_YEAR" />
                                    size="small"
                                    variant="filled"
                                    margin="small"
                                    inputProps={{ className: classes.wbpInput }}
                                    fullWidth
                                    select
                                    value={this.state.creditCardExpireYear}
                                    onChange={event => this.handleChange('creditCardExpireYear', event.target.value)}>
                                    {years.map(i => (
                                      <MenuItem value={i}>{i}</MenuItem>
                                    ))}
                                  </TextField>
                                </Grid>
                                <Grid item xs={12} sm={2} xl={2}>
                                  {cvc == 1 && (
                                    <TextField
                                      error={this.state.creditCardCVV != null && this.state.creditCardCVV.length < 3}
                                      helperText={
                                        this.state.creditCardCVV != null && this.state.creditCardCVV.length < 3 ? (
                                          <IntlMessages id="_CC_CARD_NO_CVV_NUMBER" />
                                        ) : (
                                          ''
                                        )
                                      }
                                      id="priceModel"
                                      name="priceModel"
                                      label=<IntlMessages id="_CVV_CODE" />
                                      size="small"
                                      variant="filled"
                                      margin="small"
                                      inputProps={{ className: classes.wbpInput }}
                                      fullWidth
                                      value={this.state.creditCardCVV}
                                      inputRef={el => (this.creditCardCVV = el)}
                                      onChange={event => this.handleChange('creditCardCVV', event.target.value)}
                                    />
                                  )}
                                </Grid>
                                <Grid item xs={12} sm={12} xl={12}>
                                  <div className={classes.CCs}>
                                    {cc4 == 1 && (
                                      <img
                                        src="https://booking.webbookingpro.com/images/Visa.png"
                                        className={classes.CCImage}
                                      />
                                    )}
                                    {cc2 == 1 && (
                                      <img
                                        src="https://booking.webbookingpro.com/images/MasterCard.png"
                                        className={classes.CCImage}
                                      />
                                    )}
                                    {cc3 == 1 && (
                                      <img
                                        src="https://booking.webbookingpro.com/images/Maestro.png"
                                        className={classes.CCImage}
                                      />
                                    )}
                                    {cc5 == 1 && (
                                      <img
                                        src="https://booking.webbookingpro.com/images/DinersClub.png"
                                        className={classes.CCImage}
                                      />
                                    )}
                                    {cc1 == 1 && (
                                      <img
                                        src="https://booking.webbookingpro.com/images/Amex.png"
                                        className={classes.CCImage}
                                      />
                                    )}
                                    {cc6 == 1 && (
                                      <img
                                        src="https://booking.webbookingpro.com/images/JCB.png"
                                        className={classes.CCImage}
                                      />
                                    )}
                                    {cc7 == 1 && (
                                      <img
                                        src="https://booking.webbookingpro.com/images/ChinaUnionPay.png"
                                        className={classes.CCImage}
                                      />
                                    )}
                                  </div>
                                </Grid>
                              </GridContainer>
                            </Box>
                          )}
                        </Grid>
                      </GridContainer>
                    </CmtCardContent>
                  </CmtCard>
                )}
                {onRequest == 0 && (
                  <CmtCard className={classes.card}>
                    <CmtCardContent className={classes.cardContent}>
                      <GridContainer>
                        <Grid xs={12}>
                          <Box className={classes.cardContentInner}>
                            <Typography component="label" className={classes.title + ' wbpTitle'}>
                              <IntlMessages id="_LEGAL_DISCLAIMER" />
                            </Typography>
                          </Box>
                          <Box className={classes.cardContentInner}>
                            <Typography className={classes.legal}>
                              {addLegal}
                              {Array.isArray(this.state.policies) &&
                                this.state.policies.map(
                                  function(item) {
                                    var selected = this.state.items.findIndex(
                                      ({ policyId, hotelId }) => policyId == item.id && hotelId == item.hotelId,
                                    );
                                    if (selected >= 0) {
                                      return <>{parse(item.description[locale])}</>;
                                    }
                                  }.bind(this),
                                )}
                            </Typography>
                          </Box>
                          <Box className={classes.cardContentInner}>
                            {this.state.properties.map(
                              function(item) {
                                var selected = this.state.items.findIndex(({ hotelId }) => hotelId == item.id);
                                if (selected >= 0 && item.privacy[locale] != '') {
                                  return (
                                    <Button onClick={() => this.setState({ showPrivacy: true })}>
                                      <IntlMessages id="_PRIVACY_POLICY" />
                                    </Button>
                                  );
                                }
                              }.bind(this),
                            )}
                          </Box>
                        </Grid>
                      </GridContainer>
                    </CmtCardContent>
                  </CmtCard>
                )}
              </>
            )}

            {this.state.activeStep == 2 && (
              <>
                <CmtCard className={classes.card}>
                  <CmtCardContent className={classes.cardContent}>
                    <GridContainer>
                      {this.state.opMode == 1 && (
                        <Grid xs={12}>
                          <Box className={classes.cardContentInner}>
                            <MyAlert severity="success">
                              <IntlMessages id="_BOOKING_WAS_CANCELED_MSG" />
                            </MyAlert>
                            <Box className={classes.cardContentInner}>
                              <IntlMessages id="_BOOKING_NUMBER" /> {this.state.bookingId}
                            </Box>
                          </Box>
                        </Grid>
                      )}
                      {this.state.opMode == 0 && (
                        <Grid xs={12}>
                          <Box className={classes.cardContentInner}>
                            {this.state.errorCode == 0 && (
                              <MyAlert severity="success">
                                <IntlMessages id="_BOOKING_WAS_COMPLETED_MSG" />
                              </MyAlert>
                            )}
                            {this.state.errorCode == 1 && (
                              <MyAlert severity="error">
                                <IntlMessages id="_ORDER_ERROR" />
                                <br />
                                <IntlMessages id="_LEGEND_PAYMENT_ERROR" />
                              </MyAlert>
                            )}
                          </Box>
                          {this.state.errorCode == 0 && (
                            <Box className={classes.cardContentInner}>
                              <IntlMessages id="_BOOKING_NUMBER" /> {this.state.bookingNumber}
                            </Box>
                          )}
                          {this.state.errorCode == 0 && (
                            <Box className={classes.cardContentInner}>
                              <IntlMessages id="bookingSuccessMessage2" /> {this.state.hotelEmail}
                            </Box>
                          )}
                          <Box className={classes.cardContentInner}>
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              onClick={() => this.handleSubmit('RunAgain')}>
                              <IntlMessages id="createAnotherReservation" />
                            </Button>
                          </Box>
                        </Grid>
                      )}
                    </GridContainer>
                  </CmtCardContent>
                </CmtCard>
              </>
            )}
            {(this.state.activeStep == 1 || (this.state.activeStep == 0 && this.state.items.length > 0)) && (
              <Grid container spacing={20}>
                <Grid xl={6} sm={6} xs={12}>
                  <Box className={classes.cardContentInner}>
                    {this.state.activeStep == -1 && (
                      <Button variant="contained" color="primary" size="large" onClick={() => this.handleSubmit('Print')}>
                        <IntlMessages id="print" />
                      </Button>
                    )}
                  </Box>
                </Grid>
                <Grid xl={6} sm={6} xs={12} container justify="flex-end">
                  <Box className={classes.cardContentInner}>
                    {bankinfo == '' && this.state.activeStep == 5 && (
                      <Button
                        variant="contained"
                        disabled={this.state.loading == true}
                        color="primary"
                        size="large"
                        onClick={() => this.handleSubmit('Next')}>
                        {this.state.activeStep == 0 && <IntlMessages id="_NEXT" />}
                        {this.state.activeStep == 1 && this.state.opMode == 0 && <IntlMessages id="confirmReservation" />}
                        {this.state.activeStep == 1 && this.state.opMode == 1 && <IntlMessages id="cancelReservation" />}
                        {this.state.activeStep == 1 && this.state.opMode == 2 && <IntlMessages id="initiatePayment" />}
                      </Button>
                    )}
                    {this.state.activeStep == 1 && this.state.opMode == 0 && this.state.bookingId != null && (
                      <Button
                        variant="contained"
                        style={{ marginLeft: 10 }}
                        disabled={this.state.loading == true}
                        color="primary"
                        size="large"
                        onClick={() => {
                          this.setState({ opMode: 1 }, () => {
                            this.handleSubmit('Next');
                          });
                        }}>
                        <IntlMessages id="cancelReservation" />
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
          {showRight == 1 && (
            <Grid item xs={12} sm={4} xl={4}>
              {this.state.activeStep != 2 && this.state.loading == false && (
                <CmtCard className={classes.card2}>
                  <CmtCardContent className={classes.cardContent}>
                    <Box className={classes.cardContentInner}>
                      {this.state.items.length > 0 && (
                        <>
                          <Typography component="label" className={classes.title + ' wbpTitle ' + classes.titleSize25}>
                            {onRequest == 0 && <IntlMessages id="_SUMMARY" />}
                            {onRequest == 1 && <IntlMessages id="_QUERY" />}
                          </Typography>
                          <br />
                          <Typography
                            component="label"
                            className={classes.title2hotel + ' wbpTitleHotel ' + classes.titleSize20}>
                            {this.state.items.length > 0 &&
                              this.state.items[0].roomId != null &&
                              this.getDestination(this.state.items[0].roomId, true)}
                            {this.state.items.length > 0 &&
                              this.state.items[0].treatmentId != null &&
                              this.getDestination(this.state.items[0].treatmentId, true, true)}
                          </Typography>
                          <br />
                          {this.state.properties.length > 1 && (
                            <>
                              <Typography
                                component="label"
                                className={classes.title2hotel + ' wbpTitleHotel ' + classes.titleSize15}>
                                {this.state.items.length > 0 &&
                                  this.state.items[0].roomId != null &&
                                  this.getDestination(this.state.items[0].roomId, false)}
                                {this.state.items.length > 0 &&
                                  this.state.items[0].treatmentId != null &&
                                  this.getDestination(this.state.items[0].treatmentId, false, true)}
                              </Typography>
                              <br />
                            </>
                          )}
                          <br />
                        </>
                      )}
                      <Typography component="label" className={classes.title2dates + ' wbpTitleDates'}>
                        {this.state.disableCheckOut == 1 && <>{getMyDate2(this.state.date1, locale)}</>}
                        {this.state.disableCheckOut == 0 && this.state.bookingMode == 'room' && (
                          <>
                            <Grid container spacing={5}>
                              <Grid
                                item
                                xs={6}
                                xl={6}
                                md={6}
                                className={classes.boxMiddle}
                                onClick={() => this.toggleView(3)}>
                                <IntlMessages id="_CHECK_IN" />
                                <br />
                                <div className={classes.dateBox}>
                                  <Typography component="span" className={classes.dateBox1}>
                                    {getMyDate2(this.state.date1, locale, 1)}
                                  </Typography>
                                  <Typography component="span" className={classes.dateBox2}>
                                    {getMyDate2(this.state.date1, locale, 2)}
                                  </Typography>
                                  <Typography component="span" className={classes.dateBox1}>
                                    {getMyDate2(this.state.date1, locale, 3)}
                                  </Typography>
                                  <Typography component="span" className={classes.dateBox4}>
                                    {this.state.items.length > 0 && this.getTime(this.state.items[0].roomId, false)}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                xl={6}
                                md={6}
                                className={classes.boxMiddle}
                                onClick={() => this.toggleView(3)}>
                                <IntlMessages id="_CHECK_OUT" />
                                <br />
                                <div className={classes.dateBox}>
                                  <Typography component="span" className={classes.dateBox1}>
                                    {getMyDate2(this.state.date2, locale, 1)}
                                  </Typography>
                                  <Typography component="span" className={classes.dateBox2}>
                                    {getMyDate2(this.state.date2, locale, 2)}
                                  </Typography>
                                  <Typography component="span" className={classes.dateBox1}>
                                    {getMyDate2(this.state.date2, locale, 3)}
                                  </Typography>
                                  <Typography component="span" className={classes.dateBox4}>
                                    {this.state.items.length > 0 && this.getTime(this.state.items[0].roomId, true)}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} xl={6} md={6} className={classes.boxMiddle}>
                                <IntlMessages id="_NIGHTS" />
                                <div className={classes.dateBox}>
                                  <Typography component="span" className={classes.dateBox3}>
                                    <NightsStayIcon fontSize="small" />
                                    {this.state.nights > 0 && this.state.nights}
                                  </Typography>
                                </div>
                              </Grid>
                              <Grid item xs={6} xl={6} md={6} className={classes.boxMiddle}>
                                <IntlMessages id="_TOTALGUESTS" />
                                <div className={classes.dateBox}>
                                  <Typography component="span" className={classes.dateBox3}>
                                    <PersonIcon fontSize="small" />
                                    {this.state.items.length > 0 && <>{totalAdults + totalChildren}</>}
                                    {this.state.items.length == 0 && <>{this.state.adults + this.state.children}</>}
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </Typography>
                    </Box>
                    <Box className={classes.cardContentInner}>
                      {this.state.items.length == 0 && (
                        <>
                          <Grid container spacing={2}>
                            {this.state.disableCheckOut == 1 && (
                              <Grid xl={12} sm={12} xs={12} item>
                                {getMyDate2(this.state.date1, locale)}
                                <br />
                              </Grid>
                            )}

                            <Grid xl={12} sm={12} xs={12} item>
                              <MyAlert severity="warning">
                                <IntlMessages id="pleaseSelectUnit" />
                              </MyAlert>
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {this.state.items.length > 0 && (
                        <>
                          {this.state.items.map((item, index) => (
                            <CmtCard className={classes.card3}>
                              <CmtCardContent className={classes.cardContent2}>
                                <Grid container spacing={1}>
                                  {this.state.bookingMode == 'room' && this.state.items.length > 1 && (
                                    <Grid xl={12} sm={12} xs={12} item>
                                      <b>
                                        <IntlMessages id="_UNIT" />
                                        {' #'}
                                        {index + 1}
                                      </b>
                                    </Grid>
                                  )}

                                  <Grid xl={12} sm={12} xs={12} item>
                                    <Typography
                                      component="label"
                                      className={classes.title + ' wbpTitle ' + classes.titleSize15}>
                                      {item.count}
                                      {' x '}
                                      {item.roomId != null && this.getRoom(item.roomId)}
                                      {item.unitName != null && ': ' + item.unitName}
                                      {item.treatmentId != null && (
                                        <>
                                          {this.state.treatments.find(({ id }) => item.treatmentId == id).title[locale]}
                                          <br />
                                          {item.datetime != null ? (
                                            getMyDate3(item.datetime, locale)
                                          ) : (
                                            <IntlMessages id="noSelectedTime" />
                                          )}
                                          {item.datetime != null && ' ('}
                                          {item.datetime != null &&
                                            this.state.treatmentsPersons.filter(({ id }) => id == item.personId)[0].name}
                                          {item.datetime != null && ')'}
                                        </>
                                      )}
                                    </Typography>
                                    <br />
                                    <br />
                                    <Typography component="label" className={classes.title2dates2 + ' wbpTitleDates'}>
                                      {this.state.bookingMode == 'room' && (
                                        <>
                                          {item.serviceId != null &&
                                            item.serviceId != 0 &&
                                            this.state.services.find(({ id }) => item.serviceId == id).title[locale]}{' '}
                                          {item.packageId != null &&
                                            this.state.packages.find(({ id }) => item.packageId == id).title[locale]}{' '}
                                          {(item.serviceId === 0 || item.serviceId == null) &&
                                            item.rateId != null &&
                                            item.rateId != 0 &&
                                            this.state.rates.find(({ id }) => item.rateId == id).title[locale]}{' '}
                                        </>
                                      )}
                                    </Typography>
                                  </Grid>
                                  {this.state.disableGuests == 0 && this.state.bookingMode == 'room' && (
                                    <Grid xl={6} sm={6} xs={6} item>
                                      <Typography component="label" className={classes.title2dates2 + ' wbpTitleDates'}>
                                        <IntlMessages id="_GUESTS" />
                                      </Typography>
                                    </Grid>
                                  )}
                                  {this.state.disableGuests == 0 && this.state.bookingMode == 'room' && (
                                    <Grid xl={6} sm={6} xs={6} item>
                                      <Typography component="label" className={classes.title2dates2 + ' wbpTitleDates'}>
                                        {item.adults}{' '}
                                        {item.adults == 1 ? <IntlMessages id="_ADULT" /> : <IntlMessages id="_ADULTS" />}
                                        {item.children > 0 && (
                                          <>
                                            , {item.children}{' '}
                                            {item.children == 1 ? (
                                              <IntlMessages id="_CHILD" />
                                            ) : (
                                              <IntlMessages id="_CHILDREN" />
                                            )}
                                            {' ('}
                                            {item.childAge}
                                            {')'}
                                          </>
                                        )}
                                      </Typography>
                                    </Grid>
                                  )}
                                  {this.state.bookingMode == 'room' && onRequest == 0 && (
                                    <>
                                      <Grid xl={6} sm={6} xs={6} item>
                                        <Typography component="label" className={classes.title2dates2 + ' wbpTitleDates'}>
                                          <IntlMessages id="_ROOM_PRICE" />
                                        </Typography>
                                      </Grid>
                                      <Grid xl={6} sm={6} xs={6} item>
                                        <Typography component="label" className={classes.title2dates2 + ' wbpTitleDates'}>
                                          {this.getFormated(item.priceFinal * item.count)}
                                        </Typography>
                                      </Grid>
                                      {item.taxes > 0 && (
                                        <>
                                          <Grid xl={6} sm={6} xs={6} item>
                                            <Typography
                                              component="label"
                                              className={classes.title2dates2 + ' wbpTitleDates'}>
                                              <IntlMessages id="_GUEST_TAX" />
                                            </Typography>
                                          </Grid>

                                          <Grid xl={6} sm={6} xs={6} item>
                                            <Typography
                                              component="label"
                                              className={classes.title2dates2 + ' wbpTitleDates'}>
                                              {this.getFormated(item.count * item.taxes)}
                                            </Typography>
                                          </Grid>
                                        </>
                                      )}
                                    </>
                                  )}

                                  {this.state.bookingId == null && isWBPOffer == 0 && (
                                    <Grid
                                      xl={12}
                                      sm={12}
                                      xs={12}
                                      container
                                      justifyItems="flex-end"
                                      justifyContent="flex-end"
                                      alignItems="cneter">
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          this.handleSubmit(item.treatmentId == null ? 'Remove' : 'Remove2', item)
                                        }>
                                        <DeleteIcon />
                                      </IconButton>
                                    </Grid>
                                  )}
                                </Grid>
                              </CmtCardContent>
                            </CmtCard>
                          ))}

                          {this.state.extrasSel.length > 0 && (
                            <>
                              <Grid container spacing={2}>
                                <Grid xl={6} sm={6} xs={6} item>
                                  <Typography component="label" className={classes.title2dates + ' wbpTitleDates'}>
                                    <IntlMessages id="_BOOKING_SUBTOTAL" />
                                  </Typography>
                                </Grid>
                                <Grid xl={6} sm={6} xs={6} item>
                                  <Typography component="label" className={classes.title2dates + ' wbpTitleDates'}>
                                    {this.getFormated(totalRoom)}
                                  </Typography>
                                </Grid>
                                <Grid xl={6} sm={6} xs={6} item>
                                  <Typography component="label" className={classes.title2dates + ' wbpTitleDates'}>
                                    <IntlMessages id="_EXTRAS" />
                                  </Typography>
                                </Grid>
                                <Grid xl={6} sm={6} xs={6} item>
                                  {this.state.extrasSel.map(item => (
                                    <>
                                      <Typography component="label" className={classes.title2dates + ' wbpTitleDates'}>
                                        {item.count}
                                        {' x '}
                                        {this.state.extras.find(({ id }) => item.extraId == id).title[locale]}{' '}
                                        {this.getFormated(this.getExtraPrice(item.extraId, this.state.date1))}
                                        <br />
                                      </Typography>
                                    </>
                                  ))}
                                </Grid>
                              </Grid>
                            </>
                          )}

                          {totalExtras > 0 && (
                            <Grid container spacing={2}>
                              <Grid xl={6} sm={6} xs={6} item>
                                <Typography component="label" className={classes.title2dates + ' wbpTitleDates'}>
                                  <IntlMessages id="_EXTRAS_SUBTOTAL" />
                                </Typography>
                              </Grid>
                              <Grid xl={6} sm={6} xs={6} item>
                                <Typography component="label" className={classes.title2dates + ' wbpTitleDates'}>
                                  {this.getFormated(totalExtras)}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </>
                      )}
                      {lD != 0 && onRequest == 0 && (
                        <>
                          <Grid container spacing={2}>
                            <Grid xl={6} sm={6} xs={6} item>
                              <Typography className={classes.title + ' wbpTitle ' + classes.titleSize25}>
                                <IntlMessages id="loyaltyDiscount" />
                              </Typography>
                            </Grid>
                            <Grid xl={6} sm={6} xs={6} item classname={classes.rateReserve}>
                              <Typography className={classes.title + ' wbpTitle ' + classes.titleSize25}>
                                {this.getFormated(-1 * lD)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      )}
                      <Grid container spacing={2}>
                        {this.state.items.length > 0 && onRequest == 0 && (
                          <Grid xl={6} sm={6} xs={6} item>
                            <Typography className={classes.title + ' wbpTitle ' + classes.titleSize25}>
                              <IntlMessages id="_TOTAL" />
                            </Typography>
                          </Grid>
                        )}
                        {this.state.items.length > 0 && onRequest == 0 && (
                          <Grid xl={6} sm={6} xs={6} item classname={classes.rightAlign}>
                            <Typography className={classes.title + ' wbpTitle ' + classes.titleSize25}>
                              {this.getFormated(total - lD)}
                            </Typography>
                          </Grid>
                        )}

                        {this.state.opMode == 2 && (
                          <Grid xl={6} sm={6} xs={6} item>
                            <Typography className={classes.title + ' wbpTitle ' + classes.titleSize25}>
                              <IntlMessages id="amountToPay" />
                            </Typography>
                          </Grid>
                        )}
                        {this.state.opMode == 2 && (
                          <Grid xl={6} sm={6} xs={6} item classname={classes.rateReserve}>
                            <Typography className={classes.title + ' wbpTitle ' + classes.titleSize25}>
                              {this.getFormated(amountToPay)}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      {this.state.activeStep != 0 && this.state.bookingId == null && isWBPOffer == 0 && (
                        <>
                          <br />
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            fullWidth
                            startIcon={<AddIcon />}
                            onClick={() => this.handleSubmit('Back')}>
                            {this.state.bookingMode == 'room' && propertyType == 1 && <IntlMessages id="_ADD_ROOM" />}
                            {this.state.bookingMode == 'room' && propertyType != 1 && <IntlMessages id="_ADD_UNIT" />}
                            {this.state.bookingMode == 'wellness' && <IntlMessages id="_ADD_TREATMENT" />}
                          </Button>
                          {onRequest == 1 && <br />}
                          {onRequest == 1 && <br />}
                        </>
                      )}
                      {this.state.activeStep == 1 &&
                        this.state.properties.find(({ id }) => id == propertyId).newsletterCheck == 1 && (
                          <>
                            <br />
                            <Box className={classes.cardContentInner}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.check2}
                                    onChange={event => this.handleChange('check2', event.target.checked)}
                                  />
                                }
                                label=<IntlMessages id="allowNewsletter" />
                              />
                            </Box>
                          </>
                        )}

                      {this.state.activeStep == 1 && onRequest == 0 && (
                        <>
                          <br />
                          <Box className={classes.cardContentInner}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.check1}
                                  onChange={event => this.handleChange('check1', event.target.checked)}
                                />
                              }
                              label=<IntlMessages id="_AGREE_CONF_TEXT" />
                            />
                          </Box>
                        </>
                      )}
                      {(this.state.activeStep == 1 || (this.state.activeStep == 0 && this.state.items.length > 0)) && (
                        <>
                          {bankinfo == '' && (
                            <Button
                              variant="contained"
                              disabled={this.state.loading == true}
                              color="primary"
                              size="large"
                              fullWidth
                              onClick={() => this.handleSubmit('Next')}>
                              {this.state.activeStep == 0 && <IntlMessages id="_NEXT" />}
                              {this.state.activeStep == 1 && this.state.opMode == 0 && onRequest == 0 && (
                                <IntlMessages id="confirmReservation" />
                              )}
                              {this.state.activeStep == 1 && this.state.opMode == 0 && onRequest == 1 && (
                                <IntlMessages id="sendInquiry" />
                              )}
                              {this.state.activeStep == 1 && this.state.opMode == 1 && (
                                <IntlMessages id="cancelReservation" />
                              )}
                              {this.state.activeStep == 1 && this.state.opMode == 2 && <IntlMessages id="initiatePayment" />}
                            </Button>
                          )}
                        </>
                      )}
                    </Box>
                  </CmtCardContent>
                </CmtCard>
              )}
            </Grid>
          )}
          {showWBP == 1 && (
            <Grid container xs={12} sm={12} xl={12}>
              <Grid item xl={1} sm={1} xs={1}></Grid>
              <Grid
                xl={10}
                sm={10}
                xs={10}
                container
                justifyItems="flex-end"
                justifyContent="flex-end"
                alignItems="center"
                alignContent="center">
                <span className={classes.pb}>Powered by</span>
                {this.state.general.brandId == 0 && (
                  <a href="https://www.webbookingpro.com/">
                    <img src="https://booking.webbookingpro.com/images/webbookingpro_logo.png" className={classes.logo} />
                  </a>
                )}
                {this.state.general.brandId == 1 && (
                  <a href="https://www.forotel.com/">
                    <img src="https://app.webbookingpro.com/images/zoezoom_logo_header.png" className={classes.logo} />
                  </a>
                )}
              </Grid>
              <Grid item xl={1} sm={1} xs={1}></Grid>
            </Grid>
          )}

          {showFooter == 1 && this.state.properties.length > 0 && (
            <Grid xs={12} item>
              <CmtCard className={classes.card}>
                <CmtCardContent className={classes.cardContent2}>
                  <GridContainer>
                    <Grid xs={12}>{parse(this.state.properties[0].footer)}</Grid>
                  </GridContainer>
                </CmtCardContent>
              </CmtCard>
            </Grid>
          )}
          {this.state.properties.length > 0 && <style>{parse(this.state.properties[0].css)}</style>}
        </Grid>

        <Snackbar open={this.state.showSuccess} autoHideDuration={1000} onClose={this.handleClose}>
          <MyAlert severity="success">This is a success message!</MyAlert>
        </Snackbar>
        {this.state.disableBackdrop == 0 && (
          <Backdrop open={this.state.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {(isMobile || this.state.isSearchAvailable) && this.state.selectedView == 3 && (
          <Dialog
            fullScreen
            open={this.state.selectedView == 3}
            onClose={this.handleClose3}
            style={{ zIndex: 99999 }}
            TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={this.handleClose3} aria-label="close">
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.titleBar}>
                  <IntlMessages id="_CHECK_IN" />
                  {' - '}
                  <IntlMessages id="_CHECK_OUT" />
                </Typography>
              </Toolbar>
            </AppBar>
            {this.state.disableCheckOut == 1 && (
              <DatePicker
                variant="inline"
                renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) =>
                  this.getDayElement(day, selectedDate, isInCurrentMonth, dayComponent)
                }
                format="DD/MM/yyyy"
                margin="small"
                loading={this.state.loadingCalendar}
                id="date1"
                name="date1"
                inputProps={{ className: classes.wbpInput }}
                onMonthChange={day => this.handleMonthChange(day)}
                renderLoading={() => <Skeleton animation="wave" />}
                autoOk="true"
                disablePast="true"
                value={this.state.date1}
                onChange={date => {
                  this.setState(
                    {
                      date1: date,
                      date2: moment(date)
                        .add(1, 'days')
                        .format('YYYY-MM-DD'),
                      items: [],
                    },
                    () => {
                      this.checkDates(true);
                      //this.loadResults();
                    },
                  );
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            )}
            {this.state.disableCheckOut == 0 && (
              <Datepicker
                cssClass="md-book-rental"
                controls={['calendar']}
                select="range"
                touchUi={true}
                display="inline"
                calendarType="month"
                calendarSize={2}
                min={today}
                minRange={this.state.isSearchAvailable == false ? minRange : this.state.searchMinDays + 1}
                maxRange={this.state.isSearchAvailable == false ? maxRange : this.state.searchMaxDays + 1}
                locale={myLocale}
                onPageChange={ev => {
                  this.updateAvailability(myFormatDate(ev.firstDay), this.state.isSearchAvailable);
                }}
                max={maxEnd}
                value={this.state.isSearchAvailable == false ? this.state.selectedDate : this.state.selectedDate2}
                onChange={ev => {
                  console.log(ev.value);
                  if (ev.value[0] != null && ev.value[1] != null) {
                    var startDate = ev.value[0];
                    var run = true;
                    var endDate = ev.value[1];
                    var endDate2 = moment(endDate).format('YYYY-MM-DD');
                    if (userPackage != '')
                      endDate2 = moment(startDate)
                        .add(this.state.minDays, 'days')
                        .format('YYYY-MM-DD');
                    /*if (userRateId != '')
                      endDate2 = moment(startDate)
                        .add(this.state.minDays, 'days')
                        .format('YYYY-MM-DD');*/
                    if (this.state.isSearchAvailable && this.state.selPackageId != null)
                      endDate2 = moment(startDate)
                        .add(this.state.searchMinDays, 'days')
                        .format('YYYY-MM-DD');
                    if (userDisableCheckOut == 1 /*|| endDate == null*/)
                      endDate2 = moment(startDate)
                        .add(1, 'days')
                        .format('YYYY-MM-DD');

                    if (endDate2 == moment(startDate).format('YYYY-MM-DD')) {
                      endDate2 = moment(startDate)
                        .add(1, 'days')
                        .format('YYYY-MM-DD');
                      // run = false;

                      /* if (this.state.available.find(({ d }) => d == endDate3) != null)
                        if (this.state.available.find(({ d }) => d == endDate3).a == 0) endDate2 = endDate3;*/
                    }

                    this.setState(
                      {
                        date1: moment(startDate).format('YYYY-MM-DD'),
                        date2: endDate2,
                        isSearchAvailable: false,
                        selectedDate: [moment(startDate), moment(endDate2)],
                        selectedDate2: [moment(startDate), moment(endDate2)],
                        items: [],
                      },
                      () => {
                        this.checkDates(run);
                        //this.loadResults();
                      },
                    );
                  } else this.setState({ selectedDate: ev.value, selectedDate2: ev.value });
                }}
                showRangeLabels={false}
                inRangeInvalid={false}
                rangeEndInvalid={true}
                invalid={this.state.isSearchAvailable == false ? this.state.cInvalids : this.state.cInvalids2}
                labels={this.state.isSearchAvailable == false ? this.state.cLabels : this.state.cLabels2}
                colors={this.state.isSearchAvailable == false ? this.state.cColors : this.state.cColors2}
              />
            )}
          </Dialog>
        )}
        <Dialog
          className={classes.myDialog}
          fullScreen
          style={{ zIndex: 99999 }}
          open={this.state.showPayment}
          TransitionComponent={Transition}>
          {this.state.showPayment && (
            <iframe
              allowFullScreen
              allowTransparency
              src={this.state.paymentUrl}
              className={classes.miframe}
              frameBorder="0"></iframe>
          )}
        </Dialog>
        <Dialog
          className={classes.myDialog}
          fullScreen
          style={{ zIndex: 99999 }}
          open={this.state.showLegal}
          TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => {
                  this.setState({ showLegal: false });
                }}
                aria-label="close">
                <CloseIcon />
              </IconButton>

              <Typography variant="h6" className={classes.titleBar}>
                <IntlMessages id="_LEGAL_DISCLAIMER" />
              </Typography>
            </Toolbar>
          </AppBar>
          <Box className={classes.boxTop}>
            <PerfectScrollbar className={classes.scrollbarRoot}>
              {Array.isArray(this.state.policies) &&
                this.state.policies.map(
                  function(item) {
                    var selected = this.state.items.findIndex(
                      ({ policyId, hotelId }) => policyId == item.id && hotelId == item.hotelId,
                    );
                    if (selected >= 0) {
                      return <>{parse(item.description[locale])}</>;
                    }
                  }.bind(this),
                )}
              <br />
              <br />

              {this.state.properties.map(
                function(item) {
                  var selected = this.state.items.findIndex(({ hotelId }) => hotelId == item.id);
                  if (selected >= 0) return <>{parse(item.privacy[locale])}</>;
                }.bind(this),
              )}
            </PerfectScrollbar>
          </Box>
          <Box className={classes.boxTop}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                this.setState({ check1: true, showLegal: false });
              }}>
              <IntlMessages id="_AGREE_CONF_TEXT" />
            </Button>
          </Box>
        </Dialog>

        <Dialog
          className={classes.myDialog}
          fullScreen
          style={{ zIndex: 99999 }}
          open={this.state.showPrivacy}
          TransitionComponent={Transition}
          onClose={this.handleClose3}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.handleClose3} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.titleBar}>
                <IntlMessages id="_PRIVACY_POLICY" />
              </Typography>
            </Toolbar>
          </AppBar>
          <Box className={classes.boxTop}>
            {this.state.properties.map(
              function(item) {
                var selected = this.state.items.findIndex(({ hotelId }) => hotelId == item.id);
                if (selected >= 0) return <>{parse(item.privacy[locale])}</>;
              }.bind(this),
            )}
          </Box>
        </Dialog>
        <Dialog open={this.state.selectUnitVisible} onClose={() => this.setState({ selectUnitVisible: false })}>
          <DialogTitle>
            {propertyType == 1 && <IntlMessages id="selectUnit" />}
            {propertyType != 1 && <IntlMessages id="selectPitch" />}
          </DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>
            {this.state.selectUnitUnits.length == 0 && propertyType == 1 && <IntlMessages id="_NO_AVAILABLE_ROOMS" />}
            {this.state.selectUnitUnits.length == 0 && propertyType != 1 && <IntlMessages id="_NO_AVAILABLE_UNITS" />}
            {this.state.selectUnitUnits.length >= 1 && (
              <TextField
                id="priceModel"
                name="priceModel"
                select
                variant="outlined"
                size="small"
                margin="small"
                className={classes.currency}
                value={this.state.selectUnitUnitId}
                onChange={event => this.handleChange('selectUnitUnitId', event.target.value)}>
                {this.state.selectUnitUnits.map((item, index) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </TextField>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                var sitem = JSON.parse(JSON.stringify(this.state.selectUnitData));
                sitem.unitId = this.state.selectUnitUnitId;

                sitem.unitName = this.state.selectUnitUnits.filter(({ id }) => id == sitem.unitId)[0].name;
                this.handleSubmit('Reserve', sitem);
              }}>
              <IntlMessages id="confirm" />
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.filterDialog} onClose={() => this.setState({ filterDialog: false })}>
          <DialogTitle>
            <IntlMessages id="_FILTER" />
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} md={4} xl={4}>
                <DialogContentText>
                  <IntlMessages id="includePropertyTypes" />
                </DialogContentText>
                {this.state.propertytypes.map((item, index) => (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={this.state.selpropertytypes.includes(item)}
                          defaultChecked={this.state.selpropertytypes.includes(item)}
                          onChange={event => this.handleChange('propertyType', event.target.checked, item)}
                        />
                      }
                      label={this.getPropertyType(item)}
                    />
                    <br />
                  </>
                ))}
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <DialogContentText>
                  <IntlMessages id="includedDestinations" />
                </DialogContentText>
                {this.state.destinations.map((item, index) => (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={this.state.seldestinations.includes(item)}
                          defaultChecked={this.state.seldestinations.includes(item)}
                          onChange={event => this.handleChange('destinations', event.target.checked, item)}
                        />
                      }
                      label={item}
                    />
                    <br />
                  </>
                ))}
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <DialogContentText>
                  <IntlMessages id="mustInclude" />
                </DialogContentText>
                {this.state.amenities.map((item, index) => (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={this.state.selamenities.includes(item)}
                          defaultChecked={this.state.selamenities.includes(item)}
                          onChange={event => this.handleChange('amenities', event.target.checked, item)}
                        />
                      }
                      label={intl.formatMessage({ id: 'f.' + item })}
                    />
                    <br />
                  </>
                ))}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState(
                  {
                    pageNum: 1,
                    filterDialog: false,
                  },
                  () => {
                    this.loadResults();
                  },
                )
              }>
              <IntlMessages id="confirm" />
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.personDialog} onClose={() => this.setState({ personDialog: false })}>
          <DialogTitle>
            <IntlMessages id="_GUESTS" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <IntlMessages id="_ROOMSEL1" />
              {roomCount}
              <IntlMessages id="_ROOMSEL2" />
              {roomCount + 1}
              {'.'}
            </DialogContentText>
            <Grid item xl={12} sm={12} xs={12}>
              <Box className={classes.cardContentInner}>
                <Typography component="label" className={classes.title}>
                  <IntlMessages id="_ADULTS" />
                </Typography>
              </Box>
              <Box className={classes.cardContentInner2}>
                <IconButton
                  onClick={() => {
                    this.setState(prevState => {
                      var x = prevState.adultsx;
                      if (x < this.state.general.maxAdults) x = x + 1;
                      return { adultsx: x };
                    });
                  }}>
                  <Add />
                </IconButton>
                <TextField
                  id="priceModel"
                  name="priceModel"
                  inputProps={{ readOnly: true }}
                  variant="outlined"
                  size="small"
                  margin="small"
                  className={classes.combo3}
                  value={this.state.adultsx}
                  onChange={event => this.handleChange('adultsx', event.target.value)}></TextField>
                <IconButton
                  onClick={() => {
                    this.setState(prevState => {
                      var x = prevState.adultsx;
                      if (x > 1) x = x - 1;
                      return { adultsx: x };
                    });
                  }}>
                  <Remove />
                </IconButton>
              </Box>
            </Grid>

            <Grid item xl={12} sm={12} xs={12}>
              {this.state.general.disableChildren == 0 && (
                <Box className={classes.cardContentInner}>
                  <Typography component="label" className={classes.title}>
                    <IntlMessages id="_CHILDREN" />
                  </Typography>
                </Box>
              )}
              {this.state.general.disableChildren == 0 && (
                <Box className={classes.cardContentInner2}>
                  <IconButton
                    onClick={() => {
                      this.setState(prevState => {
                        var x = prevState.childrenx;
                        if (x < this.state.general.maxChildren) x = x + 1;
                        return { childrenx: x };
                      });
                    }}>
                    <Add />
                  </IconButton>
                  <TextField
                    id="priceModel"
                    name="priceModel"
                    inputProps={{ readOnly: true }}
                    variant="outlined"
                    size="small"
                    margin="small"
                    className={classes.combo3}
                    value={this.state.childrenx}
                    onChange={event => this.handleChange('childrenx', event.target.value)}></TextField>
                  <IconButton
                    onClick={() => {
                      this.setState(prevState => {
                        var x = prevState.childrenx;
                        if (x >= 1) x = x - 1;
                        return { childrenx: x };
                      });
                    }}>
                    <Remove />
                  </IconButton>
                </Box>
              )}
            </Grid>
            {this.state.general.disableChildren == 0 && this.state.childrenx > 0 && (
              <Box className={classes.cardContentInner2}>
                <Typography component="label" className={classes.title}>
                  <IntlMessages id="childAges" />
                  <br />
                </Typography>

                {Array(this.state.childrenx)
                  .fill()
                  .map((_, ci) => (
                    <>
                      <IconButton
                        onClick={() => {
                          this.setState(prevState => {
                            var x = [...prevState.childAgex];

                            if (x[ci] <= 16) x[ci] = x[ci] + 1;
                            return { childAgex: x };
                          });
                        }}>
                        <Add />
                      </IconButton>
                      <TextField
                        id="priceModel"
                        name="priceModel"
                        variant="outlined"
                        size="small"
                        margin="small"
                        className={classes.combo3}
                        inputProps={{ readOnly: true }}
                        value={this.state.childAgex[ci]}
                      />

                      <IconButton
                        onClick={() => {
                          this.setState(prevState => {
                            var x = [...prevState.childAgex];

                            if (x[ci] >= 1) x[ci] = x[ci] - 1;
                            return { childAgex: x };
                          });
                        }}>
                        <Remove />
                      </IconButton>
                      <br />
                    </>
                  ))}
                {this.state.general.minChildrenAge != -1 && (
                  <>
                    <br />
                    <Typography component="label" className={classes.title4}>
                      <IntlMessages id="childAge4" />
                      {this.state.general.minChildrenAge + 1}
                      <IntlMessages id="childAge5" />
                      <br />
                    </Typography>
                  </>
                )}
                {this.state.general.maxChildrenAge < 17 && 1 == 2 && (
                  <>
                    <br />
                    <Typography component="label" className={classes.title4}>
                      <IntlMessages id="childAge2" />
                      {this.state.general.maxChildrenAge + 1}
                      <IntlMessages id="childAge3" />
                      <br />
                    </Typography>
                  </>
                )}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                this.setState(
                  {
                    adults: this.state.adultsx,
                    children: this.state.childrenx,
                    childAge: JSON.parse(JSON.stringify(this.state.childAgex)),
                    personDialog: false,
                  },
                  () => {
                    this.loadResults();
                  },
                )
              }>
              <IntlMessages id="confirm" />
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          className={classes.myDialog}
          fullScreen
          style={{ zIndex: 99999 }}
          open={this.state.showSlide}
          onClose={this.handleClose3}
          TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.handleClose3} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.titleBar}>
                {this.state.dialogTitle}
              </Typography>
            </Toolbar>
          </AppBar>
          <GridContainer
            style={{
              textAlign: 'center',
              maxWidth: '100%',
              maxHeight: '100%',
              margin: '0px auto',
            }}>
            <Grid item xs={12} sm={8} xl={8} alignItems="left" style={{ textAlign: 'left' }}>
              <Carousel
                data={this.state.sliderData}
                time={2000}
                width={sliderWidth}
                id={this.state.dialogTitle}
                height={sliderHeight}
                radius="10px"
                slideNumber={false}
                captionPosition="bottom"
                automatic={false}
                dots={true}
                showZoom={false}
                pauseIconColor="white"
                pauseIconSize="40px"
                slideBackgroundColor="darkgrey"
                slideImageFit="cover"
                thumbnails={false}
                onClick={() => {}}
                thumbnailWidth="0px"
                style={{
                  textAlign: 'center',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  margin: '0px auto',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} xl={4} alignItems="left" style={{ textAlign: 'left' }}>
              <Box className={classes.cardContentInner2y}>
                <PerfectScrollbar className={classes.scrollbarRoot} options={{ suppressScrollX: true }}>
                  <Typography variant="h6" className={classes.title + ' wbpTitle'}>
                    {this.state.showRateData && (
                      <>
                        {this.state.selItem.serviceId != 0 &&
                          this.state.services.find(({ id }) => id == this.state.selItem.serviceId).title[locale]}
                        {this.state.selItem.rateId != null &&
                          this.state.rates.find(({ id }) => id == this.state.selItem.rateId).title[locale]}
                      </>
                    )}
                    {!this.state.showRateData && <>{this.state.dialogTitle}</>}
                  </Typography>
                  {this.state.roomData.address != null && this.state.roomData.address != '' && (
                    <Typography className={classes.legal}>
                      <IntlMessages id="_LOCATION" />
                      {': '}
                      {this.state.roomData.address}
                      {', '}
                      {this.state.roomData.zipcode} {this.state.roomData.city}
                      {', '}
                      {this.state.roomData.country}
                      <br />
                    </Typography>
                  )}
                  {this.state.roomData.phone != null && this.state.roomData.phone != '' && (
                    <Typography className={classes.legal}>
                      <IntlMessages id="_PHONE" />
                      {': '}
                      {this.state.roomData.phone}
                      <br />
                    </Typography>
                  )}
                  {this.state.roomData.website != null && this.state.roomData.website != '' && (
                    <Typography className={classes.legal}>
                      <IntlMessages id="_WEB_SITE" />
                      {': '}
                      <a href={this.state.roomData.website} target="_blank" rel="noreferrer">
                        {this.state.roomData.website}
                      </a>
                      <br />
                    </Typography>
                  )}
                  {this.state.roomData.email != null && this.state.roomData.email != '' && (
                    <Typography className={classes.legal}>
                      <IntlMessages id="_EMAIL" />
                      {': '}
                      <a href={'mailto:' + this.state.roomData.email}>{this.state.roomData.email}</a>
                      <br />
                    </Typography>
                  )}
                  {this.state.roomData.video != null && this.state.roomData.video != '' && (
                    <Typography className={classes.legal}>
                      {'Youtube: '}
                      <a href={this.state.roomData.video} target="_blank" rel="noreferrer">
                        {this.state.roomData.video}
                      </a>
                      <br />
                    </Typography>
                  )}

                  {this.state.showPackageData && (
                    <>
                      <br />
                      <Typography variant="h6" className={classes.title + ' wbpTitle'}>
                        <IntlMessages id="_DESCRIPTION" />
                      </Typography>
                      <Typography className={classes.legal}>{parse(this.state.packageData)}</Typography>
                    </>
                  )}
                  {this.state.showRateData && (
                    <>
                      <br />
                      <Typography variant="h6" className={classes.title + ' wbpTitle'}>
                        <IntlMessages id="_DESCRIPTION" />
                      </Typography>
                      <Typography className={classes.legal}>
                        {this.state.selItem.serviceId != 0 &&
                          this.state.services.find(({ id }) => id == this.state.selItem.serviceId).description[locale]}
                        {this.state.selItem.rateId != null &&
                          parse(this.state.rates.find(({ id }) => id == this.state.selItem.rateId).description[locale])}
                      </Typography>
                    </>
                  )}
                  {(this.state.showRateData || this.state.showPackageData) && (
                    <>
                      <br />
                      <Typography variant="h6" className={classes.title + ' wbpTitle'}>
                        <IntlMessages id="_ROOM_DESCRIPTION" />
                      </Typography>
                    </>
                  )}
                  <Typography className={classes.legal}>
                    {this.state.roomData.long_description != null && parse(this.state.roomData.long_description[locale])}
                    {this.state.roomData.long_description != null &&
                      this.state.roomData.long_description[locale] == '' &&
                      this.state.roomData.description[locale]}
                  </Typography>

                  <GridContainer>
                    <Grid item xs={4} sm={4} xl={4} alignItems="baseline">
                      {this.state.roomData.room_area != null &&
                        this.state.roomData.room_area != '' &&
                        this.state.roomData.room_area != '0' &&
                        this.state.roomData.room_area != '0.0' && (
                          <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                            <Launch size="large" />
                            {' ' + this.state.roomData.room_area}
                            {' m'}
                            <sup>{2}</sup>
                          </Typography>
                        )}
                    </Grid>
                    <Grid item xs={4} sm={4} xl={4} alignItems="baseline">
                      {this.state.roomData.bedrooms != null && this.state.roomData.bedrooms != 0 && (
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                          <Hotel size="large" />
                          {' ' + this.state.roomData.bedrooms}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={4} sm={4} xl={4} alignItems="baseline">
                      {this.state.roomData.bathrooms != null && this.state.roomData.bathrooms != 0 && (
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                          <Bathtub size="large" />
                          {' ' + this.state.roomData.bathrooms}
                        </Typography>
                      )}
                    </Grid>
                  </GridContainer>

                  {this.state.roomData.facilities != null && (
                    <>
                      <br />
                      <Typography variant="h6" className={classes.title + ' wbpTitle'}>
                        {(this.state.showRateData || this.state.showPackageData) && <IntlMessages id="_ROOM_FACILITIES" />}
                        {!(this.state.showRateData || this.state.showPackageData) && (
                          <IntlMessages id="_PROPERTY_FACILITIES" />
                        )}
                      </Typography>
                      <Typography className={classes.legal}>
                        {this.showIcons(this.state.roomData.facilities, '', 1)}
                      </Typography>
                      <br />
                    </>
                  )}

                  {this.state.roomData.d1 != null && this.state.roomData.d1 != '' && (
                    <Typography className={classes.legal}>
                      <IntlMessages id="d1" />
                      {': '}
                      {this.state.roomData.d1}
                      <br />
                    </Typography>
                  )}
                  {this.state.roomData.d2 != null && this.state.roomData.d2 != '' && (
                    <Typography className={classes.legal}>
                      <IntlMessages id="d2" />
                      {': '}
                      {this.state.roomData.d2}
                      <br />
                    </Typography>
                  )}
                  {this.state.roomData.d3 != null && this.state.roomData.d3 != '' && (
                    <Typography className={classes.legal}>
                      <IntlMessages id="d3" />
                      {': '}
                      {this.state.roomData.d3}
                      <br />
                    </Typography>
                  )}
                  {this.state.roomData.d4 != null && this.state.roomData.d4 != '' && (
                    <Typography className={classes.legal}>
                      <IntlMessages id="d4" />
                      {': '}
                      {this.state.roomData.d4}
                      <br />
                    </Typography>
                  )}
                  {this.state.roomData.d5 != null && this.state.roomData.d5 != '' && (
                    <Typography className={classes.legal}>
                      <IntlMessages id="d5" />
                      {': '}
                      {this.state.roomData.d5}
                      <br />
                    </Typography>
                  )}
                  {this.state.roomData.d6 != null && this.state.roomData.d6 != '' && (
                    <Typography className={classes.legal}>
                      <IntlMessages id="d6" />
                      {': '}
                      {this.state.roomData.d6}
                      <br />
                    </Typography>
                  )}
                  {this.state.roomData.d7 != null && this.state.roomData.d7 != '' && (
                    <Typography className={classes.legal}>
                      <IntlMessages id="d7" />
                      {': '}
                      {this.state.roomData.d7}
                      <br />
                    </Typography>
                  )}
                  {this.state.roomData.d8 != null && this.state.roomData.d8 != '' && (
                    <Typography className={classes.legal}>
                      <IntlMessages id="d8" />
                      {': '}
                      {this.state.roomData.d8}
                      <br />
                    </Typography>
                  )}
                  {this.state.roomData.d9 != null && this.state.roomData.d9 != '' && (
                    <Typography className={classes.legal}>
                      <IntlMessages id="d9" />
                      {': '}
                      {this.state.roomData.d9}
                      <br />
                    </Typography>
                  )}
                  {this.state.roomData.d10 != null && this.state.roomData.d10 != '' && (
                    <Typography className={classes.legal}>
                      <IntlMessages id="d10" />
                      {': '}
                      {this.state.roomData.d10}
                      <br />
                    </Typography>
                  )}
                  {this.state.roomData.d11 != null && this.state.roomData.d11 != '' && (
                    <Typography className={classes.legal}>
                      <IntlMessages id="d11" />
                      {': '}
                      {this.state.roomData.d11}
                      <br />
                    </Typography>
                  )}

                  {this.state.showPrivacy2 && (
                    <>
                      <br />
                      <Typography variant="h6" className={classes.title + ' wbpTitle'}>
                        <IntlMessages id="_LEGAL_DISCLAIMER" />
                      </Typography>
                      <br />
                      <Typography className={classes.legal}>
                        {parse(
                          this.state.policies.filter(
                            ({ id, hotelId }) => id == this.state.selItem.policyId && hotelId == this.state.selItem.hotelId,
                          )[0].description[locale],
                        )}
                      </Typography>
                    </>
                  )}
                </PerfectScrollbar>
              </Box>
            </Grid>
          </GridContainer>
        </Dialog>

        {this.state.activeStep == 0 && this.state.bookingMode == 'room' && <>{this.getSojernCode(0)}</>}
        {this.state.activeStep == 1 && this.state.bookingMode == 'room' && <>{this.getSojernCode(1, total - lD)}</>}
        {this.state.activeStep == 2 && this.state.bookingMode == 'room' && this.state.errorCode == 0 && (
          <>{this.getSojernCode(2, total - lD)}</>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

Booking.contextType = AppContext;
export default withStyles(useStyles)(connect(mapStateToProps)(injectIntl(Booking)));
